import { Injectable } from '@angular/core';
import { AppInternalConfig } from '../models/app/app-internal-config.model';
import { AppInternalConfigService } from '../services/app/app-internal-config.service';
import { DeptAppSearchFilter } from '../models/app/filters/deptapp.search-filter';
import { DeptAppsFlowsPrivateLibrarySearchFilter } from '../models/app/filters/deptapps-flows-private-library.search-filter';
import { DeptAppsFlowsSharedSubflowSearchFilter } from '../models/app/filters/deptapps-flows-shared-subflow.search-filter';

import * as _ from 'lodash';

const LOCAL_STORAGE_APP_INTERNAL_CONFIG = 'app_internal_config';
const LOCAL_STORAGE_DEPTAPP_SEARCH_FILTER = 'deptapp_search_filter';
const LOCAL_STORAGE_DEPTAPPS_FLOWS_PRIVATE_LIBRARY_SEARCH_FILTER = 'deptapps_flows_private_library_search_filter';
const LOCAL_STORAGE_DEPTAPPS_FLOWS_SHARED_SUBFLOW_SEARCH_FILTER = 'deptapps_flows_shared_subflow_search_filter';

@Injectable()
export class SessionStorageService {

  constructor(private internalConfigService: AppInternalConfigService) { }

  async getAppInternalConfig(): Promise<AppInternalConfig> {
    let finalResult: AppInternalConfig;
    let appInternalConfigString: string = sessionStorage.getItem(LOCAL_STORAGE_APP_INTERNAL_CONFIG);
    if (appInternalConfigString) {
      finalResult = JSON.parse(appInternalConfigString);
    } else {
      finalResult = await this.internalConfigService.get();
      appInternalConfigString = JSON.stringify(finalResult);
      sessionStorage.setItem(LOCAL_STORAGE_APP_INTERNAL_CONFIG, appInternalConfigString);
    }
    return finalResult;
  }

  getDeptAppSearchFilter(): DeptAppSearchFilter {
    let searchFilter: DeptAppSearchFilter;
    const searchFilterString = sessionStorage.getItem(LOCAL_STORAGE_DEPTAPP_SEARCH_FILTER);
    if (!_.isEmpty(searchFilterString)) {
      try {
        searchFilter = JSON.parse(searchFilterString);
      } catch (e) {
        console.error(e);
      }
    }
    if (_.isNil(searchFilter)) {
      searchFilter = { ownerUserMe: true, page: 1 };
    }
    return searchFilter;
  }

  saveDeptAppSearchFilter(searchFilter: DeptAppSearchFilter) {
    sessionStorage.setItem(LOCAL_STORAGE_DEPTAPP_SEARCH_FILTER, JSON.stringify(searchFilter || {}));
    return (searchFilter || {});
  }

  getDeptAppsFlowsPrivateLibrarySearchFilter(): DeptAppsFlowsPrivateLibrarySearchFilter {
    let searchFilter: DeptAppsFlowsPrivateLibrarySearchFilter;
    const searchFilterString = sessionStorage.getItem(LOCAL_STORAGE_DEPTAPPS_FLOWS_PRIVATE_LIBRARY_SEARCH_FILTER);
    if (!_.isEmpty(searchFilterString)) {
      try {
        searchFilter = JSON.parse(searchFilterString);
      } catch (e) {
        console.error(e);
      }
    }
    if (_.isNil(searchFilter)) {
      searchFilter = { page: 1 };
    }
    return searchFilter;
  }

  saveDeptAppsFlowsPrivateLibrarySearchFilter(searchFilter: DeptAppsFlowsPrivateLibrarySearchFilter) {
    sessionStorage.setItem(LOCAL_STORAGE_DEPTAPPS_FLOWS_PRIVATE_LIBRARY_SEARCH_FILTER, JSON.stringify(searchFilter || {}));
    return (searchFilter || {});
  }

  getDeptAppsFlowsSharedSubflowSearchFilter(): DeptAppsFlowsSharedSubflowSearchFilter {
    let searchFilter: DeptAppsFlowsSharedSubflowSearchFilter;
    const searchFilterString = sessionStorage.getItem(LOCAL_STORAGE_DEPTAPPS_FLOWS_SHARED_SUBFLOW_SEARCH_FILTER);
    if (!_.isEmpty(searchFilterString)) {
      try {
        searchFilter = JSON.parse(searchFilterString);
      } catch (e) {
        console.error(e);
      }
    }
    if (_.isNil(searchFilter)) {
      searchFilter = { page: 1 };
    }
    return searchFilter;
  }

  saveDeptAppsFlowsSharedSubflowSearchFilter(searchFilter: DeptAppsFlowsSharedSubflowSearchFilter) {
    sessionStorage.setItem(LOCAL_STORAGE_DEPTAPPS_FLOWS_SHARED_SUBFLOW_SEARCH_FILTER, JSON.stringify(searchFilter || {}));
    return (searchFilter || {});
  }

  clearAll(): void {
    sessionStorage.clear();
  }
}