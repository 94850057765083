import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "../../../../@core/utils/local-storage.service";
import { getQueryParamsHref } from "../../../../@core/helpers/uri.helper";
import { Router } from "@angular/router";
import { JwtUtilsService } from "../../../../@core/utils/jwt-utils.service";
import { AuthRemoteService } from "../../../../@core/services/mdone/auth-remote.service";
import { AlertService } from "../../alert/alert.service";
import { TypeAlertEnum } from "../../alert/alert.model";
import { ProjectRemoteService } from "../../../../@core/services/mdone/project-remote.service";

import { environment } from "../../../../../environments/environment";

const IMPERSONAL_TOKEN = environment.impersonateToken;

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class NgxLoginComponent implements OnInit {
  isLoading: boolean;
  isCallback: boolean;

  constructor(
    private projectRemoteService: ProjectRemoteService,
    private alertService: AlertService,
    private authRemoteService: AuthRemoteService,
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    Promise.resolve().then(async () => {
      const queryParams: Array<any> = getQueryParamsHref();
      this.localStorageService.putProjectIdReceived(queryParams["id_project"]);
      const accessTokenParam: string = queryParams["access_token"];
      const jwtValue = this.localStorageService.getJwtValue();
      // Sólo entra la primera vez que inicias sesión en OKTA
      if (accessTokenParam && accessTokenParam.length > 0) {
        this.isCallback = true;
        //entra en el if porque el accessTokenParam es el token, TODO: añadir algo en la url para diferenciar válido de no válido
        if(this.jwtUtilsService.isValidToken(accessTokenParam)){
          if (!this.jwtUtilsService.isImpersonated(accessTokenParam)) {
            this.localStorageService.setJwtValue(accessTokenParam);
            this.applySuccessAuthentication(queryParams);
          } else {
            this.localStorageService.setJwtValue(accessTokenParam);
            this.applySuccessAuthentication(queryParams, true);
            setTimeout(() => {
              this.redirectToAxetGaia();
            }, 0);
          }
        }else{
          this.redirectToAxetGaia();
        }
      } else if (
        this.localStorageService.hasJwtValue() &&
        !this.jwtUtilsService.isTokenExpired(jwtValue)
      ) {

        if (!this.jwtUtilsService.isImpersonated(jwtValue)) {
          // aunque el usuario no sea autorizado, pasa por aquí si ha sido ya logueado en okta
          this.isCallback = true;
          this.applySuccessAuthentication(queryParams);
        } else {
          this.applySuccessAuthentication(queryParams, true);
            setTimeout(() => {
              this.redirectToAxetGaia();
            }, 0);
        }

      } else {
        try {
          // Force redirect SSO authentication invoke /api/mdone/auth/user/info
          await this.authRemoteService.getUserInfo();
        } catch (e) {
          if (e.status !== 401) {
            setTimeout(() => {
              this.isLoading = false;
              this.alertService.addAlertMessage({
                type: TypeAlertEnum.ERROR,
                summary: "Error when send request to the server!",
              });
            }, 1000);
            throw e;
          }
        }
      }
    });
  }

  private async applySuccessAuthentication(
    queryParams: Array<any>,
    newUser?: boolean
  ) {
    const jwtValue = this.localStorageService.getJwtValue();
    if (!newUser) {
      // User is admin
      if (this.jwtUtilsService.isUserAdmin(jwtValue) && jwtValue !== IMPERSONAL_TOKEN) {
        this.redirectToInternalPage();
      } else {// User not admin
        let goToAccessDenied = false;
        try {
          this.redirectToInternalPage();
        } catch (e) {
          goToAccessDenied = true;
          this.alertService.addAlertMessage({
            type: TypeAlertEnum.ERROR,
            summary: "Error when getting your projects",
          });
          throw e;
        } finally {
          this.isLoading = false;
          if (goToAccessDenied) {
            this.redirectToAxetGaia();
          }
        }
      }
    }
  }

  private redirectToInternalPage(): void {
    this.router.navigate(["pages"], {
      queryParams: {},
      preserveQueryParams: false,
    });
  }


  private redirectToAxetGaia():void {
    window.location.href = 'https://axet.nttdata.com/gaia/';
    // this.router.navigate(["access-denied"], {
    //       queryParams: {},
    //       preserveQueryParams: false, 
    //   });
  } 

}
