import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../utils/local-storage.service';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap';
import { MDOneExternalConfig } from '../models/mdone/mdone-external-config.model';
import { JwtUtilsService } from '../utils/jwt-utils.service';
import { AuthRemoteService } from '../services/mdone/auth-remote.service';
import { environment } from '../../../environments/environment';
import { DEFAULT_HOST_DEPTAPPS_DESKTOP } from '../services/desktop';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isAuthenticatedRequest(req)) {
      return from(this.localStorageService.getMDOneExternalConfig()).mergeMap((externalConfig: MDOneExternalConfig) => {
        const jwtValue: string = this.localStorageService.getJwtValue();
        if (!jwtValue || !jwtValue.length || this.jwtUtilsService.isTokenExpired(jwtValue)) {
          this.redirectToSingleSignOn(externalConfig);
        } else {
          this.refreshTokenIfIsNeccesary(externalConfig, jwtValue);
          //Intercept HTTP request
          req = req.clone({ setHeaders: { [externalConfig.tokenHeader]: `Bearer ${jwtValue}` } });
        }

        return next.handle(req).pipe(tap(
          () => { },
          (error: any) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.redirectToSingleSignOn(externalConfig);
            }
          },
        ));
      });
    }
    return next.handle(req);
  }

  private async refreshTokenIfIsNeccesary(externalConfig: MDOneExternalConfig, jwtValue: string): Promise<any> {
    if (!this.jwtUtilsService.isTokenExpired(jwtValue) &&
      this.jwtUtilsService.isTokenInThirdAge(jwtValue, externalConfig.expirationTokenInSeconds)) {
      //Refresh token
      try {
        const response = await this.authRemoteService.refreshJwtToken(externalConfig.tokenHeader, jwtValue);
        this.localStorageService.setJwtValue(response.token);
      } catch (e) {
        console.error('Can\'t refresh JWT token!', e);
      }
    }
  }

  private redirectToSingleSignOn(externalConfig: MDOneExternalConfig) {
    // If error is 401, then redirect to SSO
    const href = window.location.href;
    const isINC = href.includes("axet-pre.nttdata.com") || href.includes("axet.nttdata.com");
    const callbackUri = `${new URL(window.location.href).origin}${ isINC ? '/flows/frontend' : ''}/#/login`;
    const redirectURL = externalConfig.urlSso;

    const url = new URL(redirectURL);

    if ('oktaApiUrlBase' in environment) {
      url.href = environment['oktaApiUrlBase'];
    }
    url.searchParams.append('axetFlowsLogin', 'true');
    console.log(url);
    url.searchParams.append('redirectUri', callbackUri);

    // NOTE: this will redirect to <api-url>/okta/auth with the parameters get
    window.location.href = url.toString();
  }

  private isAuthenticatedRequest(req: HttpRequest<any>): boolean {
    return (
      this.isAuthenticatedBackendWebRequest(req) || this.isAuthenticatedDesktopRequest(req)
    );
  }

  private isAuthenticatedBackendWebRequest(req: HttpRequest<any>): boolean {
    return (
      req.url.startsWith(environment.apiUrlBase) && !req.url.endsWith('api/external-config') && !req.url.endsWith('api/token/refresh')
    );
  }

  private isAuthenticatedDesktopRequest(req: HttpRequest<any>): boolean {
    return (
      req.url.startsWith(DEFAULT_HOST_DEPTAPPS_DESKTOP)  && !req.url.endsWith('api/app/desktop/internal-config')
    );
  }

  protected get localStorageService(): LocalStorageService {
    return this.injector.get(LocalStorageService);
  }

  protected get jwtUtilsService(): JwtUtilsService {
    return this.injector.get(JwtUtilsService);
  }

  protected get authRemoteService(): AuthRemoteService {
    return this.injector.get(AuthRemoteService);
  }

  detectIfNewUserPetition() {

  }
}
