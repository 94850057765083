import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Project } from '../../models/mdone/project.model';
import { Observable, ReplaySubject } from 'rxjs';
import { UtilsService } from '../../utils/utils.service';
import { Sector } from '../../models/autodeploy/sector.model';
import { Manager} from '../../models/autodeploy/manager.model';
import { Process, ProcessStatus } from '../../models/autodeploy/process.model';
import { DeptAppForKubernetesRemoteDeploy } from '../../models/desktop/deptapp-for-kubernetes-remote-deploy.model';
import { DeptAppsDesktopConnectionEndpointService } from '../desktop/internal/deptapps-desktop-connection-endpoint.service';
import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '../desktop';
import { CheckVersionDesktopService } from '../desktop/check-version-desktop.service';
import { Region } from '../../models/autodeploy/region.model';
import { Instance } from '../../models/autodeploy/instance.model';


const RESOURCE_BASE_URI = `${environment.apiUrlBase}/auto-deploy`;

@Injectable()
export class AutoDeployService {

  private sectors: Sector[];

  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient,
    private utilsService: UtilsService) {
    this.sectors = [];
  }

  async getAvailableSectors(name: string): Promise<Sector[]> {
    name = name || '';
    let params = new HttpParams().set('name', name);
    
    return await this.http.get<Sector[]>(encodeURI(`${RESOURCE_BASE_URI}/getAvailableSectors`), {params}).toPromise();
  }

  async getSectorByRegion(id: number): Promise<Sector[]> {
    return await this.http.get<Sector[]>(encodeURI(`${RESOURCE_BASE_URI}/region/${id}/sectors`)).toPromise();
  }

  async getAvailableRegions(name: string): Promise<Region[]> {
    name = name || '';
    let params = new HttpParams().set('name', name);
    return await this.http.get<Region[]>(encodeURI(`${RESOURCE_BASE_URI}/getAvailableRegions`), {params}).toPromise();
  }


  async startAutoDeployProcess(deptAppForKubernetesRemoteDeploy: DeptAppForKubernetesRemoteDeploy, autoDeployInfo: Process, currentInstance: Instance): Promise<ProcessStatus>{
   
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    let body = {...deptAppForKubernetesRemoteDeploy, ...autoDeployInfo}
    body["instance"] = currentInstance;
    return await this.http.post<ProcessStatus>(encodeURI(`${resourceBaseUri}/start`), body).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp/desktop/auto-deploy`;
  }

  async getCurrentAutoDeployInstance(id: number): Promise<Instance> {
    return await this.http.get<Instance>(encodeURI(`${RESOURCE_BASE_URI}/instance/${id}`)).toPromise();
  }

  async getCloudDeployInstances(id: number): Promise<Instance[]> {
    return await this.http.get<Instance[]>(encodeURI(`${RESOURCE_BASE_URI}/instance/${id}/list`)).toPromise();
  }
}
