import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { DeptAppsFlowsCustomUseRegisterRunningInstanceModeEnum } from '../../models/app/enums/deptapps-flows-custom-use-register-running-instance-mode.enum';
import { DeptAppsFlowsCustomUseRegister } from '../../models/app/deptapps-flows-custom-use-register.model';
import { DeptApp } from '../../models/app/deptapp.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;

@Injectable()
export class DeptAppsFlowsCustomUseRegisterService {

  constructor(
    private http: HttpClient) { }

  async findBySearchFilterPagination(deptApp: DeptApp, idDeptAppVersion?: number,
    runningInstanceMode?: DeptAppsFlowsCustomUseRegisterRunningInstanceModeEnum, page?: number, limit?: number): Promise<PaginationResult<DeptAppsFlowsCustomUseRegister>> {
    let params = new HttpParams();
    if (!!idDeptAppVersion) {
      params = params.append('id-deptapp-version', idDeptAppVersion.toString());
    }
    if (!!runningInstanceMode) {
      params = params.append('running-instance-mode', runningInstanceMode);
    }
    if (!!page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (!!limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppsFlowsCustomUseRegister>>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/deptapps-flows-custom-use-register`), { params }).toPromise();
  }
}
