import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserPreferences } from '../../models/app/user-preferences.model';
import { Identifier } from '../../models/common/identifier.model';
import { LocalStorageService } from '../../utils/local-storage.service';

import * as _ from 'lodash';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/user-preferences`;

@Injectable()
export class UserPreferencesService {

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient) { }

  async findOrCreateByCurrentUserLogged(): Promise<UserPreferences> {
    let userPreferences: UserPreferences = this.localStorageService.getUserPreferences();
    if (_.isNil(userPreferences)) {
      userPreferences = await this.http.get<UserPreferences>(encodeURI(`${RESOURCE_BASE_URI}/me`)).toPromise();
      this.localStorageService.saveUserPreferences(userPreferences);
    }
    return userPreferences;
  }

  async saveOrUpdate(userPreferences: UserPreferences): Promise<Identifier<number>> {
    try {
      return await this.http.patch<Identifier<number>>(encodeURI(RESOURCE_BASE_URI), userPreferences).toPromise();
    } finally {
      this.localStorageService.clearUserPreferences();
    }

  }
}
