import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { DeptAppNotification } from '../../../models/app/deptapp-notification.model';
import { Observable } from 'rxjs';


@Injectable()
export class DeptAppWebSocketObservable {

  private deptAppNotificationSubject: Subject<DeptAppNotification>;
  deptAppNotificationObservable: Observable<DeptAppNotification>;

  constructor() {
    this.deptAppNotificationSubject = new Subject<DeptAppNotification>();
    this.deptAppNotificationObservable = this.deptAppNotificationSubject.asObservable();
  }

  pushDeptAppNotification(value: DeptAppNotification) {
    this.deptAppNotificationSubject.next(value);
  }
}
