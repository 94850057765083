import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DatabaseSpec } from '../../../models/meta-information-aggregator/red-asset-integration/models/database-spec.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/meta-information-aggregator-data`;

@Injectable()
export class MetaInformationAggregatorDataService {

  constructor(
    private http: HttpClient) { }

  convertDatabaseSpecToZipSchemaSpyFormat(databaseSpec: DatabaseSpec, schemaName?: string): Observable<HttpEvent<{}>> {
    return this.http.request(new HttpRequest('POST', encodeURI(`${RESOURCE_BASE_URI}/database-spec/schema-spy`), databaseSpec, {
      params: new HttpParams().append('schema-name', schemaName),
      reportProgress: true,
      responseType: 'blob'
    }));
  }
}
