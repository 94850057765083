import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CheckVersionDesktopService } from '../../desktop/check-version-desktop.service';
import { RedAssetDatabaseType } from '../../../models/meta-information-aggregator/red-asset-integration/preferences/red-asset-database-type.model';
import { ResponseSchemaList } from '../../../models/meta-information-aggregator/red-asset-integration/models/response-schema-list.model';
import { RedAssetConnectionPreferences } from '../../../models/meta-information-aggregator/red-asset-integration/preferences/red-asset-connection-preferences.model';
import { ABSOLUTE_PATH_FILE } from '../../../models/meta-information-aggregator/red-asset-integration/preferences/red-asset-connection-type.model';
import { DatabaseSpec } from '../../../models/meta-information-aggregator/red-asset-integration/models/database-spec.model';
import { DeptAppsDesktopConnectionEndpointService } from '../../desktop/internal/deptapps-desktop-connection-endpoint.service';

import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '../../desktop';
import * as _ from 'lodash';

@Injectable()
export class RedAssetIntegrationDesktopService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async findAllSupportedDatabaseTypes(): Promise<RedAssetDatabaseType[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<RedAssetDatabaseType[]>(encodeURI(`${resourceBaseUri}/database-types`)).toPromise();
  }

  async findRedAssetConnectionPreferencesBySearchFilter(searchText: string, redAssetDatabaseTypeSelected: RedAssetDatabaseType): Promise<RedAssetConnectionPreferences[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);
    let params = new HttpParams();
    if (searchText && searchText.length) {
      params = params.append(redAssetDatabaseTypeSelected && redAssetDatabaseTypeSelected.redAssetConnectionType &&
        redAssetDatabaseTypeSelected.redAssetConnectionType.name === ABSOLUTE_PATH_FILE ? 'absolute-path-database-file' : 'host', searchText);
    }
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<RedAssetConnectionPreferences[]>(encodeURI(`${resourceBaseUri}/preferences`), { params }).toPromise();
  }

  async findRedAssetConnectionPreferencesById(connectionPreferences: RedAssetConnectionPreferences): Promise<RedAssetConnectionPreferences> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<RedAssetConnectionPreferences>(encodeURI(`${resourceBaseUri}/preferences/${connectionPreferences.id}`)).toPromise();
  }

  async listSchemas(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType): Promise<ResponseSchemaList> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<ResponseSchemaList>(encodeURI(`${resourceBaseUri}/schema`),
      this.parseRedAssetConnectionPreferencesToListSchemasRequest(connectionPreferences, redAssetDatabaseTypeSelected)).toPromise();
  }

  async generateDatabaseSchema(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType): Promise<DatabaseSpec> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<DatabaseSpec>(encodeURI(`${resourceBaseUri}/schema/reverse-engineering`),
      this.parseRedAssetConnectionPreferencesToGenerateDatabaseSchemaRequest(connectionPreferences, redAssetDatabaseTypeSelected)).toPromise();
  }

  async invokeAdditionalActionConsumerIfNeeded(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType): Promise<void> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.RED_ASSET_INTEGRATION);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<void>(encodeURI(`${resourceBaseUri}/preferences/host`),
      this.parseRedAssetConnectionPreferencesToGenerateDatabaseSchemaRequest(connectionPreferences, redAssetDatabaseTypeSelected)).toPromise();
  }

  private parseRedAssetConnectionPreferencesToGenerateDatabaseSchemaRequest(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType) {
    const request = this.parseRedAssetConnectionPreferencesToListSchemasRequest(connectionPreferences, redAssetDatabaseTypeSelected);
    request.port_preferences[0].schemas_preferences = (connectionPreferences.redAssetSchemaPreferences || []).map(schemaPreferences => {
      return {
        name: schemaPreferences.name,
        tables_preferences: (schemaPreferences.redAssetTablePreferences || []).map(tablePreferences => {
          return {
            name: tablePreferences.name,
            columns: tablePreferences.columns || [],
            foreign_keys: tablePreferences.foreignKeys || [],
          }
        })
      }
    });
    request.port_preferences[0].filter_table_name = connectionPreferences.filterTableName;
    return request;
  }

  private parseRedAssetConnectionPreferencesToListSchemasRequest(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType): any {
    return {
      host: connectionPreferences.host || connectionPreferences.absolutePathDatabaseFile,
      port_preferences: [
        {
          port: connectionPreferences.port,
          user: connectionPreferences.username || 'NO_USER',
          password: connectionPreferences.password || 'NO_PASSWORD',
          database_name: connectionPreferences.databaseName,
          database_type: redAssetDatabaseTypeSelected.name,
          database_url: this.buildJDBCConnectionUrl(connectionPreferences, redAssetDatabaseTypeSelected)
        }
      ]
    };
  }

  private buildJDBCConnectionUrl(connectionPreferences: RedAssetConnectionPreferences, redAssetDatabaseTypeSelected: RedAssetDatabaseType): string {
    var url = 'jdbc:' + redAssetDatabaseTypeSelected.name + ':';
    const isOracleDatabase = redAssetDatabaseTypeSelected.name === 'oracle';
    const isH2Database = redAssetDatabaseTypeSelected.name === 'h2';
    if (isOracleDatabase) {
      url += 'thin:@';
    } else if (isH2Database) {
      url += 'tcp://';
    } else if (redAssetDatabaseTypeSelected.name == 'sqlite') {
      url += '/';
    } else {
      url += '//';
    }
    if (redAssetDatabaseTypeSelected.redAssetConnectionType.name === ABSOLUTE_PATH_FILE) {
      url += connectionPreferences.absolutePathDatabaseFile;
    }
    else {
      url += connectionPreferences.host + ':' + connectionPreferences.port;

      if (isOracleDatabase) {
        if (connectionPreferences.isOracleService) {
          url += '/';
        } else {
          url += ':';
        }
        url += connectionPreferences.databaseName;
      } else if (redAssetDatabaseTypeSelected.name === 'mysql') {
        url += '/' + connectionPreferences.databaseName + '?useSSL=false&serverTimezone=UTC';
      } else if (isH2Database) {
        url += '/~/' + connectionPreferences.databaseName;
      } else if (redAssetDatabaseTypeSelected.name === 'postgresql') {
        url += '/' + connectionPreferences.databaseName;
      } else if (redAssetDatabaseTypeSelected.name === 'sqlserver') {
        url += ';databaseName=' + connectionPreferences.databaseName;
      } else if (redAssetDatabaseTypeSelected.name === 'db2') {
        url += '/' + connectionPreferences.databaseName;
      }
    }
    return url;
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/integration/red`;
  }
}
