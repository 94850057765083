import { Injectable, Injector } from '@angular/core';
import { LocalStorageService } from '../utils/local-storage.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtUtilsService } from '../utils/jwt-utils.service';
import { Observable } from 'rxjs';

@Injectable()
export class ActivateIsAuthenticatedGuard implements CanActivate {

  constructor(private injector: Injector) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.localStorageService.hasJwtValue() && !this.jwtUtilsService.isTokenExpired(this.localStorageService.getJwtValue());
  }

  protected get localStorageService(): LocalStorageService {
    return this.injector.get(LocalStorageService);
  }

  protected get jwtUtilsService(): JwtUtilsService {
    return this.injector.get(JwtUtilsService);
  }


}
