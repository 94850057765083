import { Component } from '@angular/core';

@Component({
  selector: 'ngx-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
})
export class NgxAccessDeniedComponent {

}
