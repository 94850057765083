import { Injectable, isDevMode } from '@angular/core';
import { LocalStorageService } from '../../../utils/local-storage.service';
import { MDOneExternalConfig } from '../../../models/mdone/mdone-external-config.model';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import * as _ from 'lodash';
import { DeptAppsDesktopConnectionEndpointService } from '../internal/deptapps-desktop-connection-endpoint.service';


@Injectable()
export class DeptAppDesktopWebSocketService {

  private containerLogConsumerWebSocket: any;
  private pullImageConsumerWebSocket: any;
  private remoteDeployBySshConsumerWebSocket: any;
  private standaloneElectronAndUnzipConsumerWebSocket: any;
  private k8sDeployConsumerWebSocket: any;

  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private localStorageService: LocalStorageService,
  ) { }

  async connectContainerLogConsumer() {
    if (!this.containerLogConsumerWebSocket) {
      this.containerLogConsumerWebSocket = await this.connect();
    }
    return this.containerLogConsumerWebSocket;
  }

  async disconnectContainerLogConsumer() {
    if (this.containerLogConsumerWebSocket) {
      this.disconnect(this.containerLogConsumerWebSocket)
      this.containerLogConsumerWebSocket = undefined;
    }
  }

  async connectPullImageConsumerWebSocket() {
    if (!this.pullImageConsumerWebSocket) {
      this.pullImageConsumerWebSocket = await this.connect();
    }
    return this.pullImageConsumerWebSocket;
  }

  async disconnectPullImageConsumerWebSocket() {
    if (this.pullImageConsumerWebSocket) {
      this.disconnect(this.pullImageConsumerWebSocket)
      this.pullImageConsumerWebSocket = undefined;
    }
  }

  async connectRemoteDeployBySshConsumerWebSocket() {
    if (!this.remoteDeployBySshConsumerWebSocket) {
      this.remoteDeployBySshConsumerWebSocket = await this.connect();
    }
    return this.remoteDeployBySshConsumerWebSocket;
  }

  async disconnectRemoteDeployBySshConsumerWebSocket() {
    if (this.remoteDeployBySshConsumerWebSocket) {
      this.disconnect(this.remoteDeployBySshConsumerWebSocket)
      this.remoteDeployBySshConsumerWebSocket = undefined;
    }
  }

  async connectStandaloneElectronAndUnzipConsumerWebSocket() {
    if (!this.standaloneElectronAndUnzipConsumerWebSocket) {
      this.standaloneElectronAndUnzipConsumerWebSocket = await this.connect();
    }
    return this.standaloneElectronAndUnzipConsumerWebSocket;
  }

  async disconnectStandaloneElectronAndUnzipConsumerWebSocket() {
    if (this.standaloneElectronAndUnzipConsumerWebSocket) {
      this.disconnect(this.standaloneElectronAndUnzipConsumerWebSocket)
      this.standaloneElectronAndUnzipConsumerWebSocket = undefined;
    }
  }

  async connectK8SDeployConsumerWebSocket() {
    if (!this.k8sDeployConsumerWebSocket) {
      this.k8sDeployConsumerWebSocket = await this.connect();
    }
    return this.k8sDeployConsumerWebSocket;
  }

  async disconnectK8SDeployConsumerWebSocket() {
    if (this.k8sDeployConsumerWebSocket) {
      this.disconnect(this.k8sDeployConsumerWebSocket)
      this.k8sDeployConsumerWebSocket = undefined;
    }
  }

  private async connect() {
    const jwtValue: string = this.localStorageService.getJwtValue();
    if (!_.isEmpty(jwtValue)) {
      const { webSocketConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
      const externalConfiguration: MDOneExternalConfig = await this.localStorageService.getMDOneExternalConfig();
      const finalEndpointWebSocket: string = `${webSocketConnectionBaseUrl}?${externalConfiguration.queryParamAuthToken}=${jwtValue}`;
      const ws = new SockJS(encodeURI(finalEndpointWebSocket));
      const stompClient = Stomp.over(ws);
      if (!isDevMode()) {
        stompClient.debug = null;
      }
      return stompClient;
    }
    throw new Error('Cannot connect the websocket because you are not authenticating in the MD|ONE SSO System!');
  }

  private disconnect(stompClient: any) {
    if (stompClient && stompClient.disconnect) {
      try {
        stompClient.disconnect();
      } catch (e) {
        console.error(e);
      }
    }
  }
}
