export interface ConfirmDialogBodyMessage {
  message: string;
  icon?: string;
}

export interface InitialStateConfig {
  headerTitle?: string;
  bodyMessage?: string[] | string | ConfirmDialogBodyMessage | ConfirmDialogBodyMessage[];
  yesButtonValue?: string;
  noButtonValue?: string;
  sizeDialog?: 'sm' | 'lg';
  extraCancelButton?: boolean;
  isExportModal?: boolean;
}

export interface CredentialsPasswordConfig {
  action: ConfirmDialogActionEnum;
  initialState?: InitialStateConfig;
  accept?: () => void;
  cancel?: () => void;
}

export enum ConfirmDialogActionEnum {
  SHOW,
  HIDE,
  STOP_SUBMITTED,
}
