import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { JwtUtilsService } from "../../../../@core/utils/jwt-utils.service";
import { LocalStorageService } from "../../../../@core/utils/local-storage.service";
import { AuthRemoteService } from "../../../../@core/services/mdone/auth-remote.service";

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Project } from "../../../../@core/models/mdone/project.model";
import { ProjectRemoteService } from "../../../../@core/services/mdone/project-remote.service";

import * as _ from "lodash";
import { UserRemoteService } from "../../../../@core/services/mdone/user-remote.service";
import { NewUserDetails } from "../../../../@core/models/app/new-user-details.model";
import { DataToSend } from "../../../../@core/models/app/data-to-send-new-user.model";
import { Validate } from "./models/validate.model";

@Component({
  selector: "new-user",
  templateUrl: "./new-user.component.html",
  styleUrls: ["./new-user.component.scss"],
})
export class NewUserComponent implements OnInit {
  userInfo: FormGroup;
  projectsAll: Project[] = [];
  userDetails: NewUserDetails;

  validate: Validate = {
    project: false
  };

  @Input("ngModel") selectedProject: string;
  @Input("ngModel") createNewProject: boolean = false;
  @Input("ngModel") projectExist: boolean = false;

  constructor(
    private jwtService: JwtUtilsService,
    private localStorageService: LocalStorageService,
    private projectRemoteService: ProjectRemoteService,
    private userRemoteService: UserRemoteService,
    private authRemoteService: AuthRemoteService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.userRemoteService.getUserDetails().subscribe((userDetails: NewUserDetails) => {
      this.userDetails = userDetails;
    });

    this.userInfo = new FormGroup({
      project: new FormControl(1, [
        Validators.required,
        Validators.pattern(/[0-9]{1,}/),
      ]),
      createProject: new FormControl(false),
      newClient: new FormControl(""),
      newProject: new FormControl("")
    });

    this.configurarValidaciones();
    this.getAllProjects();
  }


  addUser() {
    this.checkIsValid(this.createNewProject ? 'newProject' : 'project');
    let dataToSend: DataToSend = { 
      ...this.userDetails,
      client: "0",
      project: this.userInfo.value.project && this.userInfo.value.project !== '' ? parseInt(this.userInfo.value.project) : null,
      newClient: this.createNewProject && this.userInfo.value.newClient ? this.userInfo.value.newClient : null,
      newProject: this.createNewProject && this.userInfo.value.newProject ? this.userInfo.value.newProject : null
    };

    this.userRemoteService
      .registerNewUserInMdOne(dataToSend)
      .subscribe((data) => {
        this.localStorageService.clearJwtValue(); //Esto borra el token impersonado temporal. Si se implementa lo del correo comentado, eliminar esta parte
        this.setMDOneProject(dataToSend); //Esto añade un proyecto temporal para evitar fallos de la web. Si se implementa lo del correo comentado, eliminar esta parte
        this.router.navigate(["login"]);
      });
  }

  setMDOneProject(dataToSend: any) {
    this.authRemoteService
      .applySuccessAuthenticationFirstTime({
        id: dataToSend.project,
        name: dataToSend.project ? _.filter(this.projectsAll, (project: any) => {
          return dataToSend.project === project.project_id;
        })[0].project_name : dataToSend.newProject,
        description: "",
        client: {
          id: dataToSend.project ? _.filter(this.projectsAll, (project: any) => {
            return dataToSend.project === project.project_id;
          })[0].client.id : "0",
          name: dataToSend.project ? _.filter(this.projectsAll, (project: any) => {
            return dataToSend.project === project.project_id;
          })[0].client.name : dataToSend.newClient,
        },
        calculateLabel: "",
      })
      .then((ok) => {});
  }

  getAllProjects() {
    this.projectRemoteService
      .findProyectsToNewUsers()
      .subscribe((projects: any) => {
        // Add default project to appear in list of projects to choose
        projects.proyectosClientes.push(
          {
          project_id: 0,
          project_name: "Sin proyecto",
          client: {
            id: 0,
            name: "Default",
          }
          });
        this.projectsAll = projects.proyectosClientes.sort((a,b) => {
          // Make the default project to be always on top of the list
          if (a.project_name === "Sin proyecto"){
            return -1;
          }
          if (
            a.project_name.toUpperCase() < b.project_name.toUpperCase()
          ) {
            return -1;
          }
          if (
            a.project_name.toUpperCase() > b.project_name.toUpperCase()
          ) {
            return 1;
          }
          return 0;
        });
      });
  }

  customSearch(term: string, item: any) {
    return item.project_name.toLowerCase().includes(term.toLowerCase());
  }

  checkIsValid(inputId: string) {
    //Assign value to project property to check form
    if (this.userInfo.value && !this.userInfo.value.project)
      // Change the default fake project into DEPTAPPS public project
      if (this.userInfo.value.project === 0)
        this.userInfo.value.project = 37;
      else
        this.userInfo.value.project = "";
    if (
      this.userInfo.controls &&
      this.userInfo.controls[`${inputId}`] &&
      inputId === "project"
    ) {
      if (
        _.isNil(this.userInfo.value.project) ||
        this.userInfo.value.project === ""
      ) {
        this.validate[`${inputId}`] = true;
      } else {
        this.validate[`${inputId}`] = false;
      }
    } else if (
      this.userInfo.controls &&
      this.userInfo.controls[`${inputId}`] &&
      this.userInfo.controls[`${inputId}`].errors
    ) {
      this.validate[`${inputId}`] = true;
    } else {
      this.validate[`${inputId}`] = false;
    }

    //checking if project exists
    if(_.filter(this.projectsAll, (project : any) => {
        return project.project_name.toLowerCase() === this.userInfo.value.newProject.toLowerCase() &&
        project.client.name.toLowerCase() === this.userInfo.value.newClient.toLowerCase()
      }
    ).length != 0){
      this.projectExist = true;
    }
    else{
      this.projectExist = false;
    }
  }


  obtainValueCheckbox() {
    if(this.createNewProject){
      this.userInfo.patchValue({
        newClient: "",
        newProject: ""
      })
    }
    this.createNewProject = !this.createNewProject;

    const projectControl = this.userInfo.get('project');

    if (projectControl) {
      if (this.createNewProject) {
        projectControl.disable();
        projectControl.reset();
      } else {
        projectControl.enable();
      }
    }
  }

  resetForm(){
    this.userInfo.reset();
    this.createNewProject = false;
    if(this.userInfo.get('project')){
      this.userInfo.get('project').enable();
    }    
  }

  private configurarValidaciones() {
    const projectValidations = this.userInfo.get('project');
    const newProjectValidations = this.userInfo.get('newProject');
    const newClientValidations = this.userInfo.get('newClient');

    // Agrega validador condicional basado en el estado del checkbox
    this.userInfo.get('createProject').valueChanges.subscribe(change => {
      if (change) {
        newProjectValidations.setValidators([
          Validators.required,
          Validators.minLength(4),
        ]);
        newClientValidations.setValidators([
          Validators.required,
          Validators.minLength(4),
        ]);
        projectValidations.clearValidators();
      } else {
        projectValidations.setValidators([
          Validators.required,
          Validators.pattern(/[0-9]{1,}/),
        ]);
        newProjectValidations.clearValidators();
        newClientValidations.clearValidators();
      }

      // Actualiza las validaciones
      projectValidations.updateValueAndValidity();
      newProjectValidations.updateValueAndValidity();
      newClientValidations.updateValueAndValidity();
    });
  }
}
