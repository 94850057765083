import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { DeptApp } from '../../models/app/deptapp.model';
import { Flag } from '../../models/common/flag.model';
import { DeptAppMetaInformationAggregatorData } from '../../models/app/deptapp-meta-information-aggregator-data.model';
import { Identifier } from '../../models/common/identifier.model';
import { Observable } from 'rxjs';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;

@Injectable()
export class DeptAppMetaInformationAggregatorDataService {

  constructor(
    private http: HttpClient) { }

  async findBySearchFilter(deptApp: DeptApp, searchText?: string, page?: number, limit?: number): Promise<PaginationResult<DeptAppMetaInformationAggregatorData>> {
    let params = new HttpParams();
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppMetaInformationAggregatorData>>(encodeURI(
      `${RESOURCE_BASE_URI}/${deptApp.id}/meta-information-aggregator-data`), { params }).toPromise();
  }

  async findById(deptAppMetaInformationAggregatorData: DeptAppMetaInformationAggregatorData): Promise<DeptAppMetaInformationAggregatorData> {
    return await this.http.get<DeptAppMetaInformationAggregatorData>(encodeURI(`${RESOURCE_BASE_URI}/meta-information-aggregator-data/${deptAppMetaInformationAggregatorData.id}`)).toPromise();
  }

  async getContentById(deptAppMetaInformationAggregatorData: DeptAppMetaInformationAggregatorData): Promise<DeptAppMetaInformationAggregatorData> {
    return await this.http.get<DeptAppMetaInformationAggregatorData>(encodeURI(`${RESOURCE_BASE_URI}/meta-information-aggregator-data/${deptAppMetaInformationAggregatorData.id}/content`)).toPromise();
  }

  getContentDatabaseSpecInSchemaSpyFormatById(deptAppMetaInformationAggregatorData: DeptAppMetaInformationAggregatorData): Observable<HttpEvent<{}>> {
    return this.http.request(new HttpRequest('GET', encodeURI(`${RESOURCE_BASE_URI}/meta-information-aggregator-data/${deptAppMetaInformationAggregatorData.id}/content/database-spec/schema-spy`), {
      reportProgress: true,
      responseType: 'blob'
    }));
  }

  async existsByDeptApp(deptApp: DeptApp): Promise<Flag> {
    return await this.http.get<Flag>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/meta-information-aggregator-data/any`)).toPromise();
  }

  async findLatestByIdDeptApp(deptApp: DeptApp): Promise<DeptAppMetaInformationAggregatorData> {
    return await this.http.get<DeptAppMetaInformationAggregatorData>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/meta-information-aggregator-data/latest`)).toPromise();
  }

  async getContentLatestByIdDeptApp(deptApp: DeptApp): Promise<DeptAppMetaInformationAggregatorData>{
    return await this.http.get<DeptAppMetaInformationAggregatorData>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/meta-information-aggregator-data/latest/content`)).toPromise();
  }

  async create(deptApp: DeptApp, deptAppMetaInformationAggregatorData: DeptAppMetaInformationAggregatorData): Promise<Identifier<number>> {
    return await this.http.post<Identifier<number>>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/meta-information-aggregator-data`), deptAppMetaInformationAggregatorData).toPromise();
  }
}
