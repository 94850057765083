import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MDOneExternalConfig } from '../../models/mdone/mdone-external-config.model';
import { environment } from '../../../../environments/environment';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/external-config`;

@Injectable()
export class MDOneExternalConfigService {

  constructor(private http: HttpClient) { }

  async get(): Promise<MDOneExternalConfig> {
    return await this.http.get<MDOneExternalConfig>(encodeURI(RESOURCE_BASE_URI)).toPromise();
  }
}
