import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { DatabaseSpec } from '../../../models/meta-information-aggregator/red-asset-integration/models/database-spec.model';
import { DatabaseTreeNode, DatabaseTreeNodeType } from '../../../models/meta-information-aggregator/red-asset-integration/models/database-tree-node.model';
import { TreeNode } from 'primeng/api';

@Injectable()
export class DatabaseSpecToDatabaseTreeNodeService {

  buildDatabaseSpecTreeNode(databaseSpec: DatabaseSpec, readOnly: boolean = false): DatabaseTreeNode[] {
    const databaseSpecTreeNode: DatabaseTreeNode[] = [];
    if (databaseSpec.schema && databaseSpec.schema.length) {
      for (let schema of databaseSpec.schema) {
        let nodeSchema: DatabaseTreeNode = {
          label: schema.name,
          data: schema.name,
          collapsedIcon: 'fa fa-database',
          expandedIcon: 'fa fa-database',
          selectable: schema.table && schema.table.length > 0,
          expanded: schema.table && schema.table.length > 0,
          children: [],
          databaseType: DatabaseTreeNodeType.TYPE_SCHEMA
        };
        //Si el esquema tiene tabla.
        if (schema.table && schema.table.length > 0) {
          let tableGroupNode: DatabaseTreeNode = {
            label: 'Tables',
            data: 'Tables',
            collapsedIcon: 'fa fa-table',
            expandedIcon: 'fa fa-table',
            selectable: !readOnly,
            expanded: true,
            parent: nodeSchema,
            children: [],
            databaseType: DatabaseTreeNodeType.TYPE_TABLE_GROUP
          };
          for (let table of schema.table) {
            let nodeTable: DatabaseTreeNode = {
              label: table.name,
              data: table.name,
              collapsedIcon: 'fa fa-table',
              expandedIcon: 'fa fa-table',
              selectable: !readOnly,
              expanded: false,
              parent: tableGroupNode,
              children: [],
              databaseType: DatabaseTreeNodeType.TYPE_TABLE
            };

            if (table.primaryKey) {
              let primaryKeyGroup: DatabaseTreeNode = {
                label: 'Primary Key',
                data: 'Primary Key',
                collapsedIcon: 'fab fa-first-order',
                expandedIcon: 'fab fa-first-order',
                selectable: false,
                expanded: false,
                parent: nodeTable,
                leaf: true,
                children: [],
                databaseType: DatabaseTreeNodeType.TYPE_PRIMARY_KEY_GROUP
              };

              let primaryKeyMainNode: DatabaseTreeNode = {
                label:
                  table.primaryKey.name +
                  '(' +
                  table.primaryKey.value.map(value => value.name).join(', ') +
                  ')',
                data: table.primaryKey.name,
                collapsedIcon: 'fab fa-foursquare',
                expandedIcon: 'fab fa-foursquare',
                selectable: false,
                expanded: false,
                leaf: true,
                parent: primaryKeyGroup,
                children: [],
                databaseType: DatabaseTreeNodeType.TYPE_PRIMARY_KEY
              };
              primaryKeyGroup.children.push(primaryKeyMainNode);
              nodeTable.children.push(primaryKeyGroup);
            }

            //De cada tabla, agregamos las columnas!
            /* INICIO PROCESAMOS LAS COLUMNAS */
            if (table.column && table.column.length > 0) {
              let tableColumnGroup: DatabaseTreeNode = {
                label: 'Columnas',
                data: 'Columnas',
                collapsedIcon: 'fa fa-columns',
                expandedIcon: 'fa fa-columns',
                selectable: !readOnly,
                expanded: true,
                parent: nodeTable,
                children: [],
                databaseType: DatabaseTreeNodeType.TYPE_COLUMN_GROUP
              };

              for (let column of table.column) {
                let nodeColumn: DatabaseTreeNode = {
                  label: column.name,
                  data: column.name,
                  collapsedIcon: 'fa fa-columns',
                  expandedIcon: 'fa fa-columns',
                  selectable: !readOnly,
                  expanded: false,
                  parent: tableColumnGroup,
                  children: [],
                  databaseType: DatabaseTreeNodeType.TYPE_COLUMN
                };

                //A cada nodo, le agregamos info extra.
                if (column.type) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Type: ' + column.type, nodeColumn)
                  );
                }
                if (column.nativeType) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Native Type: ' + column.nativeType, nodeColumn)
                  );
                }
                if (column.nullable) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Nullable: ' + column.nullable, nodeColumn)
                  );
                }
                if (column.type) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Auto-Increment: ' + column.type, nodeColumn)
                  );
                }
                if (column.autoincrement) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Length: ' + column.autoincrement, nodeColumn)
                  );
                }
                if (column.precision) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Precision: ' + column.precision, nodeColumn)
                  );
                }
                if (column.scale) {
                  nodeColumn.children.push(
                    this.generateLabelNode('Scale: ' + column.scale, nodeColumn)
                  );
                }
                tableColumnGroup.children.push(nodeColumn);
              }
              nodeTable.children.push(tableColumnGroup);
            }
            /* FIN PROCESAMOS LAS COLUMNAS */

            /* PROCESAMOS LAS FOREIGN KEYS*/
            if (table.foreignKey && table.foreignKey.length > 0) {
              let tableGroupNodeForeignKeys: DatabaseTreeNode = {
                label: 'Foreign Keys',
                data: 'Foreign Keys',
                collapsedIcon: 'fas fa-external-link-alt',
                expandedIcon: 'fas fa-external-link-alt',
                selectable: !readOnly,
                expanded: true,
                parent: nodeTable,
                children: [],
                databaseType: DatabaseTreeNodeType.TYPE_FOREIGN_KEY_GROUP
              };
              for (let foreignKey of table.foreignKey) {
                let canonicalNameTable: string =
                  (foreignKey.destScheme && foreignKey.destScheme.length > 0
                    ? foreignKey.destScheme
                    : '') + foreignKey.destTable;

                let nodeForeignKey: DatabaseTreeNode = {
                  label: foreignKey.name + '-> ' + canonicalNameTable,
                  data: foreignKey.name,
                  collapsedIcon: 'fas fa-external-link-alt',
                  expandedIcon: 'fas fa-external-link-alt',
                  selectable: !readOnly,
                  expanded: false,
                  parent: tableGroupNodeForeignKeys,
                  children: [],
                  databaseType: DatabaseTreeNodeType.TYPE_FOREIGN_KEY
                };
                tableGroupNodeForeignKeys.children.push(nodeForeignKey);
                nodeForeignKey.children.push(
                  this.generateLabelNode('Foreign Key name: ' + foreignKey.name, nodeForeignKey)
                );
                nodeForeignKey.children.push(
                  this.generateLabelNode('Target table: ' + canonicalNameTable, nodeForeignKey)
                );
                if (foreignKey.value && foreignKey.value.length > 0) {
                  let infoForeignKeyMessage = 'Advanced info: ' + table.name + '.';
                  if (foreignKey.value.length > 1) {
                    infoForeignKeyMessage += '(';
                  }
                  for (let i = 0; i < foreignKey.value.length; i++) {
                    infoForeignKeyMessage += foreignKey.value[i].source;
                    if (i + 1 < foreignKey.value.length) {
                      infoForeignKeyMessage += ', ';
                    }
                  }
                  if (foreignKey.value.length > 1) {
                    infoForeignKeyMessage += ')';
                  }
                  infoForeignKeyMessage += ' -> ' + canonicalNameTable + '.';
                  if (foreignKey.value.length > 1) {
                    infoForeignKeyMessage += '(';
                  }
                  for (let i = 0; i < foreignKey.value.length; i++) {
                    infoForeignKeyMessage += foreignKey.value[i].dest;
                    if (i + 1 < foreignKey.value.length) {
                      infoForeignKeyMessage += ', ';
                    }
                  }
                  if (foreignKey.value.length > 1) {
                    infoForeignKeyMessage += ')';
                  }
                  nodeForeignKey.children.push(
                    this.generateLabelNode(infoForeignKeyMessage, nodeForeignKey)
                  );
                }
              }
              nodeTable.children.push(tableGroupNodeForeignKeys);
            }
            tableGroupNode.children.push(nodeTable);
          }
          nodeSchema.children.push(tableGroupNode);
        }
        databaseSpecTreeNode.push(nodeSchema);
      }
    }
    return databaseSpecTreeNode;
  }

  private generateLabelNode(labelField: string, parentNode: TreeNode): DatabaseTreeNode {
    return {
      label: labelField,
      data: labelField,
      collapsedIcon: 'fa fa-info',
      expandedIcon: 'fa fa-info',
      selectable: false,
      expanded: false,
      parent: parentNode,
      children: [],
      databaseType: DatabaseTreeNodeType.TYPE_INFO
    };
  }

}