import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DeptAppMemberPermissionType } from '../../models/app/deptapp-member-permission-type.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp-member-permission-type`;

@Injectable()
export class DeptAppMemberPermissionTypeService {

  constructor(
    private http: HttpClient) { }

  async findAll(): Promise<DeptAppMemberPermissionType[]> {
    return await this.http.get<DeptAppMemberPermissionType[]>(encodeURI(RESOURCE_BASE_URI)).toPromise();
  }
}
