import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';
import { DockerRegistryImageTags } from '../../models/common/docker-registry-image-tags.model';
import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS, IS_AXET_FLOWS_PARAMETER } from '.';
import { CheckVersionDesktopService } from './check-version-desktop.service';

@Injectable()
export class DeptAppsDesktopDockerRegistryService {

  constructor(
    private checkVersionDesktopService: CheckVersionDesktopService,
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private http: HttpClient) { }

  async getDockerRegistryImageDeptAppsFlowsTags(axetFlows: boolean): Promise<DockerRegistryImageTags> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DockerRegistryImageTags>(encodeURI(`${resourceBaseUri}/image/tags/list?${IS_AXET_FLOWS_PARAMETER}=${axetFlows}`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapps-docker-registry`;
  }
}
