import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgxLoginComponent } from './@theme/components/auth/login/login.component';
import { NgxAccessDeniedComponent } from './@theme/components/auth/access-denied/access-denied.component';
import { NgxLogoutComponent } from './@theme/components/auth/logout/logout.component';
import { ActivateIsAuthenticatedGuard } from './@core/security/activate-is-authenticated.guard';
import { NewUserComponent } from './@theme/components/auth/new-user/new-user.component';

const routes: Routes = [
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule', canActivate: [ActivateIsAuthenticatedGuard] },
  {
    path: 'new-user',
    component: NewUserComponent,
  },
  {
    path: 'login',
    component: NgxLoginComponent,
  },
  {
    path: 'access-denied',
    component: NgxAccessDeniedComponent,
  },
  {
    path: 'logout',
    component: NgxLogoutComponent,
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

const config: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [ActivateIsAuthenticatedGuard]
})
export class AppRoutingModule {
}
