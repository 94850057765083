import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthenticatedUser } from '../../models/mdone/authenticated-user.model';
import { RefreshToken } from '../../models/mdone/refresh-token.model';
import { Project } from '../../models/mdone/project.model';
import { LocalStorageService } from '../../utils/local-storage.service';
import { Observable } from 'rxjs';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}`;

@Injectable()
export class AuthRemoteService {
  

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) { }

  async getUserInfo(): Promise<AuthenticatedUser> {
    return await this.http.get<AuthenticatedUser>(encodeURI(`${RESOURCE_BASE_URI}/user/info`)).toPromise();
  }

  async refreshJwtToken(jwtHeader: string, jwtValue: string): Promise<RefreshToken> {
    return await this.http.get<RefreshToken>(encodeURI(`${RESOURCE_BASE_URI}/token/refresh`),
      {
        headers: { [jwtHeader]: jwtValue },
      }).toPromise();
  }

  async applySuccessAuthenticationFirstTime(selectedProject: Project): Promise<boolean> {
    return this.localStorageService.setMDOneSelectedProject(selectedProject);
  }
}
