import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { LocalStorageService } from '../../utils/local-storage.service';
import { JwtUtilsService } from '../../utils/jwt-utils.service';
import { DeptApp } from '../../models/app/deptapp.model';
import { Identifier } from '../../models/common/identifier.model';
import { IdentifierList } from '../../models/common/identifier-list.model';
import { DeptAppSearchFilter } from '../../models/app/filters/deptapp.search-filter';
import { coreResponse, deptappCategory, useCaseCategory } from '../../models/app/deptapp-useCase.interface';
import { Subject } from 'rxjs';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;

@Injectable()
export class DeptAppService {

  private $changeStateDeptapp = new Subject<DeptApp>();

  constructor(
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService,
    private http: HttpClient) { }

  async findBySearchFilterPagination(searchFilter?: DeptAppSearchFilter, limit?: number): Promise<PaginationResult<DeptApp>> {
    searchFilter = searchFilter || {};
    const { ownerUserMe, discharged, ownerEmailUser, page, searchText, idMDOneProject, member, id } = searchFilter;
    const isUserAdmin = this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue());

    let idMDOneProjectSelected;
    if (!!idMDOneProject) {
      idMDOneProjectSelected = idMDOneProject;
    } else if (!isUserAdmin && this.localStorageService.hasMDOneSelectedProject()) {
      idMDOneProjectSelected = this.localStorageService.getMDOneSelectedProject().id;
    }

    return await this.http.post<PaginationResult<DeptApp>>(encodeURI(`${RESOURCE_BASE_URI}/page`), {
      "owner-user-me": (!!ownerUserMe).toString(),
      "discharged": (!!discharged).toString(),
      "owner-email-user": !!isUserAdmin && !ownerUserMe && ownerEmailUser && ownerEmailUser.length ? ownerEmailUser : undefined,
      "page": page && page > 0 ? page.toString() : undefined,
      "limit": limit && limit > 0 ? limit.toString() : undefined,
      "q": searchText && searchText.length ? searchText : "",
      "idMDOneProject": idMDOneProjectSelected ? idMDOneProjectSelected.toString() : undefined,
      "member": member && member.length > 0 ? member : undefined,
      "id": !!id ? id.toString() : undefined
    }).toPromise();
  }

  async findByIds(identifierList: IdentifierList<number>): Promise<DeptApp[]> {
    return await this.http.post<DeptApp[]>(encodeURI(`${RESOURCE_BASE_URI}/query`), identifierList).toPromise();
  }

  async findById(deptApp: DeptApp): Promise<DeptApp> {
    return await this.http.get<DeptApp>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}`)).toPromise();
  }

  async create(deptApp: DeptApp): Promise<Identifier<number>> {
    return await this.http.post<Identifier<number>>(encodeURI(RESOURCE_BASE_URI), deptApp).toPromise();
  }

  async update(deptApp: DeptApp): Promise<any> {
    return await this.http.put(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}`), deptApp).toPromise();
  }

  async fork(deptApp: DeptApp, mergeMetadataDeptApp: DeptApp): Promise<DeptApp> {
    return await this.http.post<DeptApp>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/fork`), mergeMetadataDeptApp).toPromise();
  }

  async block(deptApp: DeptApp): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/block`), {}).toPromise();
  }

  async unblock(deptApp: DeptApp): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/unblock`), {}).toPromise();
  }

  async delete(deptApp: DeptApp): Promise<any> {
    return await this.http.delete(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}`)).toPromise();
  }

  async enableAgain(deptApp: DeptApp): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/enable/again`), {}).toPromise();
  }

  async getDeptAppIdsByFilter(searchFilter?: DeptAppSearchFilter): Promise<[]> {

    const { ownerUserMe, discharged, ownerEmailUser, searchText, idMDOneProject, member, id } = searchFilter;
    const isUserAdmin = this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue());

    let idMDOneProjectSelected;
    if (!!idMDOneProject) {
      idMDOneProjectSelected = idMDOneProject;
    } else if (!isUserAdmin && this.localStorageService.hasMDOneSelectedProject()) {
      idMDOneProjectSelected = this.localStorageService.getMDOneSelectedProject().id;
    }

    return await this.http.post<[]>(encodeURI(`${RESOURCE_BASE_URI}/page/deptAppId`),  {
      "owner-user-me": (!!ownerUserMe).toString(),
      "discharged": (!!discharged).toString(),
      "owner-email-user": !!isUserAdmin && !ownerUserMe && ownerEmailUser && ownerEmailUser.length ? ownerEmailUser : undefined,
      "page": 1,
      "limit": 9999,
      "q": searchText && searchText.length ? searchText : "",
      "idMDOneProject": idMDOneProjectSelected ? idMDOneProjectSelected.toString() : undefined,
      "member": member && member.length > 0 ? member : undefined,
      "id": !!id ? id.toString() : undefined
    }).toPromise();
  }

  getCategoryList(){
    return this.http.post<coreResponse<deptappCategory>>(encodeURI(`${environment.apiUrlBase}/core/getUseCaseCategoryList`),null,{headers : {'Content-Type': 'application/json'}});
  }

  getCategoryUseCaseList( categoryCode : string){
    return this.http.post<coreResponse<useCaseCategory>>(encodeURI(`${environment.apiUrlBase}/core/getUseCaseList`),{
      categoryCode
    },{headers : {'Content-Type': 'application/json'}});
  }

  getChangeStateDeptapp(){
    return this.$changeStateDeptapp
  }

  setChangeStateDeptapp(deptapp : DeptApp) {
    return this.$changeStateDeptapp.next(deptapp);
  }

  isBlocked(deptapp: DeptApp): boolean {
    return deptapp.blockedBy !== undefined && deptapp.blockedBy !== null;
  }
}
