import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { InitialStateConfig, CredentialsPasswordConfig, ConfirmDialogActionEnum } from './credentials-password-dialog.model';

@Injectable()
export class CredentialsPasswordService {
  private credentialsPasswordSubject = new Subject<CredentialsPasswordConfig>();
  pass: string;
  file: File;
  credentialsPasswordObservable = this.credentialsPasswordSubject.asObservable();
  private readOnly = new BehaviorSubject<any>({});
  public customReadOnly = this.readOnly.asObservable();

  resetReadOnly(value: any): void {
    this.readOnly.next(value);
  }

  confirm(behavior: { accept: () => void, cancel?: () => void }, initialState?: InitialStateConfig): void {
    this.credentialsPasswordSubject.next({
      action: ConfirmDialogActionEnum.SHOW,
      initialState: initialState,
      accept: behavior.accept,
      cancel: behavior.cancel,
    });
  }

  hide(): void {
    this.credentialsPasswordSubject.next({ action: ConfirmDialogActionEnum.HIDE });
  }

  stopSubmitted(): void {
    this.credentialsPasswordSubject.next({ action: ConfirmDialogActionEnum.STOP_SUBMITTED });
  }

  setCredentialsPassword(pass){
    this.pass = pass;
  }

  getCredentialsPassword() {
    return this.pass;
  }

  setCredentialsFile(file) {
    this.file = file;
  }

  getCredentialsFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if(!file) {
        resolve('');
      }
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const text = fileReader.result.toString();
        resolve(text);
      }
      fileReader.onerror = () => {
        reject(new Error ('Error to read file!'))
      }
      fileReader.readAsText(this.file);
    })
  }

  async getEncryptedText(){
    const text = await this.getCredentialsFile(this.file);
    return text;
  }

}
