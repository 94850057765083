export class UserClaims {
  sub?: string;
  name: string;
  fsurname?: string;
  ssurname?: string;
  last_name?: string;
  email?: string;
  roles?: string[];

  get fullName(): string {
    let result: string = this.name;
    if (this.last_name && this.last_name.length) {
      result += ` ${this.last_name}`;
    }
    return result;
  }
}
