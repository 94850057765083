import {
  Component, OnInit, TemplateRef, ViewChild, OnDestroy,
} from '@angular/core';
import { CredentialsPasswordService } from './credentials-password-dialog.service';
import { ConfirmDialogActionEnum, ConfirmDialogBodyMessage } from './credentials-password-dialog.model';
import { Subscription } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { UtilsService } from '../../../@core/utils/utils.service';
import { EncriptUtilsService } from '../../../@core/utils/encript-utils.service';
import { TypeAlertEnum } from '../alert/alert.model';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'ngx-credentials-password-dialog',
  templateUrl: './credentials-password-dialog.component.html',
})
export class NgxCredentialsPasswordComponent implements OnInit, OnDestroy {
  @ViewChild('credentialsPasswordModal') credentialsPasswordModal: TemplateRef<any>;

  headerTitle: string;
  bodyMessage: string[] | string | ConfirmDialogBodyMessage | ConfirmDialogBodyMessage[];
  yesButtonValue: string;
  noButtonValue: string;
  sizeDialog: 'sm' | 'lg';
  extraCancelButton: boolean;
  isExportModal: boolean;
  submitted: boolean;
  password: string;

  acceptAction: () => void;
  cancelAction: () => void;

  modalOpenRef: NgbModalRef;

  credentialsPasswordObservableSubscription: Subscription;
  credentialsPassword: string = '';
  passwordValid: boolean = false;
  credentialsFile: File;

  constructor(
    private modalService: NgbModal,
    private credentialsPasswordService: CredentialsPasswordService,
    private utilsService: UtilsService,
    private alertService: AlertService,
    private encriptUtilsService: EncriptUtilsService
  ) {
  }

  ngOnInit(): void {
    this.submitted = false;

    this.credentialsPasswordObservableSubscription = this.credentialsPasswordService.credentialsPasswordObservable.subscribe(config => {
      if (config.action === ConfirmDialogActionEnum.SHOW) {
        this.hideConfirmDialogWithoutCallback();
        config.initialState = config.initialState || {};
        this.headerTitle = config.initialState.headerTitle;
        this.bodyMessage = config.initialState.bodyMessage;
        this.yesButtonValue = config.initialState.yesButtonValue || 'Confirm';
        this.noButtonValue = config.initialState.noButtonValue || 'Cancel';
        this.sizeDialog = config.initialState.sizeDialog || 'sm';
        this.extraCancelButton = config.initialState.extraCancelButton || false;
        this.isExportModal = config.initialState.isExportModal || false;
        this.acceptAction = config.accept;
        this.cancelAction = config.cancel;
        this.modalOpenRef = this.modalService.open(this.credentialsPasswordModal, {
          size: this.sizeDialog,
          backdrop: 'static',
          keyboard: false,
          centered: true,
        });
      } else if (config.action === ConfirmDialogActionEnum.HIDE) {
        this.hideConfirmDialogWithoutCallback();
        this.exportPassword();
        this.exportCredentialsFile();
        this.submitted = false;
      } else if (config.action === ConfirmDialogActionEnum.STOP_SUBMITTED) {
        this.submitted = false;
      }
    });
  }

  isMultipleBodyMessage() {
    return this.bodyMessage && _.isArray(this.bodyMessage);
  }

  isBodyMessageObject(singleBodyMessage: string | ConfirmDialogBodyMessage) {
    return singleBodyMessage && _.isObject(singleBodyMessage) && !_.isEmpty(singleBodyMessage);
  }

  hideConfirmDialogWithoutCallback(): void {
    if (this.modalOpenRef) {
      this.modalOpenRef.close();
      this.modalOpenRef = undefined;
    }
  }

  hideConfirmDialog(): void {
    this.credentialsPassword = '';
    this.credentialsFile = null;
    this.hideConfirmDialogWithoutCallback();
    this.cancelAction && this.cancelAction();
  }

  executeAction(): void {
    if(this.credentialsFile) {
      this.credentialsPasswordService.resetReadOnly({});
    }
    this.submitted = true;
    this.acceptAction && this.acceptAction();
    this.credentialsPassword = '';
    this.credentialsFile = null;
  }

  toggleTypeInputPassword(inputCredentialsPassword: HTMLInputElement) {
    this.utilsService.toggleTypeInputPassword(inputCredentialsPassword);
  }

  passwordChange(event) {
    this.credentialsPassword = event.target.value;
    if(this.credentialsPassword.length != 25) {
      this.passwordValid=false;
      this.alertService.addAlertMessage({
        type: TypeAlertEnum.ERROR,
        summary: 'You must enter 25 characters!',
      });
    } else {
      this.passwordValid=true;
    }
  }

  generateAlphanumericPass(){
    this.credentialsPassword = this.encriptUtilsService.generateAlphanumericPass(25);
    this.passwordValid=true;
  }

  exportPassword() {
    return this.credentialsPasswordService.setCredentialsPassword(this.credentialsPassword);
  }

  importCredentialsFile(files: FileList) {
    this.credentialsFile = files.item(0);
  }

  exportCredentialsFile() {
    return this.credentialsPasswordService.setCredentialsFile(this.credentialsFile);
  }

  ngOnDestroy(): void {
    this.credentialsPasswordObservableSubscription.unsubscribe();
  }
}
