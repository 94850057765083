export class AlertMessage {
  id?: string;
  summary: string;
  details?: string;
  type: TypeAlertEnum;
}

export enum TypeAlertEnum {
  SUCCESS = 'primary', ERROR = 'danger', WARNING = 'warning',
}
