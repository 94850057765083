import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  StateService,
} from './utils';
import { LocalStorageService } from './utils/local-storage.service';
import { ServicesModule } from './services/services.module';
import { JwtUtilsService } from './utils/jwt-utils.service';
import { UtilsService } from './utils/utils.service';
import { SessionStorageService } from './utils/session-storage.service';
import { LimitResults } from './utils/limit-results';
import { EncriptUtilsService } from './utils/encript-utils.service';

export const NB_CORE_PROVIDERS = [
  ...ServicesModule.forRoot().providers,
  AnalyticsService,
  StateService,
  LocalStorageService,
  SessionStorageService,
  JwtUtilsService,
  UtilsService,
  LimitResults,
  EncriptUtilsService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
