import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDOneExternalConfigService } from './mdone/mdone-external-config.service';
import { AuthRemoteService } from './mdone/auth-remote.service';
import { ClientRemoteService } from './mdone/client-remote.service';
import { ProjectRemoteService } from './mdone/project-remote.service';
import { AppInternalConfigService } from './app/app-internal-config.service';
import { DeptAppMemberPermissionTypeService } from './app/deptapp-member-permission-type.service';
import { DeptAppService } from './app/deptapp.service';
import { DeptAppVersionService } from './app/deptapp-version.service';
import { DeptAppMemberService } from './app/deptapp-member.service';
import { UserRemoteService } from './mdone/user-remote.service';
import { AppInternalDesktopConfigurationService } from './desktop/app-internal-desktop-configuration.service';
import { CheckVersionDesktopService } from './desktop/check-version-desktop.service';
import { DeptAppDesktopService } from './desktop/deptapps-desktop.service';
import { DeptAppWebSocketObservable } from './app/websocket/deptapp-websocket.observable';
import { DeptAppDesktopWebSocketService } from './desktop/websocket/deptapps-desktop-websocket.service';
import { RedAssetIntegrationDesktopService } from './meta-information-aggregator/desktop/red-asset-integration-desktop.service';
import {
  DatabaseSpecToMDoneProjectModelToModelService,
} from './meta-information-aggregator/model-to-model/database-spec-to-mdone-project.model-to-model.service';
import { DeptAppMetaInformationAggregatorDataService } from './app/deptapp-meta-information-aggregator-data.service';
import { DeptAppNodeFlowsResolvedCredentialPreferencesService } from './desktop/deptapp-node-flows-resolved-credential-preferences.service';
import {
  SwaggerSpecToMDoneProjectModelToModelService,
} from './meta-information-aggregator/model-to-model/swagger-spec-to-mdone-project.model-to-model.service';
import { TreeNodesUtilService } from './meta-information-aggregator/utils/tree-nodes-utils.service';
import {
  DatabaseSpecToDatabaseTreeNodeService,
} from './meta-information-aggregator/model-to-model/database-spec-to-database-tree-node.service';
import { DeptAppsFlowsThirdPartyLibraryCertifiedService } from './app/deptapps-flows-third-party-library-version-certified.service';
import { DeptAppsFlowsPrivateLibraryService } from './app/deptapps-flows-private-library.service';
import { DeptAppsFlowsSharedSubflowService } from './app/deptapps-flows-shared-subflow.service';
import { DeptAppsFlowsSharedSubflowVersionService } from './app/deptapps-flows-shared-subflow-version.service';
import { DeptAppResolvedDeploymentOptionsPreferencesService } from './desktop/deptapp-resolved-deployment-options-preferences.service';
import { UserPreferencesService } from './app/user-preferences.service';
import { DeptAppsDesktopConnectionEndpointService } from './desktop/internal/deptapps-desktop-connection-endpoint.service';
import { MetaInformationAggregatorDataService } from './meta-information-aggregator/app/meta-information-aggregator-data.service';
import { DeptAppsDesktopDockerRegistryService } from './desktop/deptapps-desktop-docker-registry.service';
import { DeptAppRemoteDeploymentOptionsPreferencesService } from './desktop/deptapp-remote-deployment-options-preferences.service';
import { DeptAppRemoteDeployConnectToServerDataPreferencesService } from './desktop/deptapp-remote-deploy-connect-to-server-data-preferences.service';
import { DeptAppStandaloneElectronDeployOptionsPreferencesService } from './desktop/deptapp-standalone-electron-deploy-options-preferences.service';
import { DeptAppsVisualStudioAppCenterService } from './desktop/deptapps-visual-studio-app-center.service';
import { AppcenterDesktopReleasesService } from './app/app-center-desktop-releases.service';
import { UserDesktopPreferencesService } from './desktop/user-desktop-preferences.service';
import { DeptAppsFlowsNodeService } from './app/deptapps-flows-node.service';
import { DeptAppsFlowsCustomUseRegisterService } from './app/deptapps-flows-custom-use-register.service';
import { DeptAppAdministratorSettingsService } from './app/deptapp-administrator-settings.service';
import { DeptAppsWebDockerRegistryManagementService } from './app/deptapps-web-docker-registry-management.service';
import { DeptAppForKubernetesRemoteDeployOptionsPreferencesService } from './desktop/deptapp-for-k8s-remote-deploy-options-preferences.service';
import { PostService } from './wordpress/post.service';
//Autodeploy service
import { AutoDeployService } from './autodeploy/auto-deploy.service';
import { OKTAService } from './okta/okta.service';


const AUTO_DEPLOY_SERVICES = [
  AutoDeployService
]

const OKTA_SERVICES = [
  OKTAService
]

const MDONE_SERVICES = [
  MDOneExternalConfigService,
  AuthRemoteService,
  ClientRemoteService,
  ProjectRemoteService,
  UserRemoteService,
];

const APP_SERVICES = [
  AppInternalConfigService,
  DeptAppService,
  DeptAppVersionService,
  DeptAppMemberService,
  DeptAppMemberPermissionTypeService,
  DeptAppMetaInformationAggregatorDataService,
  DeptAppsFlowsThirdPartyLibraryCertifiedService,
  DeptAppsFlowsPrivateLibraryService,
  DeptAppsFlowsSharedSubflowService,
  DeptAppsFlowsSharedSubflowVersionService,
  UserPreferencesService,
  DeptAppsFlowsNodeService,
  DeptAppsFlowsCustomUseRegisterService,
  DeptAppsWebDockerRegistryManagementService,
  DeptAppAdministratorSettingsService,
  AppcenterDesktopReleasesService
];

const APP_OBSERVABLES = [
  DeptAppWebSocketObservable
];

const DESKTOP_SERVICES = [
  DeptAppsDesktopConnectionEndpointService,
  AppInternalDesktopConfigurationService,
  CheckVersionDesktopService,
  DeptAppDesktopService,
  DeptAppDesktopWebSocketService,
  DeptAppNodeFlowsResolvedCredentialPreferencesService,
  DeptAppResolvedDeploymentOptionsPreferencesService,
  DeptAppRemoteDeploymentOptionsPreferencesService,
  DeptAppRemoteDeployConnectToServerDataPreferencesService,
  DeptAppForKubernetesRemoteDeployOptionsPreferencesService,
  DeptAppStandaloneElectronDeployOptionsPreferencesService,
  DeptAppsDesktopDockerRegistryService,
  DeptAppsVisualStudioAppCenterService,
  UserDesktopPreferencesService
];

const META_INFORMATION_AGGREGATOR_SERVICES = [
  RedAssetIntegrationDesktopService,
  DatabaseSpecToMDoneProjectModelToModelService,
  DatabaseSpecToDatabaseTreeNodeService,
  SwaggerSpecToMDoneProjectModelToModelService,
  TreeNodesUtilService,
  MetaInformationAggregatorDataService
];

const WORDPRESS_SERVICES = [
  PostService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...MDONE_SERVICES, ...APP_SERVICES, ...APP_OBSERVABLES, ...DESKTOP_SERVICES, ...META_INFORMATION_AGGREGATOR_SERVICES, ...WORDPRESS_SERVICES, ...AUTO_DEPLOY_SERVICES, ...OKTA_SERVICES
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ServicesModule,
      providers: [
        ...MDONE_SERVICES, ...APP_SERVICES, ...APP_OBSERVABLES, ...DESKTOP_SERVICES, ...META_INFORMATION_AGGREGATOR_SERVICES, ...WORDPRESS_SERVICES, ...AUTO_DEPLOY_SERVICES, ...OKTA_SERVICES
      ],
    };
  }
}
