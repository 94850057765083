import { TreeNode } from 'primeng/api';

export enum MDOneProjectTreeNodeType {
  TYPE_PARENT, TYPE_ASPECTS, TYPE_COMPONENTS, TYPE_CONTROLLERS,
  TYPE_OPERATION_CONTROLLERS, TYPE_CONTRACTMODELS, TYPE_BUSINESSSERVICES,
  TYPE_BUSSINESSENTITIES, TYPE_REPOSITORIES, TYPE_ENTITIES, TYPE_ENUMS, TYPE_SCHEDULER, TYPE_MASTER_NODE,
  TYPE_BUSSINESS_ENTITY_RELATIONSHIPS, TYPE_CONTRACT_RELATIONSHIPS, TYPE_RELATIONSHIPS, TYPE_BOOTSTRAPERS, TYPE_APPLICATION
}

export interface MDOneProjectTreeNode extends TreeNode {
  baseLabel?: string;
  nodeType?: MDOneProjectTreeNodeType;
  code?: any;
  uuid?: string;
}