import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgSelectModule } from '@ng-select/ng-select';


import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbListModule,
  NbToastrModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule
} from '@nebular/theme';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  SwitcherComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  FilterPipe
} from './pipes';

import { CORPORATE_THEME } from './styles/theme.corporate';
import { NgxExternalPageLayoutComponent } from './components/layouts/external-page-layout/external-page-layout.component';
import { NgxLoginComponent } from './components/auth/login/login.component';
import { NgxAccessDeniedComponent } from './components/auth/access-denied/access-denied.component';
import { NgxAlertsComponent } from './components/alert/alert.component';
import { AlertService } from './components/alert/alert.service';
import { NgxInternalPageLayoutComponent } from './components/layouts/internal-page-layout/internal-page-layout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { CarouselModule } from 'primeng/carousel';
import { NgxSignOutLayoutComponent } from './components/layouts/sign-out-layout/sign-out-layout.component';
import { NgxLogoutComponent } from './components/auth/logout/logout.component';
import { NgxCarouselItemComponent } from './components/carousel-item/carousel-item.component';
import { NgxLoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { LoadingDialogService } from './components/loading-dialog/loading-dialog.service';
import { NgxConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './components/confirm-dialog/confirm-dialog.service';
import { NgxLegendMapComponent } from './components/legend-map/legend-map.component';
import { NgxLoadingInlineComponent } from './components/loading-inline/loading-inline.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgxCredentialsPasswordComponent } from './components/credentials-password-dialog/credentials-password-dialog.component';
import { CredentialsPasswordService } from './components/credentials-password-dialog/credentials-password-dialog.service';
import { NewUserComponent } from './components/auth/new-user/new-user.component';
import { EditableUrlComponent } from './components/editable-url/editable-url.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, FontAwesomeModule, CodemirrorModule];

const PRIMENG_MODULES = [
  CarouselModule,
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbTooltipModule,
];

const COMPONENTS = [
  SwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  NgxExternalPageLayoutComponent,
  NgxInternalPageLayoutComponent,
  NgxSignOutLayoutComponent,
  NgxCarouselItemComponent,
  NgxLogoutComponent,
  NgxLoginComponent,
  NgxAccessDeniedComponent,
  NgxAlertsComponent,
  NgxLoadingDialogComponent,
  NgxLoadingInlineComponent,
  NgxConfirmDialogComponent,
  NgxLegendMapComponent,
  NgxCredentialsPasswordComponent,
  EditableUrlComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  FilterPipe
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'corporate',
    },
    [CORPORATE_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
];

const NGX_THEME_PROVIDERS = [AlertService, LoadingDialogService, ConfirmDialogService, CredentialsPasswordService];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...PRIMENG_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES, NewUserComponent],
})
export class ThemeModule {

  constructor() {
    library.add(fas, fab);
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS, ...NGX_THEME_PROVIDERS],
    };
  }
}
