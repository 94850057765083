export class LoadingDialogConfig {
  action: LoadingDialogActionEnum;
  isDownloadFile?: boolean;
  loaded?: number;
  total?: number;
}

export enum LoadingDialogActionEnum {
  SHOW,
  UPDATE_DOWNLOAD_PROGRESS,
  HIDE,
}
