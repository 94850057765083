export class VersionXYZ {
  x: number;
  y: number;
  z: number;
  separatorSuffix: string;
  suffix: string;

  constructor(x: number, y: number, z: number, separatorSuffix: string, suffix: string) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.separatorSuffix = separatorSuffix;
    this.suffix = suffix;
  }

  compareTo(other: VersionXYZ) {
    if (!other) {
      return 1;
    }
    if (this.x > other.x) {
      return 1;
    }
    else if (this.x < other.x) {
      return -1;
    }
    else {

      if (this.y > other.y) {
        return 1;
      }
      else if (this.y < other.y) {
        return -1;
      }
      else {
        if (this.z > other.z) {
          return 1;
        }
        else if (this.z < other.z) {
          return -1;
        }
        else {
          return this.suffix.localeCompare(other.suffix);
        }
      }
    }
  }

  static fromString(version: string) {

    let x: number = -1;
    let y: number = -1;
    let z: number = -1;
    let separatorSuffix: string = '';
    let suffix: string = '';

    let versionSplit: string[] = version.split('.');
    x = parseInt(versionSplit[0]);
    if (versionSplit.length > 1) {
      try {
        y = parseInt(versionSplit[1]);
      } catch (e) {
        y = -1;
        separatorSuffix = '.';
      }

      if (versionSplit.length > 2) {
        try {
          let zStringSplit: string[] = versionSplit[2].split('-');
          z = parseInt(zStringSplit[0]);
          if (zStringSplit.length > 1) {
            separatorSuffix = '-';
            suffix = zStringSplit[1];
          } else {
            suffix = '';
          }
        } catch (e) {
          separatorSuffix = '.';
          suffix = versionSplit[2];
        }

        if (z != -1 && versionSplit.length > 3) {
          if (!separatorSuffix || separatorSuffix.length <= 0) {
            separatorSuffix = '.';
          }
          if (!suffix || suffix.length <= 0) {
            suffix = '';
          }
          for (let i = 3; i < versionSplit.length; ++i){
            if (i > 3 || separatorSuffix != '.') {
              suffix += '.';
            }
            suffix += versionSplit[i];
          }
        }
      }
    }
    return new VersionXYZ(x, y, z, separatorSuffix, suffix);
  }

}
