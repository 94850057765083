import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppForStandaloneElectronDeployOptions } from '../../models/desktop/deptapp-for-standalone-electron-deploy-options.model';
import { Flag } from '../../models/common/flag.model';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';
import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '.';

import * as _ from 'lodash';


@Injectable()
export class DeptAppStandaloneElectronDeployOptionsPreferencesService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async existsByDeptApp(deptApp: DeptApp): Promise<Flag> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<Flag>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-standalone-electron-deploy-options-preferences/exists`)).toPromise();
  }

  async findByDeptApp(deptApp: DeptApp): Promise<DeptAppForStandaloneElectronDeployOptions> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppForStandaloneElectronDeployOptions>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-standalone-electron-deploy-options-preferences`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp`;
  }
}