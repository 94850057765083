import { Injectable } from '@angular/core';
import { MDOneProjectTreeNode, MDOneProjectTreeNodeType } from '../../../models/meta-information-aggregator/enums/mdone-project-tree-node.model';

import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class TreeNodesUtilService {

  constructor() { }

  generateParentNodeSwaggerTree(
    name: string,
    icon: string,
    expanded: boolean,
    bMaster: boolean,
    parentNode: MDOneProjectTreeNode,
  ): MDOneProjectTreeNode {
    return {
      label: name,
      baseLabel: name,
      data: name,
      collapsedIcon: icon,
      expandedIcon: icon,
      selectable: true,
      expanded: expanded,
      children: [],
      nodeType:
        bMaster === true
          ? MDOneProjectTreeNodeType.TYPE_MASTER_NODE
          : MDOneProjectTreeNodeType.TYPE_PARENT,
      code: '',
      parent: parentNode,
      uuid: uuidv4(),
    };
  }

  generateChildNodeSwaggerTree(
    name: string,
    icon: string,
    type: MDOneProjectTreeNodeType,
    code: any,
    parentNode: MDOneProjectTreeNode,
  ): MDOneProjectTreeNode {
    return {
      label: name,
      baseLabel: name,
      data: name,
      collapsedIcon: icon,
      expandedIcon: icon,
      selectable: true,
      expanded: false,
      children: [],
      nodeType: type,
      code: code,
      parent: parentNode,
      uuid: uuidv4(),
    };
  }

}
