import { Component, Input } from '@angular/core';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';

@Component({
  selector: 'ngx-internal-page-layout',
  templateUrl: './internal-page-layout.component.html',
  styleUrls: ['./internal-page-layout.component.scss'],
  animations: [
    trigger('show-hide-animation', [
      transition(
        ':enter',
        animate(
          '1s',
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0.1, offset: 0.1 }),
            style({ opacity: 0.2, offset: 0.2 }),
            style({ opacity: 0.3, offset: 0.3 }),
            style({ opacity: 0.4, offset: 0.4 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 0.6, offset: 0.6 }),
            style({ opacity: 0.7, offset: 0.7 }),
            style({ opacity: 0.8, offset: 0.8 }),
            style({ opacity: 0.9, offset: 0.9 }),
            style({ opacity: 1, offset: 1 }),
          ]),
        ),
      ),
    ]),
  ],
})
export class NgxInternalPageLayoutComponent {
  @Input()
  showHeader: boolean = true;
  @Input()
  showAlerts: boolean = true;
  @Input()
  showPoweredBy: boolean = false;



}
