import { VersionXYZ } from '../../models/common/version-xyz.model';

export const DEFAULT_HOST_DEPTAPPS_DESKTOP = 'https://localhost';
/* MD|ONE Desktop default connection */
export const DEFAULT_PORT_DEPTAPPS_DESKTOP = 65430;

/* MD|ONE expected versions features */
export const DEPTAPPS_DESKTOP_EXPECTED_VERSIONS = {
  DOCKER_MANAGEMENT: VersionXYZ.fromString('3.4.0'),
  REMOTE_DEPLOYMENT: VersionXYZ.fromString('3.4.0'),
  STANDALONE_ELECTRON_DEPLOYMENT: VersionXYZ.fromString('3.4.0'),
  CREDENTIALS_PREFERENCES: VersionXYZ.fromString('3.4.0'),
  DEPLOYMENT_OPTIONS_PREFERENCES: VersionXYZ.fromString('3.4.0'),
  RED_ASSET_INTEGRATION: VersionXYZ.fromString('3.4.0')
};

export const IS_AXET_FLOWS_PARAMETER = 'axetFlows';
