import { Component } from "@angular/core";

@Component({
  selector: "ngx-loading-inline",
  styleUrls: ["./loading-inline.component.scss"],
  templateUrl: "./loading-inline.component.html"
})
export class NgxLoadingInlineComponent {

}
