import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { DeptAppsFlowsSharedSubflow } from '../../models/app/deptapps-flows-shared-subflow.model';
import { DeptAppsFlowsSharedSubflowVersion } from '../../models/app/deptapps-flows-shared-subflow-version.model';
import { DeptAppsFlowsSharedSubflowAdvancedSearchSearchFilter } from '../../models/app/filters/deptapps-flows-shared-subflow-advanced-search.search-filter';
import { DeptAppsFlowsNode } from '../../models/app/deptapps-flows-node.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-flows-shared-subflow`;

@Injectable()
export class DeptAppsFlowsSharedSubflowVersionService {

  constructor(
    private http: HttpClient) { }

  async findBySearchFilter(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow, searchText?: string,
    page?: number, limit?: number): Promise<PaginationResult<DeptAppsFlowsSharedSubflowVersion>> {
    let params = new HttpParams();
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppsFlowsSharedSubflowVersion>>(encodeURI(
      `${RESOURCE_BASE_URI}/${deptAppsFlowsSharedSubflow.id}/version`), { params }).toPromise();
  }

  async getSharedSubflowVersionsThatDependOnById(deptAppsFlowsSharedSubflowVersion: DeptAppsFlowsSharedSubflowVersion): Promise<DeptAppsFlowsSharedSubflowVersion[]> {
    return await this.http.get<DeptAppsFlowsSharedSubflowVersion[]>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppsFlowsSharedSubflowVersion.id}/shared-subflow-versions-that-depend-on`)).toPromise();
  }

  async exportInV1Format(deptAppsFlowsSharedSubflowVersion: DeptAppsFlowsSharedSubflowVersion): Promise<ArrayBuffer> {
    return await this.http.get<ArrayBuffer>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppsFlowsSharedSubflowVersion.id}/export/v1`), {
      responseType: 'arraybuffer' as 'json',
    }).toPromise();
  }

  async findByAdvancedSearchFilter(searchFilter: DeptAppsFlowsSharedSubflowAdvancedSearchSearchFilter, limit?: number): Promise<PaginationResult<DeptAppsFlowsSharedSubflowVersion>> {
    let params = new HttpParams();
    if (searchFilter.page && searchFilter.page > 0) {
      params = params.append('page', searchFilter.page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.post<PaginationResult<DeptAppsFlowsSharedSubflowVersion>>(encodeURI(`${RESOURCE_BASE_URI}/version/shared-flows-advanced-search`), searchFilter, { params }).toPromise();
  }

  async findAllUsedNodesDeptAppFlowsSharedSubflows(): Promise<DeptAppsFlowsNode[]> {
    return await this.http.get<DeptAppsFlowsNode[]>(encodeURI(RESOURCE_BASE_URI)).toPromise();
  }

  async exportExcelAdvancedDeptAppSearch(searchFilter: DeptAppsFlowsSharedSubflowAdvancedSearchSearchFilter): Promise<Blob> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/version/shared-flows-advanced-search/export`), searchFilter, { responseType: 'blob' }).toPromise();
  }
}
