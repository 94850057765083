import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { LocalStorageService } from "../../utils/local-storage.service";

import * as _ from "lodash";


const RESOURCE_BASE_URI = `${environment.apiUrlBase}/core`;

@Injectable()
export class OKTAService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService) {

  }

  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "X-Auth-Token": this.localStorageService.getJwtValue(),
    })
  };

  async getUserRegion(id: string): Promise<string> {
    let result = await this._getUserData(id);
    return result["region"];
  }

  async _getUserData(id: string): Promise<Object> {
    return await this.http.post<Object>(encodeURI(`${RESOURCE_BASE_URI}/getUser`), {"id": id}, this.httpOptions).toPromise();
  }
}
