import { Component, } from '@angular/core';
import { Router } from '@angular/router';
import { UserRemoteService }  from "../../../../@core/services/mdone/user-remote.service";
import { AlertService } from "../../alert/alert.service";
import { TypeAlertEnum } from "../../alert/alert.model";

@Component({
  selector: 'ngx-sign-out-layout',
  templateUrl: './sign-out-layout.component.html',
  styleUrls: ['./sign-out-layout.component.scss'],
})
export class NgxSignOutLayoutComponent {

  constructor(
    private router: Router,
    private userRemoteService : UserRemoteService,
    private alertService: AlertService,
  ) {}

  registerNewUser(): void {
    this.userRemoteService.checkIfNewUserEmailIsValid().subscribe(
      (userDetails) => {
        if(userDetails) {
          this.userRemoteService.setUserDetails(userDetails);
          this.router.navigate(["new-user"], {
            queryParams: {},
            preserveQueryParams: false,
          });
        }
      },
      (error) => {
        if (error.status === 400) {
          this.alertService.addAlertMessage({
            type: TypeAlertEnum.ERROR,
            summary: "User not found! Please contact aXet.flows team",
          });
        } else {
          this.alertService.addAlertMessage({
            type: TypeAlertEnum.ERROR,
            summary: "An unexpected error occurred. Please try again later."
          });
        }
        throw error;
    });
  }
}
