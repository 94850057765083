import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppForRemoteDeployOptions } from '../../models/desktop/deptapp-for-remote-deploy-options.model';
import { Flag } from '../../models/common/flag.model';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';
import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '.';
import * as _ from 'lodash';

@Injectable()
export class DeptAppRemoteDeploymentOptionsPreferencesService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async existsByDeptApp(deptApp: DeptApp): Promise<Flag> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<Flag>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-remote-deployment-options-preferences/exists`)).toPromise();
  }

  async findByDeptApp(deptApp: DeptApp): Promise<DeptAppForRemoteDeployOptions> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppForRemoteDeployOptions>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-remote-deployment-options-preferences`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp`;
  }
}
