import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-external-page-layout',
  templateUrl: './external-page-layout.component.html',
  styleUrls: ['./external-page-layout.component.scss'],
})
export class NgxExternalPageLayoutComponent {

  @Input()
  centerLayout: boolean = false;
  currentYear: number = new Date().getFullYear();
}
