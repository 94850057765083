import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DeptAppsFlowsNode } from '../../models/app/deptapps-flows-node.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-flows-node`;

@Injectable()
export class DeptAppsFlowsNodeService {

  constructor(private http: HttpClient) { }

  async findAllDeptAppFlowNodeVersionModule(): Promise<DeptAppsFlowsNode[]> {
    return await this.http.get<DeptAppsFlowsNode[]>(encodeURI(RESOURCE_BASE_URI)).toPromise();
  }
}
