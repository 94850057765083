import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss'],
})
export class NgxCarouselItemComponent {
  @Input()
  model: any;
  @Input()
  faIcon: any;
  @Input()
  titleField: string = 'name';
  @Input()
  descriptionField: string = 'description';
  @Input()
  disabled: boolean = false;
  @Output()
  select = new EventEmitter<any>();

  onClick() {
    this.select.emit(this.model);
  }
}
