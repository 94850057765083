import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { AlertMessage, TypeAlertEnum } from './alert.model';

const TIME_FOR_HIDE_MESSAGE: number = 10000;

@Component({
  selector: 'ngx-alerts',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class NgxAlertsComponent {
  messages: AlertMessage[];

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.messages = [];
    //Events router
    this.router.events.subscribe((event: any) => {
      if (event.urlAfterRedirects) {
        this.messages = [];
      }
    });
    //Events to add new alert
    this.alertService.alertObservable.subscribe(alertMessage => {
      if (
        this.messages.filter(
          oldAlert =>
            oldAlert.type === alertMessage.type &&
            oldAlert.summary === alertMessage.summary &&
            oldAlert.details === alertMessage.details,
        ).length <= 0
      ) {
        this.messages.push(alertMessage);
        if (alertMessage.type !== TypeAlertEnum.ERROR) {
          setTimeout(() => {
            if (this.messages && this.messages.length > 0) {
              this.messages = [];
            }
          }, TIME_FOR_HIDE_MESSAGE);
        }
      }
    });
  }

  removeAlertMessage(alert: AlertMessage) {
    this.messages = this.messages.filter(current => current !== alert);
  }
}
