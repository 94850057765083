import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AppcenterDesktopRelease } from "../../models/app/appcenter-desktop-releases.model";

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/appcenter`;

@Injectable()
export class AppcenterDesktopReleasesService {

  constructor(private http: HttpClient) { }

  async getDeptappsDesktopReleases(platform: string): Promise<AppcenterDesktopRelease> {
    const formData = new FormData();
    formData.append('platform', platform);
    return await this.http.post<AppcenterDesktopRelease>(`${RESOURCE_BASE_URI}/download`, formData).toPromise();
  }
}
