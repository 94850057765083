const DEFAULT_ITEM_PER_PAGE_SHARED_SUBFLOW: number = 9;
const DEFAULT_TABLE_ITEM_PER_PAGE: number = 15;

export class LimitResults {
  constructor(){
  }

  getDefaultItemPerPageSharedSubflows(){
    return DEFAULT_ITEM_PER_PAGE_SHARED_SUBFLOW;
  }

  getDefautlTableItemPerPage() {
    return DEFAULT_TABLE_ITEM_PER_PAGE;
  }
}
