import { Injectable } from '@angular/core';
import { DatabaseSpec, TableSpec, ColumnSpec } from '../../../models/meta-information-aggregator/red-asset-integration/models/database-spec.model';
import { MDOneProject } from '../../../models/meta-information-aggregator/mdoneproject-metamodel/mdone-project.model';
import { EntityAttribute } from '../../../models/meta-information-aggregator/mdoneproject-metamodel/architecture-attributes.model';
import { Entity } from '../../../models/meta-information-aggregator/mdoneproject-metamodel/architecture-vos.model';

import * as _ from 'lodash';
import { UtilsService } from '../../../utils/utils.service';

@Injectable()
export class DatabaseSpecToMDoneProjectModelToModelService {

  constructor(
    private utilsService: UtilsService,
  ) { }

  parseDatabaseSpec(databaseSpec: DatabaseSpec, mdoneProject?: MDOneProject): MDOneProject {
    if (!mdoneProject) {
      mdoneProject = {};
    }
    const moreThanOneSchema: boolean = databaseSpec.schema.length > 1;
    if (!mdoneProject.entities) {
      mdoneProject.entities = [];
    }
    if (!mdoneProject.relationships) {
      mdoneProject.relationships = [];
    }
    databaseSpec.schema.forEach(schema => {
      schema.table.forEach(table => {
        if (this.isManyToManyRelationship(table)) {
          //ManyToMany Relationship
          mdoneProject.relationships.push({
            from: {
              name: this.getEntityName(
                table.foreignKey[0].destTable,
                table.foreignKey[0].destScheme && table.foreignKey[0].destScheme.length ? table.foreignKey[0].destScheme : schema.name,
                moreThanOneSchema,
              ),
              injectedfield: this.getColumnName(table.foreignKey[0].value[0].source),
            },
            to: {
              name: this.getEntityName(
                table.foreignKey[1].destTable,
                table.foreignKey[1].destScheme && table.foreignKey[1].destScheme.length ? table.foreignKey[1].destScheme : schema.name,
                moreThanOneSchema,
              ),
              injectedfield: this.getColumnName(table.foreignKey[1].value[0].source),
            },
            cardinality: 'many-to-many',
          });
        } else {
          const entityName = this.getEntityName(
            table.name,
            schema.name,
            moreThanOneSchema,
          );
          const newEntity: Entity = {
            unique_name: entityName,
            name: entityName,
            tableName: this.getFinalTableName(table.name, schema.name, moreThanOneSchema),
            body: [],
          };
          /* Add columns PK */
          if (table.primaryKey && table.primaryKey.value && table.primaryKey.value.length > 0) {
            this.addColumnIntoBodyEntity(newEntity, table.primaryKey.value.map(valuePk => {
              return {
                name: this.getColumnName(valuePk.name),
                type: table.column.find(column => column.name === valuePk.name).type,
              };
            }));
            //Insert de PK
            newEntity.primaryKey = {
              name: table.primaryKey.name,
              columnNames: table.primaryKey.value.map(valuePk => this.getColumnName(valuePk.name)),
              compositeKey: table.primaryKey.value.length > 1,
            }
          }

          //Unique keys
          if (table.uniqueKey && table.uniqueKey.length > 0) {
            if (!newEntity.uniqueKeys) {
              newEntity.uniqueKeys = [];
            }
            table.uniqueKey.filter(uniqueKey => uniqueKey.value && uniqueKey.value.length > 0).forEach(uniqueKey => {
              //Insert columns uniqueKey in body!!
              this.
                addColumnIntoBodyEntity(newEntity, uniqueKey.value.map(valueUq => {
                  return {
                    name: this.getColumnName(valueUq.name),
                    type: table.column.find(column => column.name === valueUq.name).type,
                  };
                }));
              newEntity.uniqueKeys.push({
                name: uniqueKey.name,
                columnNames: uniqueKey.value.map(valueUq => this.getColumnName(valueUq.name)),
                compositeKey: uniqueKey.value.length > 1,
              });
            });
          }

          const columnToProccess = (table.column || []).filter(
            column =>
              // Not is primary key column
              (!table.primaryKey || !table.primaryKey.value || !table.primaryKey.value.length ||
                !table.primaryKey.value.map(value => value.name).includes(column.name)) &&

              // Not is unique key column
              (!table.uniqueKey || !table.uniqueKey.length || !table.uniqueKey.some(
                uniqueKey => uniqueKey.value && uniqueKey.value.length &&
                  uniqueKey.value.map(value => value.name).includes(column.name))) &&

              // Not is foreign key column
              !this.isForeignKeyColumnInTable(table, column),
          );

          this.
            addColumnIntoBodyEntity(newEntity, columnToProccess.map(column => {
              return {
                name: this.getColumnName(column.name),
                type: column.type,
              };
            }));
          mdoneProject.entities.push(newEntity);

          /* Relationships */

          if (table.foreignKey && table.foreignKey.length > 0) {
            mdoneProject.relationships.push(
              ...table.foreignKey.map(foreignKey => {
                //Calculate cardinality
                let cardinality = 'many-to-one';
                if (
                  table.uniqueKey &&
                  table.uniqueKey.length > 0 &&
                  table.uniqueKey.some(
                    uniqueKey =>
                      uniqueKey.value.length == foreignKey.value.length &&
                      foreignKey.value
                        .map(value => value.source)
                        .every(source => uniqueKey.value.map(value => value.name).includes(source)),
                  )
                ) {
                  cardinality = 'one-to-one';
                }

                return {
                  from: {
                    name: this.getEntityName(
                      table.name,
                      schema.name,
                      moreThanOneSchema,
                    ),
                    injectedfield: this.getColumnName(foreignKey.value[0].source),
                    exraInjectedField: foreignKey.value.length > 1 ? foreignKey.value.slice(1, foreignKey.value.length).map(value => this.getColumnName(value.source)) : [],
                  },
                  to: {
                    name: this.getEntityName(
                      foreignKey.destTable,
                      foreignKey.destScheme && foreignKey.destScheme.length ? foreignKey.destScheme : schema.name,
                      moreThanOneSchema,
                    ),
                  },
                  cardinality: cardinality,
                };
              }),
            );
          }
        }
      });
    });
    return mdoneProject;
  }

   getEntityName(tableName: string, schemaName: string, moreThanOneSchema: boolean): string {
    const tableNameSplit: string[] = tableName.split('_');
    let entityName: string = tableNameSplit
      .map(partOfName => this.utilsService.capitalizeFirstLetter(partOfName.toLowerCase()))
      .join('');
    if (moreThanOneSchema && schemaName && schemaName.length > 0) {
      entityName = this.utilsService.capitalizeFirstLetter(_.camelCase(schemaName)) + entityName;
    }
    return entityName;
  }

   getFinalTableName(tableName: string, schemaName: string, moreThanOneSchema: boolean): string {
    if (moreThanOneSchema && schemaName && schemaName.length > 0) {
      tableName = schemaName + '.' + tableName;
    }
    return tableName;
  }

   getColumnName(columnName: string): string {
    const columnNameSplit: string[] = columnName.split('_');
    let finalColumnName: string = columnNameSplit[0].toLowerCase();
    if (columnNameSplit.length > 1) {
      finalColumnName += columnNameSplit
        .slice(1)
        .map(part => this.utilsService.capitalizeFirstLetter(part.toLowerCase()))
        .join('');
    }
    return finalColumnName;
  }

  private addColumnIntoBodyEntity(entity: Entity, candidateEntityAtributtes: EntityAttribute[]): void {
    if (entity && candidateEntityAtributtes && candidateEntityAtributtes.length) {
      entity.body = entity.body || [];
      entity.body.push(..._.uniqBy(candidateEntityAtributtes, 'name').filter(
        newAtributte => !entity.body.some(attrOld => attrOld.name === newAtributte.name)));
    }
  }

  private isManyToManyRelationship(table: TableSpec): boolean {
    return (
      //XXX: Delete condition primary-key because exists incosistent constraint models around the world
      table.column.length == 2 && table.foreignKey.length == 2
    );
  }

  private isForeignKeyColumnInTable(table: TableSpec, column: ColumnSpec) {
    if (!table.foreignKey || table.foreignKey.length <= 0) {
      return false;
    } else {
      return (
        table.foreignKey.filter(
          foreignKey => foreignKey.value.map(valueFk => valueFk.source).indexOf(column.name) >= 0,
        ).length > 0
      );
    }
  }
}