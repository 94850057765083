import { Component, Input } from '@angular/core';

import * as _ from 'lodash';
import { LegendMapOptions } from './legend-map-options.model';

@Component({
  selector: 'ngx-legend-map',
  templateUrl: './legend-map.component.html',
  styleUrls: ['./legend-map.component.scss'],
})
export class NgxLegendMapComponent {

  @Input()
  options: LegendMapOptions;

}
