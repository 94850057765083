import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Project } from '../../models/mdone/project.model';
import { PaginationResult } from '../../models/mdone/PaginationResult.model';
import { Observable, ReplaySubject } from 'rxjs';
import { UtilsService } from '../../utils/utils.service';
import { ProjectDTO } from '../../models/mdone/projectDTO.model';

import * as _ from "lodash";
import { User } from '../../models/app/user.model';
import { axetCoreProject } from '../../../interfaces/axetCoreProject.interface';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/project`;
const RESOURCE_BASE_URI_TEMP = `${environment.apiUrlBase}`; //For testing and temporal purpose


@Injectable()
export class ProjectRemoteService {

  private projectsMeCache: Project[];
  private projectsMeCacheSubject: ReplaySubject<Project[]>;

  private projectsAlsoIncludeNotProjectsMeCache: Project[];
  private projectsAlsoIncludeNotProjectsMeCacheSubject: ReplaySubject<Project[]>;

  projectsMeCacheObservable: Observable<Project[]>;
  projectsAlsoIncludeNotProjectsMeCacheObservable: Observable<Project[]>;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService) {
   this.projectsMeCache = [];
    this.projectsMeCacheSubject = new ReplaySubject<Project[]>();
    this.projectsMeCacheObservable = this.projectsMeCacheSubject.asObservable();

    this.projectsAlsoIncludeNotProjectsMeCache = [];
    this.projectsAlsoIncludeNotProjectsMeCacheSubject = new ReplaySubject<Project[]>();
    this.projectsAlsoIncludeNotProjectsMeCacheObservable = this.projectsAlsoIncludeNotProjectsMeCacheSubject.asObservable();
  }


  async findAllAlsoIncludeNotProjectsMe(): Promise<PaginationResult<Project>> {
    return await this.http.get<PaginationResult<Project>>(encodeURI(`${RESOURCE_BASE_URI}/all`)).toPromise();
  }

  async findProjectsMeActivated(): Promise<Project[]> {
    return await this.findProjectsBySearchFilter(undefined, undefined, true);
  }

  findProyectsToNewUsers(): Observable<Project[]> {
    return this.http.post<Project[]>(
      `${RESOURCE_BASE_URI_TEMP}/getClientProject`,
      {}
    );

  }

  async findProjectsBySearchFilter(searchText?: string, idClient?: number, excludeDischarged?: boolean): Promise<Project[]> {
    excludeDischarged = !!excludeDischarged;
    let params = new HttpParams().set('excludeDischarged', excludeDischarged.toString());
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    if (idClient && idClient > 0) {
      params = params.append('idClient', idClient.toString());
    }
    return await this.http.get<Project[]>(encodeURI(RESOURCE_BASE_URI), { params }).toPromise();
  }

  async findProjectsMeByIdClient(idClient: number): Promise<Project[]> {
    return await this.http.get<Project[]>(encodeURI(`${RESOURCE_BASE_URI}/me`),
      { params: new HttpParams().set('idClient', idClient.toString()) }).toPromise();
  }

  async refreshProjectsMeCache() {
    this.projectsMeCache = await this.findProjectsMeActivated();
    this.applyCalculateLabels(this.projectsMeCache);
    this.projectsMeCacheSubject.next(this.projectsMeCache);
  }

  async refreshProjectsAlsoIncludeNotProjectsMeCache() {
    this.projectsAlsoIncludeNotProjectsMeCache = (await this.findAllAlsoIncludeNotProjectsMe()).result;
    this.applyCalculateLabels(this.projectsAlsoIncludeNotProjectsMeCache);
    this.projectsAlsoIncludeNotProjectsMeCacheSubject.next(this.projectsAlsoIncludeNotProjectsMeCache);
  }

  private applyCalculateLabels(projects: Project[]): Project[] {
    for (const project of projects) {
      project.calculateLabel = this.utilsService.calculateVisibleLabelProject(project);
    }
    return projects;
  }

  // Migración de users de MDOne. Tarea ACOE-2929
  getProjects(): Observable<PaginationResult<ProjectDTO>> {
    return this.http.get<PaginationResult<ProjectDTO>>(`${RESOURCE_BASE_URI}/all`);
  }

  getProject(id: number): Observable<ProjectDTO> {
    return this.http.get<ProjectDTO>(`${RESOURCE_BASE_URI}/${id}`);
  }

  async createProject(project: ProjectDTO): Promise<HttpResponse<ProjectDTO>> {
    return await this.http.post<ProjectDTO>(RESOURCE_BASE_URI, project, {observe : 'response'}).toPromise();
  }

  async updateProject(project: ProjectDTO): Promise<HttpResponse<ProjectDTO>> {
    return await this.http.put<ProjectDTO>(RESOURCE_BASE_URI, project, {observe : 'response'}).toPromise();
  }

  async deleteProject(id: number): Promise<HttpResponse<void>> {
    return await this.http.delete<void>(`${RESOURCE_BASE_URI}/${id}`, {observe : 'response'}).toPromise();
  }

  async findProjectsDTOBySearchFilter(
    searchText: string,
    distinctMe: boolean,
    discharged: boolean,
  ): Promise<ProjectDTO[]> {
    let params = new HttpParams();
    if (!_.isEmpty(searchText)) {
      params = params.append("q", searchText);
    }
    if (!_.isNil(discharged)) {
      params = params.append("discharged", discharged.toString());
    }

    return await this.http.get<ProjectDTO[]>(encodeURI(`${RESOURCE_BASE_URI}/newFind`), { params }).toPromise();
  }

  getMembersByProyectId(project : ProjectDTO){
    return this.http.get<PaginationResult<User>>(encodeURI(`${RESOURCE_BASE_URI}/${project.id}/members`));
  }
  
  updateMembersByProyectId(project: ProjectDTO, members: any){
    return this.http.post<PaginationResult<User>>(encodeURI(`${RESOURCE_BASE_URI}/${project.id}/members`),members);
  }

  getProjectsCoreUser(){
    return this.http.post<axetCoreProject[]>(encodeURI(`${environment.apiUrlBase}/core/getUserProject`), `${localStorage.getItem('jwt_value')}`)
  }
}
