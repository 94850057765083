import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils';
import { UserClaims } from '../../../@core/models/mdone/user-claims.model';
import { LocalStorageService } from '../../../@core/utils/local-storage.service';
import { JwtUtilsService } from '../../../@core/utils/jwt-utils.service';
import { filter, map } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { TypeAlertEnum } from '../alert/alert.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '../../../@core/utils/session-storage.service';
import { UtilsService } from '../../../@core/utils/utils.service';
import { DeptAppsVisualStudioAppCenterService } from '../../../@core/services/desktop/deptapps-visual-studio-app-center.service';
import { AppcenterDesktopReleasesService } from '../../../@core/services/app/app-center-desktop-releases.service';
import { AppInternalConfig } from '../../../@core/models/app/app-internal-config.model';
import { AppInternalConfigService } from '../../../@core/services/app/app-internal-config.service';
import { ProjectRemoteService } from '../../../@core/services/mdone/project-remote.service';
import { axetCoreProject } from '../../../interfaces/axetCoreProject.interface';


const USER_AVATAR_CONTEXT_MENU: string = 'user-avatar-context-menu';

const LOGOUT_MENU_ITEM: string = 'Logout';
const DOWNLOAD_DEPTAPPS_DESKTOP: string = 'Download aXet.flows Desktop';
const DOWNLOAD_DEPTAPPS_INSTALLER: string = 'Download aXet.flows Installer';
const SELECT_PROJECT_MENU_ITEM: string = 'Select project';

const PRODUCTION_ENVIRONMENT = 'prod';
const PRE_PRODUCTION_ENVIRONMENT = 'preprod';
const UAT_ENVIRONMENT = 'dev';
const DEV_ENVIRONMENT = 'develop';
const LOCAL_ENVIRONMENT = 'local';

const WINDOWS_OS_NAME: string = 'WINDOWS';
const DEBIAN_OS_NAME: string = 'DEBIAN';
const MAC_OS_NAME: string = 'MACOS';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  userMenu = [{ title: DOWNLOAD_DEPTAPPS_DESKTOP}, { title: DOWNLOAD_DEPTAPPS_INSTALLER }/*, { title: LOGOUT_MENU_ITEM }*/];
  user: UserClaims;

  productVersion: string;
  projectsCore : axetCoreProject[] = [];
  contProjectRequests : number = 0;

  @ViewChild('downloadDeptAppsDesktopModal')
  downloadDeptAppsDesktopModal: TemplateRef<any>;

  internalConfig: AppInternalConfig = undefined;
  private modalOpenRef: NgbModalRef;

  constructor(
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private jwtUtilsService: JwtUtilsService,
    private utilsService: UtilsService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private appcenterDesktopReleaseService: AppcenterDesktopReleasesService,
    private modalService: NgbModal,
    private deptappsVisualStudioAppCenterService: DeptAppsVisualStudioAppCenterService,
    private appInternalConfigService: AppInternalConfigService,
    private projectRemoteService : ProjectRemoteService) {
  }

  ngOnInit() {
    // if (!this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue())) {
    //   this.userMenu.unshift({ title: SELECT_PROJECT_MENU_ITEM });
    // }

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === USER_AVATAR_CONTEXT_MENU),
        map(({ item: { title } }) => title),
      )
      .subscribe(async title => {
        switch (title) {
          // case SELECT_PROJECT_MENU_ITEM:
          //   this.router.navigate(['select-project']);
          //   break;
          // case LOGOUT_MENU_ITEM:
          //   this.router.navigate(['logout']);
          //   break;
          case DOWNLOAD_DEPTAPPS_DESKTOP:
            this.openDownloadDeptAppsDesktopModal();
            break;
          case DOWNLOAD_DEPTAPPS_INSTALLER:
            this.downloadDeptAppsInstaller();
            break;
        }
      });

    if (this.localStorageService.hasJwtValue()) {
      this.user = this.jwtUtilsService.getUserClaims(this.localStorageService.getJwtValue());
    } else {
      this.user = new UserClaims();
      this.user.name = 'Anonymous';
    }

    this.sessionStorageService.getAppInternalConfig().then(internalConfig => {
      this.productVersion = internalConfig.version;
    });

    this.appInternalConfigService.$changeStateInternalConfig.subscribe(internalConfig => {
      this.internalConfig = internalConfig;
    });
  }

  obtainCommit(info: string): string {
    return info.split('|')[2];
  }

  isAnonymousUser(): boolean {
    return !this.user || !this.user.sub
  }

  getSelectedProjectFullName(): string {
    if (!this.isAnonymousUser()) {
      if (this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue())) {
        return '(as an Administrator)';
      }
      else if (this.jwtUtilsService.isUserAuditor(this.localStorageService.getJwtValue())) {
        return '(as an Auditor)';
      }
       else {
        if (this.contProjectRequests === 0) {
          this.contProjectRequests++;
          this.projectRemoteService.getProjectsCoreUser().subscribe({
            next : (projects: axetCoreProject[]) => {
              this.projectsCore = projects;
            },
            error : (error) => {
              console.error('Error getting projects:', error);
            }
          })
        }

        if (this.projectsCore.length >= 1)
          return this.projectsCore[0].name;
      }
    }
    return;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  openConfluenceDocumentationDeptApps() {
    this.utilsService.openNewBrowserTab('https://umane.emeal.nttdata.com/confluence/display/EVRCNT/DEPTAPPS');
  }

  downloadDeptAppsDesktopWindowsVersion() {
    this.internalDownloadDeptAppsDesktop(WINDOWS_OS_NAME);
  }

  downloadDeptAppsDesktopDebianVersion() {
    this.internalDownloadDeptAppsDesktop(DEBIAN_OS_NAME);
  }

  downloadDeptAppsDesktopMacOSXVersion() {
    this.internalDownloadDeptAppsDesktop(MAC_OS_NAME);
  }

  private internalDownloadDeptAppsInstaller() {
    this.deptappsVisualStudioAppCenterService.getLatestVersionOfDeptappInstallerFromAppCenter().subscribe((data) => {
      this.utilsService.openNewBrowserTab(data.download_url);
    });
  }


  closeDownloadDeptAppsDesktopModal() {
    if (this.modalOpenRef) {
      this.modalOpenRef.close();
      this.modalOpenRef = undefined;
    }
  }

  private openDownloadDeptAppsDesktopModal() {
    this.modalOpenRef = this.modalService.open(this.downloadDeptAppsDesktopModal, {
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
    });
  }

  private downloadDeptAppsInstaller() {
    this.internalDownloadDeptAppsInstaller();
  }

  private internalDownloadDeptAppsDesktop(platform: string) {
    const self = this;
    self.getRuntimeEnvironment((runtimeEnv) => {
      self.appcenterDesktopReleaseService.getDeptappsDesktopReleases(platform).then(result => {
        self.utilsService.openNewBrowserTab(result.download_url);
      }).catch(e => {
        console.log(e)
      })
    });
  }

  private async getRuntimeEnvironment(cbSuccess: (env: string) => void) {
    const internalConfig = await this.sessionStorageService.getAppInternalConfig();
    const activeProfiles = (internalConfig.activeProfiles || []);
    if (!activeProfiles || !activeProfiles.length) {
      this.alertService.addAlertMessage({
        type: TypeAlertEnum.WARNING,
        summary: `Can't download because the runtime environment is unknown!`,
        details: 'Please, contact with Flows Team support'
      });
    } else if (activeProfiles.includes(PRODUCTION_ENVIRONMENT)) {
      cbSuccess(PRODUCTION_ENVIRONMENT);
    }
    else if (activeProfiles.includes(PRE_PRODUCTION_ENVIRONMENT)) {
      cbSuccess(PRE_PRODUCTION_ENVIRONMENT);
    }
    else if (activeProfiles.includes(UAT_ENVIRONMENT)) {
      cbSuccess(UAT_ENVIRONMENT);
    } else if (activeProfiles.includes(DEV_ENVIRONMENT)) {
      cbSuccess(DEV_ENVIRONMENT);
    } else if (activeProfiles.some(profile => profile.startsWith(LOCAL_ENVIRONMENT))) {
      cbSuccess(LOCAL_ENVIRONMENT);
    } else {
      this.alertService.addAlertMessage({
        type: TypeAlertEnum.WARNING,
        summary: `Not exists DEPTAPPS Desktop for this runtime: [${activeProfiles.join(', ')}]!`,
        details: 'Please, contact with Flows Team support'
      });
    }
  }
}
