import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { DockerRegistryImagePushedTagDetails, DockerRegistryImageTagsDetails } from "../../models/app/docker-registry-image-tags-details.model";

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-docker-registry`;

@Injectable()
export class DeptAppsWebDockerRegistryManagementService {

  constructor(private http: HttpClient) {}

  async getDockerRegistryImageTags(): Promise<DockerRegistryImageTagsDetails[]> {
    return await this.http.get<DockerRegistryImageTagsDetails[]>(encodeURI(`${RESOURCE_BASE_URI}/image`)).toPromise();
  }

  async removeDockerRegistryImageTag(currentRepository: DockerRegistryImageTagsDetails, currentTag: DockerRegistryImagePushedTagDetails): Promise<any> {
    return await this.http.delete(encodeURI(`${RESOURCE_BASE_URI}/image/${currentRepository.name}/${currentTag.tagName}`)).toPromise();
  }
}
