import { Injectable } from '@angular/core';
import { MDOneProject } from '../../../models/meta-information-aggregator/mdoneproject-metamodel/mdone-project.model';
import {
  ContractRemoteOperationParameter,
} from '../../../models/meta-information-aggregator/mdoneproject-metamodel/architecture-operations-parameters.model';
import {
  ContractRemoteOperation,
} from '../../../models/meta-information-aggregator/mdoneproject-metamodel/architecture-operations.model';
import {
  ContractRemoteController,
} from '../../../models/meta-information-aggregator/mdoneproject-metamodel/architecture-remote-controller.model';
import { TreeNodesUtilService } from '../utils/tree-nodes-utils.service';
import * as _ from 'lodash';
import { MDOneProjectTreeNode, MDOneProjectTreeNodeType } from '../../../models/meta-information-aggregator/enums/mdone-project-tree-node.model';

@Injectable()
export class SwaggerSpecToMDoneProjectModelToModelService {

  constructor(
    private treeNodesUtilService: TreeNodesUtilService,
  ) { }

  parseSwaggerSpec(swaggerProject: any, mdoneProject?: MDOneProject): MDOneProject {
    if (!mdoneProject) {
      mdoneProject = {};
      mdoneProject.controllers = [];
      mdoneProject.contractModels = [];
    }
    if (swaggerProject.paths) {
      const plainListOperations: Array<
        any
      > = this.getPlainListOperations(swaggerProject, false);
      const plainListOperationWithTags = plainListOperations.filter(operation => operation.tags && operation.tags.length);
      if (plainListOperationWithTags && plainListOperationWithTags.length) {
        const uniqueTags = _.uniq(_.flatten(plainListOperationWithTags.map(operation => operation.tags)));
        mdoneProject.controllers.push(
          ...uniqueTags.map(tag =>
            this.parseController(
              _.upperFirst(_.camelCase(tag)), plainListOperationWithTags.filter(operation => operation.tags.includes(tag)
              ),
            ),
          ),
        );
      }

      /* Operation without tags  */
      const plainListOperationsWithoutTags = plainListOperations.filter(operation => !operation.tags || !operation.tags.length);
      if (plainListOperationsWithoutTags && plainListOperationsWithoutTags.length) {
        mdoneProject.controllers.push(this.parseController('ResourceController', plainListOperationsWithoutTags));
      }
    }

    if (swaggerProject.definitions) {
      mdoneProject.contractModels.push(
        ..._.uniqBy(
          Object.keys(swaggerProject.definitions).map(definitionKey => {
            const currentDefinition = swaggerProject.definitions[definitionKey];
            const newContractModel = {
              unique_name: '',
              name: _.upperFirst(_.camelCase(definitionKey)),
              componentsUsed: [],
              controllersUsed: [],
              contractModelsUsed: [],
              businessServicesUsed: [],
              businessEntitiesUsed: [],
              repositoriesUsed: [],
              entitiesUsed: [],
              codedoc: '',
              operations: [],
              body: [],
              extends: [],
            };

            let bodyProperties;
            if (currentDefinition.type === 'array' && !!currentDefinition.items && !!currentDefinition.items.properties) {
              bodyProperties = currentDefinition.items.properties;
            } else if (!!currentDefinition.properties) {
              bodyProperties = currentDefinition.properties;
            }
            if (!!bodyProperties) {
              newContractModel.body.push(...this.parseAttributesContractModel(bodyProperties));
            }
            return newContractModel;  
          }),
          elem => elem.name,
        ),
      );
    }

    // Vamos a comprobar si existe algun objeto que apunte a Object, en ese caso, lo vamos a generar.
    const ObjectFoundException = 'ObjectFoundException';

    try {
      if (mdoneProject.controllers) {
        mdoneProject.controllers.forEach(controller => {
          if (controller.operations) {
            controller.operations.forEach(operation => {
              if (
                (operation.returnType &&
                  operation.returnType == 'GenericType') ||
                (operation.params &&
                  operation.params.some(param => param.type == 'GenericType'))
              ) {
                throw ObjectFoundException;
              }
            });
          }
        });
      }
      if (mdoneProject.contractModels) {
        mdoneProject.contractModels.forEach(contractModel => {
          if (contractModel.body) {
            contractModel.body.forEach(field => {
              if (field.type && field.type == 'GenericType') {
                throw ObjectFoundException;
              }
            });
          }
        });
      }
    } catch (e) {
      if (e == ObjectFoundException) {
        mdoneProject.contractModels.push({
          unique_name: '',
          name: 'GenericType',
          componentsUsed: [],
          controllersUsed: [],
          contractModelsUsed: [],
          businessServicesUsed: [],
          businessEntitiesUsed: [],
          repositoriesUsed: [],
          entitiesUsed: [],
          codedoc: '',
          operations: [],
          body: [],
          extends: [],
        });
      }
    }

    return mdoneProject;
  }

  private parseController(
    name: string,
    swaggerOperations: Array<any>,
  ): ContractRemoteController {
    const newController: ContractRemoteController = {
      unique_name: '',
      name: name,
      componentsUsed: [],
      controllersUsed: [],
      contractModelsUsed: [],
      businessServicesUsed: [],
      businessEntitiesUsed: [],
      repositoriesUsed: [],
      entitiesUsed: [],
      codedoc: '',
      prefixPath: '',
      operations:
        swaggerOperations && swaggerOperations.length > 0
          ? swaggerOperations.map(operation => this.parseOperation(operation))
          : [],
    };

    if (newController.operations.length > 0) {
      const firstPath: string = newController.operations[0].path;
      const splitFirstPath: string[] = firstPath.substring(1).split('/');
      let partialSplit: string;
      let fillCondition = true;
      for (let i = 0; i < splitFirstPath.length && fillCondition; i++) {
        partialSplit = splitFirstPath[i];
        if (
          (fillCondition =
            !partialSplit.startsWith('{') &&
            !partialSplit.endsWith('}') &&
            newController.operations.every(operation => {
              if (operation.path) {
                const splitPathOperation: string[] = operation.path
                  .substring(1)
                  .split('/');
                if (i < splitPathOperation.length) {
                  return splitPathOperation[i] === partialSplit;
                }
              }
              return false;
            }))
        ) {
          newController.prefixPath += '/' + partialSplit;
        }
      }
      if (newController.prefixPath.length > 0) {
        newController.operations.forEach(operation => {
          operation.path = operation.path.split(newController.prefixPath)[1];
          if (operation.path === '/') {
            operation.path = undefined;
          }
        });
      }
    }
    return newController;
  }

  private getTypeSwaggerAttributeDefinition(attr: any): string {
    let result = 'GenericType';
    if (attr.type) {
      let isDateOrDateTimeType: boolean = false;
      let isTimeType = false;
      if (attr.type === 'string' &&
        ((isDateOrDateTimeType = attr.format === 'date' || attr.format === 'date-time') || (isTimeType = attr.format == 'time'))) {
        if (isDateOrDateTimeType) {
          result = 'LocalDate';
        }
        else if (isTimeType) {
          result = 'LocalTime';
        }
      } else if (attr.type === 'array' && attr.items) {
        if (attr.items['$ref']) {
          const splitRef: Array<string> = attr.items['$ref'].split('/');
          result = _.upperFirst(_.camelCase(splitRef[splitRef.length - 1]));
        } else {
          result = _.upperFirst(_.camelCase(attr.items.type));
        }
        result += '[]';
      } else {
        result = _.upperFirst(_.camelCase(attr.type));
      }
    } else if (attr['$ref']) {
      const splitRef: Array<string> = attr['$ref'].split('/');
      result = _.upperFirst(_.camelCase(splitRef[splitRef.length - 1]));
    }
    result = this.normalizeType(result);
    return result;
  }

  private parseOperation(operation: any): ContractRemoteOperation {
    return {
      signature: operation.operationId || _.camelCase(operation.summary) || _.camelCase(operation.description),
      type: this.getTypeContractRemoteOperationByHttpVerb(operation.verb),
      path: operation.url.startsWith('/') ? operation.url : '/' + operation.url,
      params: operation.parameters
        ? operation.parameters.map(p => this.parseParameterOperation(p))
        : [],
      returnType: this.getTypeSwaggerParamOrReturnType(
        operation.returnType,
        true,
      ),
      codedoc: ''
    };
  }

  private parseParameterOperation(
    param: any,
  ): ContractRemoteOperationParameter {
    return {
      name: this.dateToLocalDateOrLocalTime(_.camelCase(param.name)),
      type: this.getTypeSwaggerParamOrReturnType(param, false),
      required: true,
      codedoc: '',
      remoteType: this.getRemoteTypeSwaggerParam(param.in),
    };
  }

  private getTypeSwaggerParamOrReturnType(
    paramOrReturnType: any,
    isReturnType: boolean,
  ): string {
    let result: string = isReturnType ? undefined : 'GenericType';
    if (paramOrReturnType) {
      if (paramOrReturnType.type) {
        if (paramOrReturnType.type === 'array') {
          if (paramOrReturnType.items) {
            if (paramOrReturnType.items.type === 'date' || paramOrReturnType.items.format === 'date-time') {
              result = 'LocalDate';
            } else if (paramOrReturnType.items.type === 'time' || paramOrReturnType.items.format === 'time') {
              result = 'LocalTime';
            } else if (paramOrReturnType.items.type) {
              result = _.upperFirst(_.camelCase(paramOrReturnType.items.type));
            }
          }
          if (!result) {
            result = 'GenericType';
          }
          result += '[]';
        } else {
          if (paramOrReturnType.format === 'date' || paramOrReturnType.format === 'date-time') {
            result = 'LocalDate';
          } else if (paramOrReturnType.format === 'time' || paramOrReturnType.format === 'time') {
            result = 'LocalTime';
          } else {
            result = _.upperFirst(_.camelCase(paramOrReturnType.type));
          }
        }
      } else if (paramOrReturnType.schema) {
        if (paramOrReturnType.schema.type) {
          if (paramOrReturnType.schema.type == 'array') {
            if (
              paramOrReturnType.schema.items &&
              paramOrReturnType.schema.items['$ref']
            ) {
              const refTypeSplit: Array<string> = paramOrReturnType.schema.items[
                '$ref'
              ].split('/');
              result = _.upperFirst(
                _.camelCase(refTypeSplit[refTypeSplit.length - 1]),
              );
            } else if (paramOrReturnType.schema.items.type) {
              if (
                paramOrReturnType.schema.items.type === 'date' ||
                paramOrReturnType.schema.items.format === 'date-time'
              ) {
                result = 'LocalDate';
              } else if (paramOrReturnType.schema.items.type === 'time' || paramOrReturnType.schema.items.format === 'time') {
                result = 'LocalTime';
              } else {
                result = _.upperFirst(
                  _.camelCase(paramOrReturnType.schema.items.type),
                );
              }
            }
            if (!result) {
              result = 'GenericType';
            }
            result += '[]';
          } else {
            if (paramOrReturnType.schema.format === 'date' || paramOrReturnType.schema.format === 'date-time') {
              result = 'LocalDate';
            } else if (paramOrReturnType.schema.format === 'time' || paramOrReturnType.schema.format === 'time') {
              result = 'LocalTime';
            } else {
              result = _.upperFirst(_.camelCase(paramOrReturnType.schema.type));
            }
          }
        } else if (paramOrReturnType.schema['$ref']) {
          const splitRefSchema: Array<string> = paramOrReturnType.schema[
            '$ref'
          ].split('/');
          result = _.upperFirst(
            _.camelCase(splitRefSchema[splitRefSchema.length - 1]),
          );
        }
      }
      result = this.normalizeType(result);
    }
    return result;
  }

  private parseAttributesContractModel(properties: any): any {
    return Object.keys(properties).map(propertyName => {
      return {
        name: _.lowerFirst(_.camelCase(propertyName)),
        type: this.getTypeSwaggerAttributeDefinition(properties[propertyName]),
        validations: [],
        codedoc: ''
      };
    });
  }

  private getRemoteTypeSwaggerParam(swaggerType: string): string {
    if (swaggerType.toLowerCase() === 'path') {
      return 'path-param';
    } else if (swaggerType.toLowerCase() === 'query') {
      return 'query-param';
    } else {
      return 'body';
    }
  }

  private getTypeContractRemoteOperationByHttpVerb(httpVerb: string) {
    if (httpVerb.toLowerCase() === 'get') {
      return 'read';
    } else if (httpVerb.toLowerCase() === 'post') {
      return 'create';
    } else if (httpVerb.toLowerCase() === 'put') {
      return 'update';
    } else if (httpVerb.toLowerCase() === 'delete') {
      return 'delete';
    } else {
      return 'generic';
    }
  }

  private dateToLocalDateOrLocalTime(type: string): string {
    return type.toLowerCase() === 'date' || type.toLowerCase() === 'date-time'
      ? 'LocalDate' : (type.toLowerCase() === 'time' ? 'LocalTime' : type);
  }

  /* Swagger Common Utils operations */
  private getPlainListOperations(swaggerProject: any, filterSwagger: boolean): Array<any> {
    return [].concat.apply(
      [],
      Object.keys(swaggerProject.paths).map(pathkey => {
        const pathElement = swaggerProject.paths[pathkey];
        return Object.keys(pathElement).filter(operationKey => _.isObject(pathElement[operationKey])).map(operationKey => {
          const operationElement = pathElement[operationKey];
          if (operationElement.parameters && operationElement.parameters.length > 0) {
            operationElement.parameters = operationElement.parameters.map(parameter => {
              if (parameter['$ref']) {
                const paramNameRefSplit = parameter['$ref'].split('/');
                const paramName = paramNameRefSplit[paramNameRefSplit.length - 1];
                parameter = swaggerProject.parameters[paramName];
              }
              return parameter;
            });

            const paramaterNamesUsed = operationElement.parameters.filter(parameter => !_.isEmpty(parameter.name)).map(parameter => parameter.name);
            for (const parameterWithoutName of operationElement.parameters.filter(parameter => _.isEmpty(parameter.name))) {
              const proposedParamName = this.calculateProposedParamNameBySwaggerType(parameterWithoutName.in, paramaterNamesUsed);
              parameterWithoutName.name = proposedParamName;
            }
          }

          // Remap Original Data
          if (filterSwagger) {
            operationElement.originalPath = {};
            operationElement.originalPath['element'] = _.cloneDeep(pathElement[operationKey]);
            operationElement.originalPath['operation'] = operationKey;
            operationElement.originalPath['key'] = pathkey;
          }

          let returnType: string;
          if (operationElement.responses) {
            returnType = operationElement.responses['200'] || operationElement.responses['201'];
            if (returnType) {
              delete operationElement.responses;
            }
          }

          return Object.assign(
            {
              verb: operationKey.toUpperCase(),
              url: pathkey,
              returnType: returnType,
            },
            operationElement,
          );
        });
      }),
    );
  }

  getSwaggerServiceName(swaggerSpec: any): string {
    let swaggerServiceName = '';
    if (swaggerSpec && swaggerSpec.info && swaggerSpec.info.title) {
        swaggerServiceName = swaggerSpec.info.title;
    }
    return (swaggerServiceName && swaggerServiceName.length > 0) ? swaggerServiceName : 'Swagger Definition';
  }

  parseSwaggerSpecToTree(swaggerSpec: any) {
    const parentProjectModuleSwaggerTree: MDOneProjectTreeNode = this.treeNodesUtilService.generateParentNodeSwaggerTree(
      this.getSwaggerServiceName(swaggerSpec),
        'fas fa-server',
        true,
        true,
        undefined,
    );

    const controllersSwaggerTree: MDOneProjectTreeNode = this.treeNodesUtilService.generateParentNodeSwaggerTree(
      'End Points',
      'fa fas fa-cogs',
      true,
      false,
      parentProjectModuleSwaggerTree,
    );

    const contractModelsSwaggerTree = this.treeNodesUtilService.generateParentNodeSwaggerTree(
      'Models',
      'fa fa-compress',
      true,
      false,
      parentProjectModuleSwaggerTree,
    );

    parentProjectModuleSwaggerTree.children.push(controllersSwaggerTree);
    parentProjectModuleSwaggerTree.children.push(contractModelsSwaggerTree);

    const projectExplorerNodesSwaggerTree: MDOneProjectTreeNode[] = [];
    projectExplorerNodesSwaggerTree.push(parentProjectModuleSwaggerTree);

    if (swaggerSpec.definitions) {
      const keys = Object.keys(swaggerSpec.definitions);
      for (const key of keys) {
        const element = swaggerSpec.definitions[key];
        const nodeData = {};
        nodeData['key'] = key;
        nodeData['element'] = element;
        const childNode: MDOneProjectTreeNode = this.generateNode(
          key, nodeData, contractModelsSwaggerTree, 'fas fa-file-code', MDOneProjectTreeNodeType.TYPE_CONTRACTMODELS);
        contractModelsSwaggerTree.children.push(childNode);
      }
    }

    if (swaggerSpec.paths) {
      const controllers = [];
      const plainListOperations: Array<any> = this.getPlainListOperations(swaggerSpec, true);
      const plainListOperationWithTags = plainListOperations.filter(operation => operation.tags && operation.tags.length);
      if (plainListOperationWithTags && plainListOperationWithTags.length) {
        const uniqueTags = _.uniq(_.flatten(plainListOperationWithTags.map(operation => operation.tags)));

        for (const tag of uniqueTags) {
          const controllerOperations = plainListOperationWithTags.filter(operation => operation.tags.includes(tag));
          if (controllerOperations) {
            const ctPath = this.getCtModelPath(controllerOperations);
            const ctName = _.upperFirst(_.camelCase(tag)) + ` (${ctPath})`;
            const controllerNode: MDOneProjectTreeNode = this.generateNode(
              ctName, undefined, controllersSwaggerTree, 'fas fa-file-code', MDOneProjectTreeNodeType.TYPE_CONTROLLERS);

            for (const oper of controllerOperations) {
              const operName = this.calculateOperationLabel(oper.operationId, oper, ctPath);
              const operationNode: MDOneProjectTreeNode = this.generateNode(
                operName, oper.originalPath, controllerNode, 'far fa-dot-circle', MDOneProjectTreeNodeType.TYPE_OPERATION_CONTROLLERS);
              controllerNode.children.push(operationNode);
            }
            controllers.push(controllerNode);
          }
        }
      }

      /* Operation without tags  */
      const plainListOperationsWithoutTags = plainListOperations.filter(operation => !operation.tags || !operation.tags.length);
      if (plainListOperationsWithoutTags && plainListOperationsWithoutTags.length) {
        const controllerNode: MDOneProjectTreeNode = this.generateNode(
          'ResourceController', undefined, controllersSwaggerTree, 'fas fa-file-code', MDOneProjectTreeNodeType.TYPE_CONTROLLERS);

        for (const oper of plainListOperationsWithoutTags) {
          const operationNode: MDOneProjectTreeNode = this.generateNode(
            oper.operationId, oper.originalPath, controllerNode, 'far fa-dot-circle', MDOneProjectTreeNodeType.TYPE_OPERATION_CONTROLLERS);
          controllerNode.children.push(operationNode);
        }
        controllers.push(controllerNode);
      }

      //Ordenamos el Array De Controllers!
      controllers.sort((a, b) => (a.label > b.label) ? 1 : -1);
      for (const c of controllers) {
        controllersSwaggerTree.children.push(c);
      }

    }

    return projectExplorerNodesSwaggerTree;
  }

  private calculateProposedParamNameBySwaggerType(swaggerType: string, paramaterNamesUsed: string[]): string {
    const originalProposedParamName = this.internalCalculateProposedParamNameBySwaggerType(swaggerType);

    let i = 0;
    let proposedParamName = originalProposedParamName;
    while (paramaterNamesUsed.includes(proposedParamName)) {
      proposedParamName = `${originalProposedParamName}${++i}`;
    }
    paramaterNamesUsed.push(proposedParamName);
    return proposedParamName;
  }

  private internalCalculateProposedParamNameBySwaggerType(swaggerType: string): string {
    const remoteType = _.camelCase(this.getRemoteTypeSwaggerParam(swaggerType));
    let proposedParamName = 'object';
    if (remoteType.toLowerCase().endsWith('param')) {
      proposedParamName = remoteType;
    } else {
      proposedParamName = `${remoteType}Param`;
    }
    return proposedParamName;
  }

  private normalizeType(type: string): string {
    if (type == 'Object') {
      type = 'GenericType';
    } else if (type == 'Object[]') {
      type = 'GenericType[]';
    } else if (type == 'Number') {
      type = 'Double';
    } else if (type == 'Number[]') {
      type = 'Double[]';
    }
    return type;
  }


  private calculateOperationLabel(opId: string, oper: any, ctPath: string) {
    let label = '';
    if (oper.url) {
      label = `${oper.verb} `;
      if (oper.url.startsWith(ctPath)) {
        label += oper.url.substring(ctPath.length, oper.url.length);
      } else {
        label += oper.url;
      }
    }

    return label + ' (' + opId + ')';
  }

  private getCtModelPath(controllerOperations: any[]) {
    let prefixPath = '';
    if (controllerOperations.length > 0) {
      const firstPath: string = controllerOperations[0].url;
      const splitFirstPath: string[] = firstPath.substring(1).split('/');
      let partialSplit: string = '';
      let fillCondition = true;

      for (let i = 0; i < splitFirstPath.length && fillCondition; i++) {
        partialSplit = splitFirstPath[i];
        if (
          (fillCondition =
            !partialSplit.startsWith('{') &&
            !partialSplit.endsWith('}') &&
            controllerOperations.every(operation => {
              if (operation.url) {
                const splitPathOperation: string[] = operation.url
                  .substring(1)
                  .split('/');
                if (i < splitPathOperation.length) {
                  return splitPathOperation[i] === partialSplit;
                }
              }
              return false;
            }))
        ) {
          prefixPath += '/' + partialSplit;
        }
      }
    }
    return prefixPath;
  }

  private generateNode(name: string, data: any, parent: MDOneProjectTreeNode, icon: string, nodeType: MDOneProjectTreeNodeType): MDOneProjectTreeNode {
    return this.treeNodesUtilService.generateChildNodeSwaggerTree(
      name,
      icon,
      nodeType,
      data,
      parent,
    );
  }

}
