import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-editable-url',
  templateUrl: './editable-url.component.html',
  styleUrls: ['./editable-url.component.scss']
})
export class EditableUrlComponent {
  private _url: string = '';
  private _oldInfra: boolean = false;
  private _defaultUrl: string = "";

  // Control para el primer subdominio (por la izquierda)
  firstSubdomainControl = new FormControl('');

  @Input()
  set url(value: string) {
    if (value) {
      this._url = `https://${value}`;
      this.firstSubdomainControl.setValue(this.getFirstSubdomain());
    }
  }

  @Input()
  set defaultUrl(value: string) {
    console.log(value);
    this._defaultUrl = value;
  }

  @Input()
  set oldInfra(value: boolean) {
    this._oldInfra = value;
  }

  get url(): string {
    return this._url;
  }

  @Output() urlChange = new EventEmitter<string>();


  
  ngOnInit() {

  }

  // Obtiene el primer subdominio de la URL (sin protocolo)
  getFirstSubdomain(): string {
    const subdomains = this._url.replace('https://', '').split('.');
    return subdomains[0];
  }

  // Actualiza la URL con el nuevo valor del primer subdominio
  updateUrl() {
    const subdomains = this._url.replace('https://', '').split('.');
    subdomains[0] = this.firstSubdomainControl.value || subdomains[0];
    this._url = `https://${subdomains.join('.')}`;
    this.urlChange.emit(this.firstSubdomainControl.value);  // Emite la URL actualizada
  }
}
