import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { User } from '../models/app/user.model';
import { TreeNode } from 'primeng/api';
import { Project } from '../models/mdone/project.model';
import { AbstractDeptAppsFlowsRepositoryResourceWithUserVote } from '../models/common/abstract-deptapps-flows-repository-resource-with-user-vote.model';
import { DeptAppsFlowsRepositoryResourceUserVote } from '../models/app/deptapps-flows-repository-resource-user-vote.model';
import { VersionXYZ } from '../models/common/version-xyz.model';

import { v4 as uuidv4 } from 'uuid';
import * as prettyBytes from 'pretty-bytes';
import * as _ from 'lodash';
import { DeptApp } from '../models/app/deptapp.model';
import { LocalStorageService } from './local-storage.service';
import { JwtUtilsService } from './jwt-utils.service';

const DEFAULT_CODE_MIRROR_OPTS: any = {
  theme: 'mdn-like',
  lineNumbers: true,
  lineWrapping: true,
  foldGutter: true,
  matchBrackets: true,
  gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
  scrollbarStyle: 'overlay',
};
const MINIMAL_DOCKER_IMAGE_TAG_APPLY_MAX_OLD_SPACE_SIZE_ENV_AS_STRING: string = '2.14.2';
const MINIMAL_DOCKER_IMAGE_TAG_APPLY_MAX_OLD_SPACE_SIZE_ENV: VersionXYZ = VersionXYZ.fromString(MINIMAL_DOCKER_IMAGE_TAG_APPLY_MAX_OLD_SPACE_SIZE_ENV_AS_STRING);

const MINIMAL_DOCKER_IMAGE_TAG_APPLY_FULL_OFFLINE_MODE_AS_STRING: string = '3.1.0';
const MINIMAL_DOCKER_IMAGE_TAG_APPLY_FULL_OFFLINE_MODE: VersionXYZ = VersionXYZ.fromString(MINIMAL_DOCKER_IMAGE_TAG_APPLY_FULL_OFFLINE_MODE_AS_STRING);

const MINIMAL_DOCKER_IMAGE_TAG_APPLY_VIRTUAL_SCREEN_AS_STRING: string = '4.0.0';
const MINIMAL_DOCKER_IMAGE_TAG_APPLY_VIRTUAL_SCREEN: VersionXYZ = VersionXYZ.fromString(MINIMAL_DOCKER_IMAGE_TAG_APPLY_VIRTUAL_SCREEN_AS_STRING);

const MINIMAL_DOCKER_IMAGE_TAG_APPLY_DESKTOP_CONTROL_SERVER_EMBEDDED_AS_STRING: string = '4.2.0';
const MINIMAL_DOCKER_IMAGE_TAG_APPLY_DESKTOP_CONTROL_SERVER_EMBEDDED: VersionXYZ = VersionXYZ.fromString(MINIMAL_DOCKER_IMAGE_TAG_APPLY_DESKTOP_CONTROL_SERVER_EMBEDDED_AS_STRING);

const MINIMAL_DOCKER_IMAGE_TAG_FOR_INSECURE_HTTP_PARSER_AS_STRING: string = '5.0.4';
const MINIMAL_DOCKER_IMAGE_TAG_FOR_INSECURE_HTTP_PARSER: VersionXYZ = VersionXYZ.fromString(MINIMAL_DOCKER_IMAGE_TAG_FOR_INSECURE_HTTP_PARSER_AS_STRING);

const REGEX_GET_EXTENSION_NAME_FILE: RegExp = /(?:\.([^.]+))?$/;

@Injectable()
export class UtilsService {

  private urlPatternValidator: RegExp;

  constructor(
    private datePipe: DatePipe,
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService) {
    this.urlPatternValidator = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
  }

  getMinimalVersionForApplyMaxOldSpaceSizeEnvVar(): string {
    return MINIMAL_DOCKER_IMAGE_TAG_APPLY_MAX_OLD_SPACE_SIZE_ENV_AS_STRING;
  }

  isDockerImageTagSelectedMinimalVersionForApplyMaxOldSpaceSizeEnvVar(deptAppsFlowsVersionToRunInstance: string): boolean {
    return this.internalIsDockerImageTagSelectMinimalVersionThat(MINIMAL_DOCKER_IMAGE_TAG_APPLY_MAX_OLD_SPACE_SIZE_ENV, deptAppsFlowsVersionToRunInstance);
  }

  getMinimalVersionForFullOfflineMode(): string {
    return MINIMAL_DOCKER_IMAGE_TAG_APPLY_FULL_OFFLINE_MODE_AS_STRING;
  }

  isDockerImageTagSelectedMinimalVersionForFullOfflineMode(deptAppsFlowsVersionToRunInstance: string): boolean {
    return this.internalIsDockerImageTagSelectMinimalVersionThat(MINIMAL_DOCKER_IMAGE_TAG_APPLY_FULL_OFFLINE_MODE, deptAppsFlowsVersionToRunInstance);
  }

  getMinimalVersionForVirtualScreen(): string {
    return MINIMAL_DOCKER_IMAGE_TAG_APPLY_VIRTUAL_SCREEN_AS_STRING;
  }

  isDockerImageTagSelectedMinimalVersionForVirtualScreen(deptAppsFlowsVersionToRunInstance: string): boolean {
    return this.internalIsDockerImageTagSelectMinimalVersionThat(MINIMAL_DOCKER_IMAGE_TAG_APPLY_VIRTUAL_SCREEN, deptAppsFlowsVersionToRunInstance);
  }

  getMinimalVersionForDesktopControlServerEmbedded(): string {
    return MINIMAL_DOCKER_IMAGE_TAG_APPLY_DESKTOP_CONTROL_SERVER_EMBEDDED_AS_STRING;
  }

  createNewDeptAppObject(): DeptApp {
    const isCurrentUserLoggedAdmin = this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue());
    return {
      idMDOneProject: !isCurrentUserLoggedAdmin
        && this.localStorageService.hasMDOneSelectedProject() ? this.localStorageService.getMDOneSelectedProject().id : null,
      deptAppSearchTag: [],
      associatedProjects: [],
    };
  }

  isDockerImageTagSelectedMinimalVersionForDesktopControlServerEmbedded(deptAppsFlowsVersionToRunInstance: string): boolean {
    return this.internalIsDockerImageTagSelectMinimalVersionThat(MINIMAL_DOCKER_IMAGE_TAG_APPLY_DESKTOP_CONTROL_SERVER_EMBEDDED, deptAppsFlowsVersionToRunInstance);
  }

  getMinimalVersionForEnableInsecureHttpParser(): string {
    return MINIMAL_DOCKER_IMAGE_TAG_FOR_INSECURE_HTTP_PARSER_AS_STRING;
  }

  isDockerImageTagSelectedMinimalVersionForEnableInsecureHttpParser(deptAppsFlowsVersionToRunInstance: string): boolean {
    return this.internalIsDockerImageTagSelectMinimalVersionThat(MINIMAL_DOCKER_IMAGE_TAG_FOR_INSECURE_HTTP_PARSER, deptAppsFlowsVersionToRunInstance);
  }

  getOrCreateUserVoteByFlowsRepositoryResource(flowsRepositoryResource: AbstractDeptAppsFlowsRepositoryResourceWithUserVote) {
    let userVote: DeptAppsFlowsRepositoryResourceUserVote;
    if (_.isNil(flowsRepositoryResource.currentUserLoggedVote)) {
      flowsRepositoryResource.currentUserLoggedVote = userVote = {
        voteUp: false,
        voteDown: false,
        resourceId: flowsRepositoryResource.id
      };
    } else {
      userVote = flowsRepositoryResource.currentUserLoggedVote;
    }
    return userVote;
  }

  customSearchFnAnyObject(term: string, item: any): boolean {
    let searchString;
    if (_.isString(item)) {
      searchString = this.normalizeAndRemoveAccents(item.toLowerCase());
    } else {
      searchString = _.keys(item).filter(idx => _.isString(item[idx])).map(idx => this.normalizeAndRemoveAccents(item[idx].toLowerCase())).join('|');
    }
    return this.internalCustomSearchFn(searchString, term);
  }

  customSearchFnUsersCache(term: string, user: User): boolean {
    let searchString = this.normalizeAndRemoveAccents(this.internalGetFullNameUserAndEmail(user).toLowerCase());
    let isMatching: boolean;
    if (!(isMatching = this.internalCustomSearchFn(searchString, term))) {
      isMatching = this.customSearchFnAnyObject(term, user);
    }
    return isMatching;
  }

  normalizeAndRemoveAccents(chars: string): string {
    return chars.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  isValidPortNumber(port: number) {
    if (_.isNil(port) || _.isObject(port)) {
      return false;
    }
    if (_.isString(port)) {
      if (_.isEmpty(port) || _.isNaN(port)) {
        return false;
      }
      port = _.toNumber(port);
    }
    if (_.isNaN(port) || port <= 0 || port > 65535) {
      return false;
    }
    return true;
  }

  getUrlPatternValidator(): RegExp {
    return this.urlPatternValidator;
  }

  openNewBrowserTab(url: string) {
    return window.open(url, '_blank');
  }

  expandTreeNodeArray(nodes: TreeNode[], isExpand: boolean) {
    nodes.forEach(node => {
      this.expandTreeNode(node, isExpand);
    });
  }

  expandTreeNode(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandTreeNode(childNode, isExpand);
      });
    }
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }

  humanizeMegabytes(valueInMegaBytes: number) {
    const bytes = valueInMegaBytes * 1024 * 1024;
    return prettyBytes(bytes, { binary: true } as any);
  }

  formatDateTime(date: Date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy HH:mm:ss');
  }

  formatTimestamp(date?: Date) {
    date = date || new Date();
    return this.datePipe.transform(date, 'ddMMyyyyHHmmss');
  }

  toggleTypeInputPassword(htmlInputElement: HTMLInputElement) {
    if (htmlInputElement.type === 'password') {
      htmlInputElement.type = 'text';
    } else {
      htmlInputElement.type = 'password';
    }
  }

  millisecondsToHumanReadableString(milliseconds) {
    // TIP: to find current time in milliseconds, use:
    // var  current_time_milliseconds = new Date().getTime();
    function numberEnding(number) {
      return (number > 1) ? 's' : '';
    }

    var temp = Math.floor(milliseconds / 1000);
    var years = Math.floor(temp / 31536000);
    if (years) {
      return years + ' year' + numberEnding(years);
    }
    //TODO: Months! Maybe weeks?
    var days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      return days + ' day' + numberEnding(days);
    }
    var hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return hours + ' hour' + numberEnding(hours);
    }
    var minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return minutes + ' minute' + numberEnding(minutes);
    }
    var seconds = temp % 60;
    if (seconds) {
      return seconds + ' second' + numberEnding(seconds);
    }
    return 'less than a second'; //'just now' //or other string you like;
  }

  capitalizeFirstLetter(characters: string) {
    return characters.charAt(0).toUpperCase() + characters.slice(1);
  }

  truncateString(value: string, size: number): string {
    let result: string;
    if (value && value.length > size) {
      result = `${value.substring(0, size)}...`;
    } else {
      result = value;
    }
    return result;
  }

  getFullNameUserByEmail(email: String, usersCache: User[]): string {
    let user: User;
    if (usersCache) {
      let initialLength = usersCache.length;
      usersCache = usersCache.filter(user => !_.isNil(user))
      if (usersCache.length !== initialLength) console.error("Some user's value was null. Let aXet.Flows support team know this")
      user = usersCache.find(currentUser => currentUser.email === email);
    }
    let result;
    if (user) {
      result = this.internalGetFullNameUserAndEmail(user);
    } else {
      result = email;
    }
    return result;
  }

  getIdUserByEmail(email: String, usersCache: User[]): number {
    const user = this.findUserByEmail(email, usersCache);
    let result: number = -1;
    if (!_.isNil(user)) result = user.id;

    return result;
  }

  findUserByEmail (email: String, usersCache: User[]): User {
    let user: User;
    if (usersCache) {
      const initialLength = usersCache.length;
      usersCache = usersCache.filter(user => !_.isNil(user))
      if (usersCache.length !== initialLength) console.error("Some user's value was null. Let aXet.Flows support team know this")
      user = usersCache.find(currentUser => currentUser.email === email);
      if (!user) {
        user = usersCache.find(currentUser => currentUser.login === email);
      }
    }

    return user;
  }

  getHpcCenterUserByEmail(email: String, usersCache: User[]) {
    let user;
    if (usersCache) {
      user = usersCache.find(currentUser => currentUser.email === email);
    }
    let result = 'Unknown';

    return result;
  }

  getProjectNameByIdMDOneProject(idMDOneProject: number | number[], projectsMe: Project[]) {
    const idMDOneProjectArray = !_.isArray(idMDOneProject) ? [idMDOneProject] : idMDOneProject;
    const projects: Project[] = (projectsMe || []).filter(currentProject => idMDOneProjectArray.includes(currentProject.id));
    let projectLabel;
    if (!_.isEmpty(projects)) {
      projectLabel = this.calculateVisibleLabelProject(projects);
    } else {
      projectLabel = 'unknown';
    }
    return projectLabel;
  }

  generateLongUUIDBasedPassword() {
    return uuidv4();
  }

  generateShortUUIDBasedPassword() {
    return this.generateLongUUIDBasedPassword().split("-")[0];
  }

  calculateVisibleLabelProject(project: Project | Project[]) {
    const projectArray = !_.isArray(project) ? [project] : project;
    return projectArray.map(currentProject => !!currentProject && !!currentProject.name && !!currentProject.name.length ? currentProject.name + ' (' + (!!currentProject.client && !!currentProject.client.name.length ? currentProject.client.name : 'unknown client') + ')' : 'unknown project').join(', ');
  }

  createAndDownloadBlobFile(body, options, filename) {
    this.downloadBlob(new Blob([body], options), filename);
  }

  createAndDownloadBlobFileFromBase64(base64Body, options, filename) {
    this.downloadBlob(this.b64toBlob(base64Body, options), filename);
  }

  getExtensionFilename(filename: string): string {
    return REGEX_GET_EXTENSION_NAME_FILE.exec(filename)[1];
  }

  buildCodeMirrorOptions(mode: string, extraOpts?: any) {
    return _.assign(_.assign(DEFAULT_CODE_MIRROR_OPTS, extraOpts), { mode });
  }

  private internalIsDockerImageTagSelectMinimalVersionThat(minimalVersion: VersionXYZ, deptAppsFlowsVersionToRunInstance: string) {
    try {
      return VersionXYZ.fromString(deptAppsFlowsVersionToRunInstance).compareTo(minimalVersion) >= 0;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  private downloadBlob(blob, filename) {
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement("a");
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }
  }

  private b64toBlob(b64Data, options?: any, sliceSize?: number) {
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, options);
    return blob;
  }

  private internalCustomSearchFn(searchString: string, term: string): boolean {
    return searchString.includes(this.normalizeAndRemoveAccents(term.toLowerCase()));
  }

  private internalGetFullNameUserAndEmail(user: User): string {
    return `${user.displayName} (${user.email})`;
  }

  fileToBase64(file): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>{
        let data:string = (reader.result as string).replace("data:", "").replace(/^.+,/, "");
        resolve(data);
      };
      reader.onerror = error => reject(error);
    });
  }
}
