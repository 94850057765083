import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../@core/utils/local-storage.service';

@Component({
  selector: 'ngx-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class NgxLogoutComponent implements OnInit {

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.localStorageService.clearAll();
  }
}
