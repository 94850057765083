import { TreeNode } from "primeng/api";
import { ForeignKeySpec } from "./database-spec.model";

export enum DatabaseTreeNodeType {
  TYPE_SCHEMA, TYPE_TABLE, TYPE_TABLE_GROUP, TYPE_COLUMN, TYPE_COLUMN_GROUP, TYPE_FOREIGN_KEY, TYPE_FOREIGN_KEY_GROUP, TYPE_INFO, TYPE_PRIMARY_KEY, TYPE_PRIMARY_KEY_GROUP
}

export interface DatabaseTreeNode extends TreeNode {
  databaseType?: DatabaseTreeNodeType;
}

export interface DatabaseForeignKeyTreeNode extends DatabaseTreeNode {
  foreignKeySpec?: ForeignKeySpec;
}

