import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { InitialStateConfig, ConfirmDialogConfig, ConfirmDialogActionEnum } from './confirm-dialog.model';

@Injectable()
export class ConfirmDialogService {
  private confirmDialogSubject = new Subject<ConfirmDialogConfig>();
  confirmDialogObservable = this.confirmDialogSubject.asObservable();

  confirm(behavior: { accept: () => void, cancel?: () => void }, initialState?: InitialStateConfig): void {
    this.confirmDialogSubject.next({
      action: ConfirmDialogActionEnum.SHOW,
      initialState: initialState,
      accept: behavior.accept,
      cancel: behavior.cancel,
    });
  }

  hide(): void {
    this.confirmDialogSubject.next({ action: ConfirmDialogActionEnum.HIDE });
  }

  stopSubmitted(): void {
    this.confirmDialogSubject.next({ action: ConfirmDialogActionEnum.STOP_SUBMITTED });
  }
}
