import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: 'footer.component.html',
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
