import { Injectable } from '@angular/core';
import { DeptAppsDesktopConnectionEndpoint } from '../../../models/desktop/deptapps-desktop-connection-endpoint.model';
import { UserPreferencesService } from '../../app/user-preferences.service';
import { DEFAULT_HOST_DEPTAPPS_DESKTOP, DEFAULT_PORT_DEPTAPPS_DESKTOP } from '..';

@Injectable()
export class DeptAppsDesktopConnectionEndpointService {

  constructor(
    private userPreferencesService: UserPreferencesService
  ) { }

  async getConnectionEndpoint(): Promise<DeptAppsDesktopConnectionEndpoint> {
    const currentUserPreferences = await this.userPreferencesService.findOrCreateByCurrentUserLogged();
    const deptappsDesktopBaseUrl = `${DEFAULT_HOST_DEPTAPPS_DESKTOP}:${currentUserPreferences.deptAppsDesktopConnectionPort || DEFAULT_PORT_DEPTAPPS_DESKTOP}`;
    return {
      httpConnectionBaseUrl: `${deptappsDesktopBaseUrl}/api`,
      webSocketConnectionBaseUrl: `${deptappsDesktopBaseUrl}/wsapi`
    };
  }
}
