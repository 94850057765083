import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS, IS_AXET_FLOWS_PARAMETER } from '.';
import { DeptAppStandaloneElectronDeployPlatformEnum } from '../../models/desktop/enums/deptapp-standalone-electron-deploy-plaftform.enum';
import { DeptAppsFlowsStandaloneElectronVersion } from '../../models/desktop/deptapps-flows-standalone-electron-version.model';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable()
export class DeptAppsVisualStudioAppCenterService {

  constructor(
    private checkVersionDesktopService: CheckVersionDesktopService,
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private http: HttpClient) { }

  async getVersionsInVisualStudioAppCenterByPlatform(platform: DeptAppStandaloneElectronDeployPlatformEnum, axetFlows: boolean): Promise<DeptAppsFlowsStandaloneElectronVersion[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppsFlowsStandaloneElectronVersion[]>(encodeURI(`${resourceBaseUri}/${platform}/releases?${IS_AXET_FLOWS_PARAMETER}=${axetFlows}`)).toPromise();
  }

  async getVersionsInVisualStudioAppCenterForSameOperatingSystem(axetFlows: boolean): Promise<DeptAppsFlowsStandaloneElectronVersion[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppsFlowsStandaloneElectronVersion[]>(encodeURI(`${resourceBaseUri}/same-operating-system/releases?${IS_AXET_FLOWS_PARAMETER}=${axetFlows}`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapps-visual-studio-app-center`;
  }

  getLatestVersionOfDeptappInstallerFromAppCenter(): Observable<any> {
    return this.http.get(`${environment.apiUrlBase}/getLatestDeptAppsInstallerVersion`);
    
  }
}
