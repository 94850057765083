import { Injectable } from '@angular/core';
import { VersionXYZ } from '../../models/common/version-xyz.model';
import { ConfirmDialogService } from '../../../@theme/components/confirm-dialog/confirm-dialog.service';
import { AppInternalDesktopConfigurationService } from './app-internal-desktop-configuration.service';
import { TypeAlertEnum } from '../../../@theme/components/alert/alert.model';
import { LoadingDialogService } from '../../../@theme/components/loading-dialog/loading-dialog.service';
import { AlertService } from '../../../@theme/components/alert/alert.service';
import { from, throwError, empty } from 'rxjs';
import { AppInternalDesktopConfiguration } from '../../models/desktop/app-internal-desktop-configuration.model';
import { map, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CheckVersionDesktopService {
  constructor(
    private appInternalDesktopConfigurationService: AppInternalDesktopConfigurationService,
    private alertService: AlertService,
    private loadingDialogService: LoadingDialogService,
    private confirmDialogService: ConfirmDialogService) { }

  async isCorrectDesktopVersion(expectedVersionMDOneDesktop: VersionXYZ): Promise<any> {
    return from(this.appInternalDesktopConfigurationService.get()).
      pipe(map((appInternalDesktopConfiguration: AppInternalDesktopConfiguration) => {
        const versionDesktop: VersionXYZ = VersionXYZ.fromString(appInternalDesktopConfiguration.version);
        if (versionDesktop.compareTo(expectedVersionMDOneDesktop) < 0) {
          this.showMessageNeedUpgrade();
          throw new Error('Need upgrade aXet.flows Desktop!!');
        } else {
          return empty();
        }
      }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.handleShutdown(err, () => {
                this.alertService.addAlertMessage({
                  type: TypeAlertEnum.ERROR,
                  summary: `Couldn't check if you have the correct version of aXet.flows Desktop version`,
                  details: 'Try later!',
                });
            });
            this.loadingDialogService.hide();
            this.confirmDialogService.hide();
          }
          return throwError(err);
        })).toPromise();
  }

  handleShutdown(error: any, handleOthersErrors: () => void, othersActionIfShutdown?: () => void): void {
    if (error.status === 0) {
      this.alertService.addAlertMessage({
        type: TypeAlertEnum.ERROR,
        summary: `aXet.flows Desktop isn't running!`,
        details: `Run this one!`,
      });
      this.loadingDialogService.hide();
      this.confirmDialogService.hide();
      if (othersActionIfShutdown) {
        othersActionIfShutdown();
      }
    } else {
      handleOthersErrors();
    }
  }

  private showMessageNeedUpgrade(): void {
    this.alertService.addAlertMessage({
      type: TypeAlertEnum.WARNING,
      summary: 'Need a new version of aXet.flows Desktop!',
      details: 'Please, download new version!',
    });
  }
}
