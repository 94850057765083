import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppInternalDesktopConfiguration } from '../../models/desktop/app-internal-desktop-configuration.model';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';

@Injectable()
export class AppInternalDesktopConfigurationService {

  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private http: HttpClient) { }

  async get(): Promise<AppInternalDesktopConfiguration> {
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<AppInternalDesktopConfiguration>(encodeURI(resourceBaseUri)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/app/desktop/internal-config`;
  }
}
