export function getQueryParamsHref(): Array<any> {
  const queryParams = new Array<any>();
  const hrefSplit: string[] = window.location.href.split('?');
  if (hrefSplit && hrefSplit.length > 1) {
    let keyValueSplit: string[];
    for (const queryParam of hrefSplit[1].split('&')) {
      keyValueSplit = queryParam.split('=');
      if (keyValueSplit && keyValueSplit.length > 1) {
        queryParams[keyValueSplit[0]] = keyValueSplit[1];
      }
    }
  }
  return queryParams;
}
