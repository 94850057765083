import {
  Component, TemplateRef, ViewChild, OnDestroy, AfterViewInit,
} from '@angular/core';
import { LoadingDialogService } from './loading-dialog.service';
import { LoadingDialogActionEnum } from './loading-dialog.model';
import { Subscription } from 'rxjs';
import * as prettyBytes from 'pretty-bytes';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-loading-dialog',
  templateUrl: './loading-dialog.component.html',
})
export class NgxLoadingDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('loadingDialogModal')
  loadingDialogModal: TemplateRef<any>;

  loaded: number;
  total: number;
  downloadingFile: boolean;

  modalOpenRef: NgbModalRef;
  loadingDialogServiceSubscription: Subscription;

  constructor(
    private modalService: NgbModal,
    private loadingDialogService: LoadingDialogService,
  ) {
    this.downloadingFile = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadingDialogServiceSubscription = this.loadingDialogService.loadingDialogObservable.subscribe(
        config => {
          if (config.action === LoadingDialogActionEnum.SHOW) {
            this.closeCurrentExistingLoadingDialog();
            this.loaded = config.loaded;
            this.total = config.total;
            this.downloadingFile = config.isDownloadFile || false;
            this.modalOpenRef = this.modalService.open(this.loadingDialogModal, {
              size: 'sm',
              backdrop: 'static',
              keyboard: false,
              centered: true,
            });
          } else if (config.action === LoadingDialogActionEnum.UPDATE_DOWNLOAD_PROGRESS) {
            this.downloadingFile = true;
            this.loaded = config.loaded;
            this.total = config.total;
          } else if (config.action === LoadingDialogActionEnum.HIDE) {
            if (this.modalOpenRef) {
              setTimeout(() => {
                this.closeCurrentExistingLoadingDialog();
              }, 1000);
            }
          }
        },
      );
    }, 0);
  }

  buildMessageLoading(): string {
    let message = this.downloadingFile ? 'Downloading...' : 'Loading...';
    if (this.loaded && this.loaded > 0 && this.calculatePercent() >= 60) {
      message += ` (${prettyBytes(this.loaded)}/${prettyBytes(this.total)})`
    }
    return message;
  }

  calculatePercent(): number {
    let percent;
    if (this.total && this.total > 0) {
      let current = this.loaded && this.loaded >= 0 ? this.loaded : 0;
      percent = (current / this.total) * 100;
    } else {
      percent = 0;
    }
    return percent;
  }

  private closeCurrentExistingLoadingDialog() {
    if (this.modalOpenRef) {
      this.modalOpenRef.close()
      this.modalOpenRef = undefined;
      this.downloadingFile = false;
    }
  }

  ngOnDestroy(): void {
    if(!!this.loadingDialogServiceSubscription){
      this.loadingDialogServiceSubscription.unsubscribe();
    }
  }
}
