import { Injectable } from '@angular/core';
import { MDOneExternalConfigService } from '../services/mdone/mdone-external-config.service';
import { MDOneExternalConfig } from '../models/mdone/mdone-external-config.model';
import { Project } from '../models/mdone/project.model';
import { UserPreferences } from '../models/app/user-preferences.model';
import { JwtUtilsService } from './jwt-utils.service';

import * as _ from 'lodash';

const LOCAL_STORAGE_AXET_CONFIG = 'axet_config';
const LOCAL_STORAGE_JWT_VALUE = 'jwt_value';
const LOCAL_STORAGE_MDONE_SELECTED_PROJECT = 'mdone_selected_project';
const LOCAL_STORAGE_MDONE_SELECTED_PROJECT_ID_QUERY_PARAM = 'mdone_selected_project_id_query_param';
const LOCAL_STORAGE_USER_PREFERENCES = 'user_preferences';

@Injectable()
export class LocalStorageService {

  constructor(
    private jwtUtilsService: JwtUtilsService,
    private externalConfigService: MDOneExternalConfigService) { }

  async getMDOneExternalConfig(): Promise<MDOneExternalConfig> {
    let finalResult: MDOneExternalConfig;
    let mdoneExternalConfigString: string = localStorage.getItem(LOCAL_STORAGE_AXET_CONFIG);
    if (mdoneExternalConfigString) {
      finalResult = JSON.parse(mdoneExternalConfigString);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_JWT_VALUE);
      finalResult = await this.externalConfigService.get();
      mdoneExternalConfigString = JSON.stringify(finalResult);
      localStorage.setItem(LOCAL_STORAGE_AXET_CONFIG, mdoneExternalConfigString);
    }
    return finalResult;
  }

  getJwtValue(): string {
    return localStorage.getItem(LOCAL_STORAGE_JWT_VALUE);
  }

  setJwtValue(jwtValue: string) {
    localStorage.setItem(LOCAL_STORAGE_JWT_VALUE, jwtValue);
  }

  hasJwtValue(): boolean {
    return !!this.getJwtValue();
  }

  clearJwtValue(): void {
    localStorage.removeItem(LOCAL_STORAGE_JWT_VALUE);
  }

  getMDOneSelectedProject(): Project {
    const projectRaw: string = localStorage.getItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT);
    if (projectRaw && projectRaw.length) {
      return JSON.parse(projectRaw);
    }
  }

  hasMDOneSelectedProject(): boolean {
    return !!this.getMDOneSelectedProject();
  }

  setMDOneSelectedProject(project: Project): boolean {
    if (project) {
      localStorage.setItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT, JSON.stringify(project));
      return true;
    }
    return false;
  }

  clearMDOneSelectedProject(): void {
    localStorage.removeItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT);
    this.popProjectIdReceived();
  }

  putProjectIdReceived(projectId: string): void {
    if (projectId && projectId.length) {
      localStorage.setItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT_ID_QUERY_PARAM, projectId);
    }
  }

  popProjectIdReceived(): string {
    const projectId = localStorage.getItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT_ID_QUERY_PARAM);
    localStorage.removeItem(LOCAL_STORAGE_MDONE_SELECTED_PROJECT_ID_QUERY_PARAM);
    return projectId;
  }

  /* User preferences */

  saveUserPreferences(userPreferences: UserPreferences) {
    const emailUserLogged = this.jwtUtilsService.getEmail(this.getJwtValue());
    localStorage.setItem(LOCAL_STORAGE_USER_PREFERENCES, JSON.stringify(_.assign(userPreferences, { emailUser: emailUserLogged })));
  }

  getUserPreferences(): UserPreferences {
    const emailUserLogged = this.jwtUtilsService.getEmail(this.getJwtValue());

    let userPreferences: UserPreferences;
    const userPreferencesString = localStorage.getItem(LOCAL_STORAGE_USER_PREFERENCES);
    if (!_.isEmpty(userPreferencesString)) {
      try {
        const candidateUserPreferences: UserPreferences = JSON.parse(userPreferencesString);
        if (candidateUserPreferences.emailUser === emailUserLogged) {
          userPreferences = candidateUserPreferences;
        } else {
          this.clearUserPreferences();
        }
      } catch (e) {
        console.error(e);
        this.clearUserPreferences();
      }
    }
    return userPreferences;
  }

  clearUserPreferences() {
    localStorage.removeItem(LOCAL_STORAGE_USER_PREFERENCES);
  }

  clearAll(): void {
    localStorage.clear();
  }
}
