import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { Identifier } from '../../models/common/identifier.model';
import { DeptAppsFlowsThirdPartyLibraryCertified } from '../../models/app/deptapps-flows-third-party-library-version-certified.model';

import { environment } from '../../../../environments/environment';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-flows-third-party-library-certified`;

@Injectable()
export class DeptAppsFlowsThirdPartyLibraryCertifiedService {

  constructor(private http: HttpClient) { }

  async findBySearchFilterPagination(searchText?: string, discharged?: boolean, page?: number, limit?: number): Promise<PaginationResult<DeptAppsFlowsThirdPartyLibraryCertified>> {
    let params = new HttpParams().set('discharged', (!!discharged).toString());
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppsFlowsThirdPartyLibraryCertified>>(encodeURI(`${RESOURCE_BASE_URI}/page`), { params }).toPromise();
  }

  async findById(deptAppsFlowsThirdPartyLibraryCertified: DeptAppsFlowsThirdPartyLibraryCertified): Promise<DeptAppsFlowsThirdPartyLibraryCertified> {
    return await this.http.get<DeptAppsFlowsThirdPartyLibraryCertified>(encodeURI(`${RESOURCE_BASE_URI}/${deptAppsFlowsThirdPartyLibraryCertified.id}`)).toPromise();
  }

  async create(deptAppsFlowsThirdPartyLibraryCertified: DeptAppsFlowsThirdPartyLibraryCertified): Promise<Identifier<number>> {
    return await this.http.post<Identifier<number>>(encodeURI(RESOURCE_BASE_URI), deptAppsFlowsThirdPartyLibraryCertified).toPromise();
  }

  async update(deptAppsFlowsThirdPartyLibraryCertified: DeptAppsFlowsThirdPartyLibraryCertified): Promise<any> {
    return await this.http.put(encodeURI(`${RESOURCE_BASE_URI}/${deptAppsFlowsThirdPartyLibraryCertified.id}`), deptAppsFlowsThirdPartyLibraryCertified).toPromise();
  }

  async delete(deptAppsFlowsThirdPartyLibraryCertified: DeptAppsFlowsThirdPartyLibraryCertified): Promise<any> {
    return await this.http.delete(encodeURI(`${RESOURCE_BASE_URI}/${deptAppsFlowsThirdPartyLibraryCertified.id}`)).toPromise();
  }

  async enableAgain(deptAppsFlowsThirdPartyLibraryCertified: DeptAppsFlowsThirdPartyLibraryCertified): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptAppsFlowsThirdPartyLibraryCertified.id}/enable/again`), {}).toPromise();
  }
}
