import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppMember } from '../../models/app/deptapp-member.model';
import { LocalStorageService } from '../../utils/local-storage.service';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;
const RESOURCE_BASE_URI_USER = `${environment.apiUrlBase}`;

@Injectable()
export class DeptAppMemberService {

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,) { }

    private httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Auth-Token": this.localStorageService.getJwtValue(),
      })
    };

  async findBySearchFilter(deptApp, memberId?: number,
    idDeptAppMemberPermissionType?: number, discharged?: boolean, page?: number, limit?: number): Promise<PaginationResult<DeptAppMember>> {
      let id = getId(deptApp);

    return await this.http.post<PaginationResult<DeptAppMember>>(encodeURI(`${RESOURCE_BASE_URI}/member/search`), { 
      "discharged": (!!discharged).toString(),
      "idUser": memberId && memberId>0 ? memberId : undefined,
      "idDeptApp": id ? id : undefined,
      "id-deptapp-member-permission-type": idDeptAppMemberPermissionType && idDeptAppMemberPermissionType > 0 ? idDeptAppMemberPermissionType.toString() : undefined,
      "page": page && page > 0 ? page.toString() : 1,
      "limit": limit && limit > 0 ? limit.toString() : 1
     }).toPromise();
  }

  async getDeptAppMembersByDeptAppIds(deptAppIds: []): Promise<DeptAppMember[]> {
    return await this.http.post<DeptAppMember[]>(encodeURI(`${RESOURCE_BASE_URI}/member/deptappids`), deptAppIds).toPromise();
  }
  async findById(deptAppMember: DeptAppMember): Promise<DeptAppMember> {
    return await this.http.get<DeptAppMember>(encodeURI(`${RESOURCE_BASE_URI}/member/${deptAppMember.id}`)).toPromise();
  }

  async create(deptApp, deptAppMember: DeptAppMember): Promise<any> {
    let id = getId(deptApp);
    
    return await this.http.put(encodeURI(`${RESOURCE_BASE_URI}/${id}/member`), deptAppMember).toPromise();
  }

  async update(deptAppMember: DeptAppMember): Promise<any> {
    return await this.http.put(encodeURI(`${RESOURCE_BASE_URI}/member/${deptAppMember.id}`), deptAppMember).toPromise();
  }

  async delete(deptAppMember: DeptAppMember): Promise<any> {
    return await this.http.delete(encodeURI(`${RESOURCE_BASE_URI}/member/${deptAppMember.id}`)).toPromise();
  }

  async enableAgain(deptAppMember: DeptAppMember): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/member/${deptAppMember.id}/enable/again`), {}).toPromise();
  }

  async connect() {
    this.http.post(`${RESOURCE_BASE_URI_USER}/deptapp/verification`, {}, this.httpOptions).toPromise();
  }
}

function getId(deptApp: DeptApp | number): number {
  return typeof deptApp === 'number' ? deptApp : deptApp.id;
}
