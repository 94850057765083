import { Injectable } from '@angular/core';
import { UserClaims } from '../models/mdone/user-claims.model';
import { RoleEnum } from '../models/mdone/enums/role.enum';
import { User } from '../models/app/user.model';

@Injectable()
export class JwtUtilsService {

  getUserClaims(jwtToken: string): UserClaims {
    const claims = this.getTokenClaims(jwtToken);
    const userClaims = new UserClaims();
    Object.assign(userClaims, claims);
    userClaims.email = claims.unique_name;
    delete (userClaims as any).unique_name;
    return userClaims;
  }
  

  getEmail(jwtToken: string): string {
    return this.getTokenClaims(jwtToken).unique_name;
  }

  getRoles(jwtToken: string): string[] {
    return this.getTokenClaims(jwtToken).roles || [];
  }

  getOktaId(jwtToken: string): string {
    return this.getTokenClaims(jwtToken).okta_id;
  }

  getFlowsUserId(jwtToken: string): number {
    return this.getTokenClaims(jwtToken).sub;
  }

  isUserAdmin(jwtToken: string): boolean {
    return this.getRoles(jwtToken).some(role => role === RoleEnum.ADMIN);
  }
  
  isUserAuditor(jwtToken: string): boolean {
    return this.getRoles(jwtToken).some(role => role === RoleEnum.AUDITOR);
  }

  isTokenExpired(jwtToken: string): boolean {
    return this.getTokenClaims(jwtToken).exp - (Date.now() / 1000) <= 0;
  }

  isImpersonated(jwtToken: string): boolean {
    return this.getTokenClaims(jwtToken).impersonate
  }

  isTokenInThirdAge(jwtToken: string, tokenLifeTimeInSeconds: number) {
    const tokenLifeTimeInMilliseconds: number = tokenLifeTimeInSeconds * 1000;
    const howToExpiresToken = this.getTimestampTokenExpiredInMilliseconds(jwtToken) - Date.now();
    return howToExpiresToken > 0 && howToExpiresToken / (1000 * 60) >= 1 && howToExpiresToken <= (tokenLifeTimeInMilliseconds / 3);
  }

  isValidToken(token){
    const parts = token.split('.');
    if (parts.length !== 3) {
        //No tiene 3 partes no es un jwt valido
        return false;
    } else {  
      const [header, payload, signature] = parts;
      if (!this.isBase64(header) || !this.isBase64(payload)) {
          //Alguna parte no está en base 64
          return false;
      } else {
        try {
          //Intentamos descodificarlo y da error no es un jwt valido  
            const decodedHeader = JSON.parse(atob(header));
            const decodedPayload = JSON.parse(atob(payload));
          } catch (error) {
            return false;
          }
        }
    }
    //Si llegamos es valido
    return true;

  }

  private getTimestampTokenExpiredInMilliseconds(jwtToken: string): number {
    return this.getTokenClaims(jwtToken).exp * 1000;
  }

  private getTokenClaims(jwtToken: string) {
    const claimsBase64 = jwtToken.split('.')[1];
    const claimsObject = JSON.parse(this.base64UrlDecode(claimsBase64));
    return claimsObject;
  }

  private base64UrlDecode(str) {
    var output = str.replace(/-/g, "+").replace(/_/g, "/");
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += "==";
        break;
      case 3:
        output += "=";
        break;
      default:
        throw "Illegal base64url string!";
    }

    try {
      return this.b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  }

  private b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = '0' + code;
      }
      return '%' + code;
    }));
  }
  private isBase64(str) {
    const base64Pattern = /^[A-Za-z0-9+/\-]+[=]{0,2}$/;
    return base64Pattern.test(str);
}

}
