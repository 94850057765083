import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppVersionHeaderData, DeptAppVersion, state } from '../../models/app/deptapp-version.model';
import { Flag } from '../../models/common/flag.model';
import { IdentifierList } from '../../models/common/identifier-list.model';
import { DeptAppVersionImportFormatInfoDeptAppV1 } from '../../models/app/deptapp-version-import-format-v1.model';
import { DeptAppAdvancedSearchFilter } from '../../models/app/filters/deptapp-advanced.search-filter';
import { DeptAppsFlowsSharedSubflowVersionUsed } from '../../models/app/deptapps-flows-shared-subflow-version-used.model';
import { Subject } from 'rxjs';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;

@Injectable()
export class DeptAppVersionService {

  private $changeStateDeptappVersion = new Subject<DeptAppVersion>();

  constructor(
    private http: HttpClient) { }

  async findBySearchFilter(deptApp: DeptApp, searchText?: string): Promise<DeptAppVersionHeaderData[]> {
    let params = new HttpParams();
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    return await this.http.get<DeptAppVersionHeaderData[]>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/version/list`), { params }).toPromise();
  }

  async findBySearchFilterPagination(deptApp: DeptApp, discharged: boolean, searchText?: string,
    page?: number, limit?: number): Promise<PaginationResult<DeptAppVersion>> {
    let params = new HttpParams().set('discharged', (!!discharged).toString());;
    if (searchText && searchText.length) {
      params = params.append('q', searchText);
    }
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppVersion>>(encodeURI(
      `${RESOURCE_BASE_URI}/${deptApp.id}/version/page`), { params }).toPromise();
  }

  async findByIds(identifierList: IdentifierList<number>): Promise<DeptAppVersion[]> {
    return await this.http.post<DeptAppVersion[]>(encodeURI(`${RESOURCE_BASE_URI}/version/query`), identifierList).toPromise();
  }

  async findById(deptAppVersion: DeptAppVersion): Promise<DeptAppVersion> {
    return await this.http.get<DeptAppVersion>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}`)).toPromise();
  }

  async findLatestByDeptApp(deptApp: DeptApp, includeCredentials: boolean): Promise<DeptAppVersion> {
    return await this.http.get<DeptAppVersion>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/version/latest`), {
      params: new HttpParams().append('include-credentials', (!!includeCredentials).toString()),
    }).toPromise();
  }

  async existsByDeptApp(deptApp: DeptApp): Promise<Flag> {
    return await this.http.get<Flag>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/version/any`)).toPromise();
  }

  async infoRunDeptAppVersionInProductionMode(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.get<any>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/info/run/production-mode`)).toPromise();
  }

  async infoExportDeptAppVersionInFullOfflineMode(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.get<any>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/info/export/mode/full-offline`)).toPromise();
  }

  async importInV1Format(deptApp: DeptApp, importData: DeptAppVersionImportFormatInfoDeptAppV1): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/version/import/v1`), importData).toPromise();
  }

  async exportInV1Format(deptAppVersion: DeptAppVersion): Promise<ArrayBuffer> {
    return await this.http.get<ArrayBuffer>(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/export/v1`), {
      responseType: 'arraybuffer' as 'json',
    }).toPromise();
  }

  async findAllDeptAppsFlowsVersionUsedToDesign(): Promise<string[]> {
    return await this.http.get<string[]>(encodeURI(`${RESOURCE_BASE_URI}/version/deptapps-flows-version-used-to-design`)).toPromise();
  }

  async findAllDeptAppFlowSharedSubflowVersionUsed(): Promise<DeptAppsFlowsSharedSubflowVersionUsed[]> {
    return await this.http.get<DeptAppsFlowsSharedSubflowVersionUsed[]>(`${RESOURCE_BASE_URI}/version/deptapps-flows-shared-subflow-version-used`).toPromise();
  }

  async findByAdvancedSearchFilter(searchFilter: DeptAppAdvancedSearchFilter, limit?: number): Promise<PaginationResult<DeptAppVersion>> {
    let params = new HttpParams();
    if (searchFilter.page && searchFilter.page > 0) {
      params = params.append('page', searchFilter.page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.post<PaginationResult<DeptAppVersion>>(encodeURI(`${RESOURCE_BASE_URI}/version/advanced-search`), searchFilter, { params }).toPromise();
  }

  async exportExcelAdvancedDeptAppSearch(searchFilter: DeptAppAdvancedSearchFilter): Promise<Blob> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/version/advanced-search/export`), searchFilter, { responseType: 'blob' }).toPromise();
  }

  async enableDeptAppVersion(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/enable/again`), {}).toPromise();
  }

  async deleteDeptAppVersion(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.delete(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}`)).toPromise();
  }

  async getSwaggerDocsVersion2(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.get(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/swagger-docs`)).toPromise();
  }

  async getOpenApiDocsVersion3(deptAppVersion: DeptAppVersion): Promise<any> {
    return await this.http.get(encodeURI(`${RESOURCE_BASE_URI}/version/${deptAppVersion.id}/openapi-v3-docs`)).toPromise();
  }

  scanVersion(id: number){
    return this.http.get(encodeURI(`${environment.apiUrlBase}/analysis?version-id=${id}`),{responseType: 'text'});
  }

  setVersionStatus(id: number, param? : any){
    return this.http.get(encodeURI(`${environment.apiUrlBase}/analysis/recover?version-id=${id}&hotspots=${param}`),{responseType: 'text'});
  }

  getChangeStateDeptappVersion(){
    return this.$changeStateDeptappVersion;
  }

  setChangeStateDeptappVersion(deptappVer : DeptAppVersion){
    return this.$changeStateDeptappVersion.next(deptappVer);
  }

  async getVersionFlowJson(id: number){
    return JSON.parse(await this.http.get(encodeURI(`${environment.apiUrlBase}/deptapp/version/${id}/content/flows.json`), {
      responseType: 'text'
    }).toPromise());
  }
}
