import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { User } from "../../models/app/user.model";
import { PaginationResult } from '../../models/mdone/PaginationResult.model';

import * as _ from "lodash";
import { NewUserDetails } from "../../models/app/new-user-details.model";
import { LocalStorageService } from "../../../@core/utils/local-storage.service";
import { Role } from "../../models/mdone/role.model";
import { List } from "lodash";
import { RoleEnum } from "../../models/mdone/enums/role.enum";
import { JwtUtilsService } from "../../utils/jwt-utils.service";

const RESOURCE_BASE_URI = `${environment.apiUrlBase}`;
const RESOURCE_BASE_URI_ONE = `${environment.apiUrlBase}/user`;

@Injectable()
export class UserRemoteService {
  private usersCacheDistinctMe: User[];
  private usersCacheDistinctMeSubject: ReplaySubject<User[]>;

  private usersCacheComplete: User[];
  private usersCacheCompleteSubject: ReplaySubject<User[]>;

  usersCacheDistinctMeObservable: Observable<User[]>;
  usersCacheCompleteObservable: Observable<User[]>;

  // private userDetails = new Subject<NewUserDetails>();
  private userDetails = new BehaviorSubject<NewUserDetails>({
     preferredLanguage: "",
    lastName: "",
    displayName: "",
    secondEmail: "",
    title: "",
    locale: "",
    employeeNumber: 0,
    firstName: "",
    mobilePhone: 0,
    department: "",
    email: "",
    login: "",});

  constructor(private http: HttpClient,
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService
  ) {
    this.usersCacheDistinctMe = [];
    this.usersCacheDistinctMeSubject = new ReplaySubject<User[]>();
    this.usersCacheDistinctMeObservable =
      this.usersCacheDistinctMeSubject.asObservable();
    this.usersCacheComplete = [];
    this.usersCacheCompleteSubject = new ReplaySubject<User[]>();
    this.usersCacheCompleteObservable =
      this.usersCacheCompleteSubject.asObservable();

    this.refreshUsersCache();
  }


  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "X-Auth-Token": this.localStorageService.getJwtValue(),
    })
  };


  async findAll(): Promise<PaginationResult<User>> {
    return await this.http
      .get<PaginationResult<User>>(encodeURI(`${RESOURCE_BASE_URI_ONE}/all`))
      .toPromise();
  }

  async getRoles(): Promise<Role[]> {
    return await this.http
      .get<Role[]>(encodeURI(`${RESOURCE_BASE_URI_ONE}/roles`))
      .toPromise();
  }

  async findBySearchFilter(
    searchText: string,
    distinctMe: boolean
  ): Promise<User[]> {
    let params = new HttpParams();
    if (!_.isEmpty(searchText)) {
      params = params.append("q", searchText);
    }
    if (!_.isNil(distinctMe)) {
      params = params.append("distinct-me", distinctMe.toString());
    }
    return await this.http
      .get<User[]>(encodeURI(RESOURCE_BASE_URI_ONE), { params })
      .toPromise();
  }

  async loadUsers (): Promise<any> {
    return await this.http
    .get<any>(encodeURI(RESOURCE_BASE_URI_ONE + "/all/me"), {  })
    .toPromise();
  } 

  async findSingleUser(email: string): Promise<User> {
    return await this.http
      .get<User>(encodeURI(`${RESOURCE_BASE_URI_ONE}/single`), {
        params: new HttpParams().append("email", email),
      })
      .toPromise();
  }

  private async refreshUsersCache() {
    let usersResponse = (await this.loadUsers());
    
    this.usersCacheDistinctMe = usersResponse.usersDistinctMe;
    this.usersCacheComplete = usersResponse.usersDistinctMe.concat(usersResponse.userMe);

    this.usersCacheDistinctMeSubject.next(this.usersCacheDistinctMe);
    this.usersCacheCompleteSubject.next(this.usersCacheComplete);
  }

  registerNewUserInMdOne(userInfo: any): Observable<any> {
    return this.http.post(`${RESOURCE_BASE_URI}/insertUser`, userInfo);
  }

  checkIfNewUserEmailIsValid(): Observable<any> {
    const backend_url = RESOURCE_BASE_URI.replace('api','');

    return this.http.get(`${backend_url}okta/userInfo`, this.httpOptions);
  }


  setUserDetails(userDetails: NewUserDetails) {
    this.userDetails.next(userDetails);
  }

  getUserDetails() {
    return this.userDetails.asObservable();
  }

  // Migración de users de MDOne. Tarea ACOE-2929
  getUsers(): Observable<PaginationResult<User>> {
    return this.http.get<PaginationResult<User>>(`${RESOURCE_BASE_URI_ONE}/all`);
  }
  async findUsersBySearchFilter(
    searchText: string,
    distinctMe: boolean,
    deregistered: boolean,
  ): Promise<User[]> {
    let params = new HttpParams();
    if (!_.isEmpty(searchText)) {
      params = params.append("q", searchText);
    }
    if (!_.isNil(distinctMe)) {
      params = params.append("distinct-me", distinctMe.toString());
    }
    if (!_.isNil(deregistered)) {
      params = params.append("discharge", deregistered.toString());
    }

    return await this.http.get<User[]>(encodeURI(RESOURCE_BASE_URI_ONE), { params }).toPromise();
  }

  getUser(email: string): Observable<User> {
    return this.http.get<User>(`${RESOURCE_BASE_URI_ONE}/${email}`);
  }

  async createUser(user: User): Promise<HttpResponse<User>> {
    return await this.http.post<User>(RESOURCE_BASE_URI_ONE, user, { observe: 'response'}).toPromise();
  }

  async updateUser(user: User): Promise<HttpResponse<User>> {
    return await this.http.put<User>(RESOURCE_BASE_URI_ONE, user, { observe: 'response'}).toPromise();
  }

  async deleteUser(id: number): Promise<HttpResponse<void>> {
    return await this.http.delete<void>(`${RESOURCE_BASE_URI_ONE}/${id}`, { observe: 'response' }).toPromise();
  }

  async getCoreUser(): Promise<User> {
    const id = this.jwtUtilsService.getOktaId(localStorage.getItem('jwt_value'));
    return await this.http.post<User>(`${RESOURCE_BASE_URI}/core/getUser`, {id}).toPromise();
  }
}
