import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { LocalStorageService } from '../../utils/local-storage.service';
import { JwtUtilsService } from '../../utils/jwt-utils.service';
import { DeptAppsFlowsSharedSubflow } from '../../models/app/deptapps-flows-shared-subflow.model';
import { DeptAppsFlowsSharedSubflowSearchFilter } from '../../models/app/filters/deptapps-flows-shared-subflow.search-filter';
import { DeptAppsFlowsRepositoryResourceUserVote } from '../../models/app/deptapps-flows-repository-resource-user-vote.model';
import { DeptAppsFlowsRepositoryResourceUserVoteStatus } from '../../models/app/deptapps-flows-repository-resource-user-vote-status.model';
import { DeptAppsFlowsRepositoryResourceMostUsed } from '../../models/app/deptapps-flows-repository-resource-most-used.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-flows-shared-subflow`;

@Injectable()
export class DeptAppsFlowsSharedSubflowService {

  constructor(
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService,
    private http: HttpClient) { }

  async findBySearchFilterPagination(searchFilter?: DeptAppsFlowsSharedSubflowSearchFilter, limit?: number): Promise<PaginationResult<DeptAppsFlowsSharedSubflow>> {
    const params = this.createQueryParamsBySearchFilter(searchFilter, limit);
    return await this.http.get<PaginationResult<DeptAppsFlowsSharedSubflow>>(this.buildURIByResourceBaseURI(`/page`), { params }).toPromise();
  }

  async exportData(searchFilter?: DeptAppsFlowsSharedSubflowSearchFilter, limit?: number): Promise<Blob> {
    const params = this.createQueryParamsBySearchFilter(searchFilter, limit);
    return await this.http.get(this.buildURIByResourceBaseURI(`/xlsx`), { params, responseType: 'blob' }).toPromise();
  }

  async findById(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow): Promise<DeptAppsFlowsSharedSubflow> {
    return await this.http.get<DeptAppsFlowsSharedSubflow>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}`)).toPromise();
  }

  async findUserVoteBySearchFilter(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow, page?: number, limit?: number): Promise<PaginationResult<DeptAppsFlowsRepositoryResourceUserVote>> {
    let params = new HttpParams();
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppsFlowsRepositoryResourceUserVote>>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}/user-vote/page`), { params }).toPromise();
  }

  async createOrUpdateUserVote(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow, userVote: DeptAppsFlowsRepositoryResourceUserVote): Promise<DeptAppsFlowsRepositoryResourceUserVoteStatus> {
    return await this.http.post<DeptAppsFlowsRepositoryResourceUserVoteStatus>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}/user-vote`), userVote).toPromise();
  }

  async update(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow): Promise<any> {
    return await this.http.patch(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}`), deptAppsFlowsSharedSubflow).toPromise();
  }

  async delete(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow): Promise<any> {
    return await this.http.delete(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}`)).toPromise();
  }

  async enableAgain(deptAppsFlowsSharedSubflow: DeptAppsFlowsSharedSubflow): Promise<any> {
    return await this.http.post(this.buildURIByResourceBaseURI(`/${deptAppsFlowsSharedSubflow.id}/enable/again`), {}).toPromise();
  }

  async getMostUsedSharedSubflow(page: number, limit: number): Promise<PaginationResult<DeptAppsFlowsRepositoryResourceMostUsed<DeptAppsFlowsSharedSubflow>>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return await this.http.get<PaginationResult<DeptAppsFlowsRepositoryResourceMostUsed<DeptAppsFlowsSharedSubflow>>>(this.buildURIByResourceBaseURI(`/ranking/most-used`), { params }).toPromise();
  }

  async getHighestRatedSharedSubflow(page: number, limit: number): Promise<PaginationResult<DeptAppsFlowsSharedSubflow>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return await this.http.get<PaginationResult<DeptAppsFlowsSharedSubflow>>(this.buildURIByResourceBaseURI(`/ranking/highest-rated`), { params }).toPromise();
  }

  buildURIByResourceBaseURI(contextPath?: string) {
    return encodeURI(RESOURCE_BASE_URI + (contextPath || ''));
  }

  private createQueryParamsBySearchFilter(searchFilter?: DeptAppsFlowsSharedSubflowSearchFilter, limit?: number) {
    searchFilter = searchFilter || {};
    let params = new HttpParams().set('discharged', (!!searchFilter.discharged).toString());
    if (searchFilter.searchText && searchFilter.searchText.length) {
      params = params.append('q', searchFilter.searchText);
    }
    if (searchFilter.page && searchFilter.page > 0) {
      params = params.append('page', searchFilter.page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    let idMDOneProjectSelected;
    if (!!searchFilter.idMDOneProject) {
      idMDOneProjectSelected = searchFilter.idMDOneProject;
    } else if (!this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue()) &&
      this.localStorageService.hasMDOneSelectedProject()) {
      idMDOneProjectSelected = this.localStorageService.getMDOneSelectedProject().id;
    }
    if (idMDOneProjectSelected) {
      params = params.append('id-mdone-project', idMDOneProjectSelected.toString());
    }
    return params;
  }
}
