import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AlertMessage } from './alert.model';
import { Subject } from 'rxjs';

@Injectable()
export class AlertService {

  private alertSource: Subject<AlertMessage> = new Subject<AlertMessage>();
  alertObservable: Observable<AlertMessage> = this.alertSource.asObservable();

  addAlertMessage(alertMessage: AlertMessage) {
    console.log(alertMessage)
    this.alertSource.next(alertMessage);
  }
}
