import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppForRun } from '../../models/desktop/deptapp-for-run.model';
import { Flag } from '../../models/common/flag.model';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';

import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '.';
import * as _ from 'lodash';
import { DeptAppForRunOptions } from '../../models/desktop/deptapp-for-run-options.model';

@Injectable()
export class DeptAppResolvedDeploymentOptionsPreferencesService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async existsByDeptApp(deptApp: DeptApp): Promise<Flag> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DEPLOYMENT_OPTIONS_PREFERENCES);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<Flag>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-resolved-deployment-options-preferences/exists`)).toPromise();
  }

  async findByDeptApp(deptApp: DeptApp): Promise<DeptAppForRunOptions> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DEPLOYMENT_OPTIONS_PREFERENCES);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppForRunOptions>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-resolved-deployment-options-preferences`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp`;
  }
}
