import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent, HttpRequest } from '@angular/common/http';
import { Flag } from '../../models/common/flag.model';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppRunningInstance } from '../../models/desktop/deptapp-running-instance.model';
import { DeptAppForRun } from '../../models/desktop/deptapp-for-run.model';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { Observable, from } from 'rxjs';

import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '.';
import * as _ from 'lodash';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';
import { DeptAppRemoteDeployDockerMachineType } from '../../models/desktop/deptapp-remote-deploy-docker-machine-type.model';
import { DeptAppForRemoteDeploy } from '../../models/desktop/deptapp-for-remote-deploy.model';
import { DeptAppForStandaloneElectronDeploy } from '../../models/desktop/deptapp-for-standalone-electron-deploy.model';
import { DeptAppStandaloneElectronDeployPlatform } from '../../models/desktop/deptapp-standalone-electron-deploy-plaftform.model';
import { DeptAppForStandaloneElectronDeployResponse } from '../../models/desktop/deptapp-for-standalone-electron-deploy-response.model';
import { AbstractDeptAppInstance } from '../../models/desktop/abstract-deptapp-instance.model';
import { DeptAppRemoteDeployConnectServerAuthType } from '../../models/desktop/deptapp-remote-deploy-connect-server-auth-type.model';
import { DeptAppForKubernetesRemoteDeploy } from '../../models/desktop/deptapp-for-kubernetes-remote-deploy.model';
import { K8SConnection } from '../../models/desktop/deptapp-k8s-connection-config.model';
import { K8SDeployment } from '../../models/desktop/deptapp-k8s-upload-deployment.model';

@Injectable()
export class DeptAppDesktopService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async isDeptAppsFlowsDesignerStarting(deptApp?: DeptApp): Promise<Flag> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    let params = new HttpParams();
    if (!_.isUndefined(deptApp)) {
      params = params.append('id-deptapp', deptApp.id.toString());
    }
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<Flag>(encodeURI(`${resourceBaseUri}/instance/designer/is-running`), {
      params
    }).toPromise();
  }

  async isDeptAppsFlowsHeadlessRunnerStarting(deptApp: DeptApp): Promise<Flag> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<Flag>(encodeURI(`${resourceBaseUri}/instance/headless-runner/is-running`), {
      params: new HttpParams().append('id-deptapp', deptApp.id.toString())
    }).toPromise();
  }

  async getDeptAppsFlowsDesignerStarting(): Promise<DeptAppRunningInstance> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppRunningInstance>(encodeURI(`${resourceBaseUri}/instance/designer`)).toPromise();
  }

  async getDeptAppsFlowsRunnerStarting(deptApp?: DeptApp): Promise<DeptAppRunningInstance[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);
    let params = new HttpParams();
    if (!_.isUndefined(deptApp)) {
      params = params.append('id-deptapp', deptApp.id.toString());
    }

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppRunningInstance[]>(encodeURI(`${resourceBaseUri}/instance/headless-runner`), {
      params
    }).toPromise();
  }

  async findAllDeptAppRemoteDeployDockerMachineType(): Promise<DeptAppRemoteDeployDockerMachineType[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppRemoteDeployDockerMachineType[]>(encodeURI(`${resourceBaseUri}/instance/headless-runner/deptapp-remote-deploy-docker-machine-type`)).toPromise();
  }

  async findAllDeptAppStandaloneElectronDeployPlatform(): Promise<DeptAppStandaloneElectronDeployPlatform[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppStandaloneElectronDeployPlatform[]>(encodeURI(`${resourceBaseUri}/instance/headless-runner/deptapp-standalone-electron-deploy-platform`)).toPromise();
  }

  async findAllDeptAppRemoteDeployConnectServerAuthType(): Promise<DeptAppRemoteDeployConnectServerAuthType[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppRemoteDeployConnectServerAuthType[]>(encodeURI(`${resourceBaseUri}/instance/headless-runner/deptapp-remote-deploy-connect-server-auth-type`)).toPromise();
  }

  async runDeptAppInDesignerMode(deptAppForRun: DeptAppForRun): Promise<AbstractDeptAppInstance> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<DeptAppRunningInstance>(encodeURI(`${resourceBaseUri}/instance/designer/start`), deptAppForRun).toPromise();
  }

  async runDeptAppInDesignerModeAuditor(deptAppForRun: DeptAppForRun): Promise<AbstractDeptAppInstance> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);
    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<DeptAppRunningInstance>(encodeURI(`${resourceBaseUri}/instance/designer/start/auditor`), deptAppForRun).toPromise();
  }

  async runDeptAppInHeadlessRunnerMode(deptAppForRun: DeptAppForRun): Promise<DeptAppRunningInstance> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<DeptAppRunningInstance>(encodeURI(`${resourceBaseUri}/instance/headless-runner/start`), deptAppForRun).toPromise();
  }

  async generateDockerComposeSpecForRemoteDeploy(deptAppForRemoteDeploy: DeptAppForRemoteDeploy): Promise<string> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<string>(encodeURI(`${resourceBaseUri}/instance/headless-runner/docker-compose`), deptAppForRemoteDeploy, {
      responseType: 'text' as 'json'
    }).toPromise();
  }

  async generateKubernetesObjectFileForRemoteDeploy(deptAppForKubernetesRemoteDeploy: DeptAppForKubernetesRemoteDeploy): Promise<string> {

    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<string>(encodeURI(`${resourceBaseUri}/instance/headless-runner/k8s-deployment-file`), deptAppForKubernetesRemoteDeploy, {
      responseType: 'text' as 'json'
    }).toPromise();
  }

  async generateAndDownloadStandaloneElectronDeploy(deptAppForStandaloneElectronDeploy: DeptAppForStandaloneElectronDeploy): Promise<DeptAppForStandaloneElectronDeployResponse> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT)

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<DeptAppForStandaloneElectronDeployResponse>(encodeURI(`${resourceBaseUri}/instance/headless-runner/standalone-electron`), deptAppForStandaloneElectronDeploy).toPromise();
  }

  async launchStandaloneElectronDeploy(deptAppForStandaloneElectronDeploy: DeptAppForStandaloneElectronDeploy): Promise<void> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.STANDALONE_ELECTRON_DEPLOYMENT)

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<void>(encodeURI(`${resourceBaseUri}/instance/headless-runner/standalone-electron/launch`), deptAppForStandaloneElectronDeploy).toPromise();
  }

  async validateConnectionKubernetesCluster(k8sConfig: K8SConnection): Promise<any> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<any>(encodeURI(`${resourceBaseUri}/instance/headless-runner/k8s-cluster/connection/test`), k8sConfig).toPromise();
  }

  async deployInKubernetesCluster(deployment: K8SDeployment): Promise<any> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<any>(encodeURI(`${resourceBaseUri}/instance/headless-runner/k8s-cluster/deploy`), deployment).toPromise();
  }

  async deployInRemoteServerWithSshConnection(deptAppForRemoteDeploy: DeptAppForRemoteDeploy): Promise<void> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.REMOTE_DEPLOYMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post<void>(encodeURI(`${resourceBaseUri}/instance/headless-runner/remote-server/deploy/ssh`), deptAppForRemoteDeploy).toPromise();
  }

  downloadLogFile(deptAppRunningInstance: DeptAppRunningInstance): Observable<HttpEvent<{}>> {
    return from(this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT))
    .mergeMap(() => {
      return from(this.buildResourceBaseUri());
    }).mergeMap((resourceBaseUri) => {
      return this.http.request(new HttpRequest('GET', encodeURI(`${resourceBaseUri}/instance/${deptAppRunningInstance.containerId}/log/file`), {
        reportProgress: true,
        responseType: 'blob'
      }))
    });
  }

  async subscribeLogConsole(deptAppRunningInstance: DeptAppRunningInstance): Promise<any> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post(encodeURI(`${resourceBaseUri}/instance/${deptAppRunningInstance.containerId}/log/subscribe`), {}).toPromise();
  }

  async unsubscribeLogConsole(deptAppRunningInstance: DeptAppRunningInstance): Promise<any> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.post(encodeURI(`${resourceBaseUri}/instance/${deptAppRunningInstance.containerId}/log/unsubscribe`), {}).toPromise();
  }

  async stopDeptAppRunningInstance(deptAppRunningInstance: DeptAppRunningInstance): Promise<any> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.DOCKER_MANAGEMENT);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.delete(encodeURI(`${resourceBaseUri}/instance/${deptAppRunningInstance.containerId}/stop`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp/desktop`;
  }
}
