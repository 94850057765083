import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { LoadingDialogConfig, LoadingDialogActionEnum } from './loading-dialog.model';

@Injectable()
export class LoadingDialogService {

  private loadingDialogSubject = new Subject<LoadingDialogConfig>();
  loadingDialogObservable = this.loadingDialogSubject.asObservable();

  show(isDownload?: boolean, loaded?: number, total?: number): void {
    this.loadingDialogSubject.next({
      action: LoadingDialogActionEnum.SHOW,
      isDownloadFile: isDownload,
      loaded: loaded,
      total: total,
    });
  }

  updateDownloadProgress(loaded: number, total: number): void {
    this.loadingDialogSubject.next({
      action: LoadingDialogActionEnum.UPDATE_DOWNLOAD_PROGRESS,
      loaded: loaded,
      total: total,
    });
  }

  hide(): void {
    this.loadingDialogSubject.next({ action: LoadingDialogActionEnum.HIDE });
  }
}
