import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AppInternalConfig } from '../../models/app/app-internal-config.model';
import { Subject } from 'rxjs';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/app/internal-config`;

@Injectable()
export class AppInternalConfigService {
  public $changeStateInternalConfig = new Subject<AppInternalConfig>();

  constructor(private http: HttpClient) { }

  async get(): Promise<AppInternalConfig> {
    return await this.http.get<AppInternalConfig>(encodeURI(RESOURCE_BASE_URI)).toPromise();
  }
}
