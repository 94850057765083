import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckVersionDesktopService } from './check-version-desktop.service';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppVersionNodeFlowsResolvedCredential } from '../../models/desktop/deptapp-version-node-flows-resolved-credential.model';
import { DeptAppsDesktopConnectionEndpointService } from './internal/deptapps-desktop-connection-endpoint.service';

import { DEPTAPPS_DESKTOP_EXPECTED_VERSIONS } from '.';

import * as _ from 'lodash';

@Injectable()
export class DeptAppNodeFlowsResolvedCredentialPreferencesService {
  constructor(
    private deptappsDesktopConnectionEndpointService: DeptAppsDesktopConnectionEndpointService,
    private checkVersionDesktopService: CheckVersionDesktopService,
    private http: HttpClient) { }

  async findByDeptApp(deptApp: DeptApp): Promise<DeptAppVersionNodeFlowsResolvedCredential[]> {
    await this.checkVersionDesktopService.isCorrectDesktopVersion(DEPTAPPS_DESKTOP_EXPECTED_VERSIONS.CREDENTIALS_PREFERENCES);

    const resourceBaseUri = await this.buildResourceBaseUri();
    return await this.http.get<DeptAppVersionNodeFlowsResolvedCredential[]>(encodeURI(`${resourceBaseUri}/${deptApp.id}/desktop/deptapp-node-flows-resolved-credential-preferences`)).toPromise();
  }

  private async buildResourceBaseUri() {
    const { httpConnectionBaseUrl } = await this.deptappsDesktopConnectionEndpointService.getConnectionEndpoint();
    return `${httpConnectionBaseUrl}/deptapp`;
  }
}
