import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { UtilsService } from './utils.service';

@Injectable()
export class EncriptUtilsService {
  constructor(private utilsService: UtilsService) {}

  generateAlphanumericPass(numCharacters){
    let pass = this.utilsService.generateLongUUIDBasedPassword();
    pass = pass.split('-').join('').slice(0, numCharacters);
    return pass;
  }

  encriptData(data, password) {
    const stringData = JSON.stringify(data);
    const iv = Buffer.alloc(16, 0);
    const encrypted = CryptoJS.AES.encrypt(stringData, password, iv);
    this.utilsService.createAndDownloadBlobFile(encrypted, {type: "text/json"}, 'credentials_file.deptapp');
    return {
      exportedCredentials: encrypted
    };
  }

  decriptData(encrypted, password) {
    const iv = Buffer.alloc(16, 0);
    const decrypted = CryptoJS.AES.decrypt(
      encrypted, password, { iv: iv }
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
}
