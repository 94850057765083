import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Client } from '../../models/mdone/client.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/client`;

@Injectable()
export class ClientRemoteService {

  constructor(private http: HttpClient) { }

  async findClientsMe(): Promise<Client[]> {
    return await this.http.get<Client[]>(encodeURI(`${RESOURCE_BASE_URI}/me`)).toPromise();
  }
}
