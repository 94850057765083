import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationResult } from '../../models/common/pagination-result.model';
import { LocalStorageService } from '../../utils/local-storage.service';
import { JwtUtilsService } from '../../utils/jwt-utils.service';
import { DeptAppsFlowsPrivateLibrary } from '../../models/app/deptapps-flows-private-library.model';
import { Identifier } from '../../models/common/identifier.model';
import { DeptAppsFlowsPrivateLibrarySearchFilter } from '../../models/app/filters/deptapps-flows-private-library.search-filter';
import { DeptAppsFlowsRepositoryResourceUserVote } from '../../models/app/deptapps-flows-repository-resource-user-vote.model';
import { DeptAppsFlowsRepositoryResourceUserVoteStatus } from '../../models/app/deptapps-flows-repository-resource-user-vote-status.model';
import { DeptAppsFlowsRepositoryResourceMostUsed } from '../../models/app/deptapps-flows-repository-resource-most-used.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapps-flows-private-library`;

@Injectable()
export class DeptAppsFlowsPrivateLibraryService {

  constructor(
    private localStorageService: LocalStorageService,
    private jwtUtilsService: JwtUtilsService,
    private http: HttpClient) { }

  async findBySearchFilterPagination(searchFilter?: DeptAppsFlowsPrivateLibrarySearchFilter, limit?: number): Promise<PaginationResult<DeptAppsFlowsPrivateLibrary>> {
    const params = this.createQueryParamsBySearchFilter(searchFilter, limit);
    return await this.http.get<PaginationResult<DeptAppsFlowsPrivateLibrary>>(this.buildURIByResourceBaseURI(`/page`), { params }).toPromise();
  }

  async exportData(searchFilter?: DeptAppsFlowsPrivateLibrarySearchFilter, limit?: number): Promise<Blob> {
    const params = this.createQueryParamsBySearchFilter(searchFilter, limit);
    return await this.http.get(this.buildURIByResourceBaseURI(`/xlsx`), { params, responseType: 'blob'}).toPromise();
  }

  async findById(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<DeptAppsFlowsPrivateLibrary> {
    return await this.http.get<DeptAppsFlowsPrivateLibrary>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}`)).toPromise();
  }

  async getMostUsedPrivateLibrary(page: number, limit: number): Promise<PaginationResult<DeptAppsFlowsRepositoryResourceMostUsed<DeptAppsFlowsPrivateLibrary>>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return await this.http.get<PaginationResult<DeptAppsFlowsRepositoryResourceMostUsed<DeptAppsFlowsPrivateLibrary>>>(this.buildURIByResourceBaseURI(`/ranking/most-used`), { params }).toPromise();
  }

  async getHighestRatedLibrary(page: number, limit: number): Promise<PaginationResult<DeptAppsFlowsPrivateLibrary>> {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    return await this.http.get<PaginationResult<DeptAppsFlowsPrivateLibrary>>(this.buildURIByResourceBaseURI(`/ranking/highest-rated`), { params }).toPromise();
  }

  async findUserVoteBySearchFilter(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary, page?: number, limit?: number): Promise<PaginationResult<DeptAppsFlowsRepositoryResourceUserVote>> {
    let params = new HttpParams();
    if (page && page > 0) {
      params = params.append('page', page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }
    return await this.http.get<PaginationResult<DeptAppsFlowsRepositoryResourceUserVote>>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}/user-vote/page`), { params }).toPromise();
  }

  async create(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<Identifier<number>> {
    return await this.http.post<Identifier<number>>(this.buildURIByResourceBaseURI(), deptAppsFlowsPrivateLibrary).toPromise();
  }

  async createOrUpdateUserVote(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary, userVote: DeptAppsFlowsRepositoryResourceUserVote): Promise<DeptAppsFlowsRepositoryResourceUserVoteStatus> {
    return await this.http.post<DeptAppsFlowsRepositoryResourceUserVoteStatus>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}/user-vote`), userVote).toPromise();
  }

  async unpublishNpmPackage(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<any> {
    return await this.http.post<any>(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}/npm-package/unpublish`), {}, {
      responseType: 'text' as 'json'
    }).toPromise();
  }

  async update(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<any> {
    return await this.http.put(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}`), deptAppsFlowsPrivateLibrary).toPromise();
  }


  async delete(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<any> {
    return await this.http.delete(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}`)).toPromise();
  }

  async enableAgain(deptAppsFlowsPrivateLibrary: DeptAppsFlowsPrivateLibrary): Promise<any> {
    return await this.http.post(this.buildURIByResourceBaseURI(`/${deptAppsFlowsPrivateLibrary.id}/enable/again`), {}).toPromise();
  }

  buildURIByResourceBaseURI(contextPath?: string) {
    return encodeURI(RESOURCE_BASE_URI + (contextPath || ''));
  }

  private createQueryParamsBySearchFilter(searchFilter?: DeptAppsFlowsPrivateLibrarySearchFilter, limit?: number) {
    searchFilter = searchFilter || {};
    let params = new HttpParams().set('discharged', (!!searchFilter.discharged).toString()).set('include-user-votes', true.toString());
    if (searchFilter.searchText && searchFilter.searchText.length) {
      params = params.append('q', searchFilter.searchText);
    }
    if (searchFilter.page && searchFilter.page > 0) {
      params = params.append('page', searchFilter.page.toString());
    }
    if (limit && limit > 0) {
      params = params.append('limit', limit.toString());
    }

    let idMDOneProjectSelected;
    if (!!searchFilter.idMDOneProject) {
      idMDOneProjectSelected = searchFilter.idMDOneProject;
    } else if (!this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue()) &&
      this.localStorageService.hasMDOneSelectedProject()) {
      idMDOneProjectSelected = this.localStorageService.getMDOneSelectedProject().id;
    }
    if (idMDOneProjectSelected) {
      params = params.append('id-mdone-project', idMDOneProjectSelected.toString());
    }

    let lastModificationDateSelected;
    if (!!searchFilter.lastModificationDate) {
      lastModificationDateSelected = searchFilter.lastModificationDate;
    } else if (!this.jwtUtilsService.isUserAdmin(this.localStorageService.getJwtValue()) &&
      this.localStorageService.hasMDOneSelectedProject()) {
      lastModificationDateSelected = this.localStorageService.getMDOneSelectedProject().id;
    }
    if (lastModificationDateSelected) {

      params = params.append('lastModificationDate', this.addHoursToStringDate(lastModificationDateSelected, 1));
    }

    return params;
  }
  
  addHoursToStringDate(dateString : string, nHours : number) : string {
    let date = new Date(dateString);
    date.setHours(date.getHours() + nHours);
    return date.toISOString();
  }
}
