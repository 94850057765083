import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DeptApp } from '../../models/app/deptapp.model';
import { DeptAppAdministratorSettings } from '../../models/app/deptapp-administrator-settings.model';

const RESOURCE_BASE_URI = `${environment.apiUrlBase}/deptapp`;

@Injectable()
export class DeptAppAdministratorSettingsService {

  constructor(private http: HttpClient) { }

  async findByDeptApp(deptApp: DeptApp): Promise<DeptAppAdministratorSettings> {
    return await this.http.get<DeptAppAdministratorSettings>(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/administrator-settings`)).toPromise();
  }

  async createOrUpdate(deptApp: DeptApp, deptAppAdministratorSettings: DeptAppAdministratorSettings): Promise<any> {
    return await this.http.post(encodeURI(`${RESOURCE_BASE_URI}/${deptApp.id}/administrator-settings`), deptAppAdministratorSettings).toPromise();
  }
}
